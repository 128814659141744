// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import AppSystemWebApiResourceViewModel from '../../../../viewModel/resource/AppSystemWebApiResourceViewModel';
import AppSystemWebApiResourceDTO from '../../dto/resource/AppSystemWebApiResourceDTO';

export default abstract class AppSystemWebApiResourceModel extends BaseModel<AppSystemWebApiResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  * @type {int64}
  */
  get appSystemId() { return this.dto.appSystemId; }
  set appSystemId(value) { this.dto.appSystemId = value; }
  /**
  */
  get apiUrl() { return this.dto.apiUrl; }
  set apiUrl(value) { this.dto.apiUrl = value; }
  /**
  */
  get apiUser() { return this.dto.apiUser; }
  set apiUser(value) { this.dto.apiUser = value; }
  /**
  */
  get apiPassword() { return this.dto.apiPassword; }
  set apiPassword(value) { this.dto.apiPassword = value; }
  /**
  */
  get hasEncryptedApiPassword() { return this.dto.hasEncryptedApiPassword; }
  set hasEncryptedApiPassword(value) { this.dto.hasEncryptedApiPassword = value; }
  /**
  */
  get apiAuthenticationType() { return this.dto.apiAuthenticationType; }
  set apiAuthenticationType(value) { this.dto.apiAuthenticationType = value; }
  /**
  * @type {int32}
  */
  get timeoutSeconds() { return this.dto.timeoutSeconds; }
  set timeoutSeconds(value) { this.dto.timeoutSeconds = value; }
  /**
  */
  get useProxy() { return this.dto.useProxy; }
  set useProxy(value) { this.dto.useProxy = value; }
  /**
  */
  get proxyUrl() { return this.dto.proxyUrl; }
  set proxyUrl(value) { this.dto.proxyUrl = value; }
  /**
  */
  get proxyUser() { return this.dto.proxyUser; }
  set proxyUser(value) { this.dto.proxyUser = value; }
  /**
  */
  get proxyPassword() { return this.dto.proxyPassword; }
  set proxyPassword(value) { this.dto.proxyPassword = value; }
  /**
  */
  get hasEncryptedProxyPassword() { return this.dto.hasEncryptedProxyPassword; }
  set hasEncryptedProxyPassword(value) { this.dto.hasEncryptedProxyPassword = value; }
  /**
  */
  get enableHttpsCertificateValidation() { return this.dto.enableHttpsCertificateValidation; }
  set enableHttpsCertificateValidation(value) { this.dto.enableHttpsCertificateValidation = value; }
  /**
  */
  get workspaceId() { return this.dto.workspaceId; }
  set workspaceId(value) { this.dto.workspaceId = value; }
  /**
  */
  get secret() { return this.dto.secret; }
  set secret(value) { this.dto.secret = value; }
  /**
  */
  get hasEncryptedSecret() { return this.dto.hasEncryptedSecret; }
  set hasEncryptedSecret(value) { this.dto.hasEncryptedSecret = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.creatorid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = qs.creatorname;
    this.dto.created = qs.created;
    value = Number.parseFloat(qs.modifierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = qs.modifiername;
    this.dto.modified = qs.modified;
    value = Number.parseFloat(qs.appsystemid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.appSystemId = value;
    this.dto.apiUrl = qs.apiurl;
    this.dto.apiUser = qs.apiuser;
    this.dto.apiPassword = qs.apipassword;
    value = qs.hasEncryptedApiPassword === 'true' ? true : qs.hasencryptedapipassword;
    value = qs.hasEncryptedApiPassword === 'false' ? false : undefined;
    this.dto.hasEncryptedApiPassword = value;
    this.dto.apiAuthenticationType = qs.apiauthenticationtype;
    value = Number.parseFloat(qs.timeoutseconds);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.timeoutSeconds = value;
    value = qs.useProxy === 'true' ? true : qs.useproxy;
    value = qs.useProxy === 'false' ? false : undefined;
    this.dto.useProxy = value;
    this.dto.proxyUrl = qs.proxyurl;
    this.dto.proxyUser = qs.proxyuser;
    this.dto.proxyPassword = qs.proxypassword;
    value = qs.hasEncryptedProxyPassword === 'true' ? true : qs.hasencryptedproxypassword;
    value = qs.hasEncryptedProxyPassword === 'false' ? false : undefined;
    this.dto.hasEncryptedProxyPassword = value;
    value = qs.enableHttpsCertificateValidation === 'true' ? true : qs.enablehttpscertificatevalidation;
    value = qs.enableHttpsCertificateValidation === 'false' ? false : undefined;
    this.dto.enableHttpsCertificateValidation = value;
    this.dto.workspaceId = qs.workspaceid;
    this.dto.secret = qs.secret;
    value = qs.hasEncryptedSecret === 'true' ? true : qs.hasencryptedsecret;
    value = qs.hasEncryptedSecret === 'false' ? false : undefined;
    this.dto.hasEncryptedSecret = value;
  }
}
