// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import UserStatusResourceViewModel from '../../../../viewModel/resource/UserStatusResourceViewModel';
import UserResourceViewModel from '../../../../viewModel/resource/UserResourceViewModel';
import UserResourceDTO from '../../dto/resource/UserResourceDTO';

export default abstract class UserResourceModel extends BaseModel<UserResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  */
  get email() { return this.dto.email; }
  set email(value) { this.dto.email = value; }
  /**
  */
  get userName() { return this.dto.userName; }
  set userName(value) { this.dto.userName = value; }
  /**
  * @type {date-time}
  */
  get gdprConsentDate() { return this.dto.gdprConsentDate; }
  set gdprConsentDate(value) { this.dto.gdprConsentDate = value; }
  /**
  * @type {int32}
  */
  get gdprConsentVersion() { return this.dto.gdprConsentVersion; }
  set gdprConsentVersion(value) { this.dto.gdprConsentVersion = value; }
  /**
  * @type {date-time}
  */
  get verificationCodeSentOn() { return this.dto.verificationCodeSentOn; }
  set verificationCodeSentOn(value) { this.dto.verificationCodeSentOn = value; }
  /**
  * @type {date-time}
  */
  get verificationCodeValidUntil() { return this.dto.verificationCodeValidUntil; }
  set verificationCodeValidUntil(value) { this.dto.verificationCodeValidUntil = value; }
  /**
  * @type {date-time}
  */
  get verifiedOn() { return this.dto.verifiedOn; }
  set verifiedOn(value) { this.dto.verifiedOn = value; }
  /**
  * @type {int32}
  */
  get statusId() { return this.dto.statusId; }
  set statusId(value) { this.dto.statusId = value; }
  /**
  * @type {int64}
  */
  get appHostRoleId() { return this.dto.appHostRoleId; }
  set appHostRoleId(value) { this.dto.appHostRoleId = value; }
  /**
  */
  get userLanguage() { return this.dto.userLanguage; }
  set userLanguage(value) { this.dto.userLanguage = value; }
  /**
  */
  get userCulture() { return this.dto.userCulture; }
  set userCulture(value) { this.dto.userCulture = value; }
  /**
  */
  get registrationKey() { return this.dto.registrationKey; }
  set registrationKey(value) { this.dto.registrationKey = value; }
  /**
  */
  get canDelete() { return this.dto.canDelete; }
  set canDelete(value) { this.dto.canDelete = value; }
  /**
  */
  get isDeleted() { return this.dto.isDeleted; }
  set isDeleted(value) { this.dto.isDeleted = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.creatorid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = qs.creatorname;
    this.dto.created = qs.created;
    value = Number.parseFloat(qs.modifierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = qs.modifiername;
    this.dto.modified = qs.modified;
    this.dto.email = qs.email;
    this.dto.userName = qs.username;
    this.dto.gdprConsentDate = qs.gdprconsentdate;
    value = Number.parseFloat(qs.gdprconsentversion);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.gdprConsentVersion = value;
    this.dto.verificationCodeSentOn = qs.verificationcodesenton;
    this.dto.verificationCodeValidUntil = qs.verificationcodevaliduntil;
    this.dto.verifiedOn = qs.verifiedon;
    value = Number.parseFloat(qs.statusid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.statusId = value;
    value = Number.parseFloat(qs.apphostroleid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.appHostRoleId = value;
    this.dto.userLanguage = qs.userlanguage;
    this.dto.userCulture = qs.userculture;
    this.dto.registrationKey = qs.registrationkey;
    value = qs.canDelete === 'true' ? true : qs.candelete;
    value = qs.canDelete === 'false' ? false : undefined;
    this.dto.canDelete = value;
    value = qs.isDeleted === 'true' ? true : qs.isdeleted;
    value = qs.isDeleted === 'false' ? false : undefined;
    this.dto.isDeleted = value;
  }
}
