// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ShopOrderItemResourceViewModel from '../../../../viewModel/resource/ShopOrderItemResourceViewModel';
import ShopOrderItemResourceDTO from '../../dto/resource/ShopOrderItemResourceDTO';

export default abstract class ShopOrderItemResourceModel extends BaseModel<ShopOrderItemResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get shopOrderId() { return this.dto.shopOrderId; }
  set shopOrderId(value) { this.dto.shopOrderId = value; }
  /**
  * @type {int64}
  */
  get shopArticleId() { return this.dto.shopArticleId; }
  set shopArticleId(value) { this.dto.shopArticleId = value; }
  /**
  */
  get shopArticleNumber() { return this.dto.shopArticleNumber; }
  set shopArticleNumber(value) { this.dto.shopArticleNumber = value; }
  /**
  */
  get caption() { return this.dto.caption; }
  set caption(value) { this.dto.caption = value; }
  /**
  * @type {int64}
  */
  get shopSupplierId() { return this.dto.shopSupplierId; }
  set shopSupplierId(value) { this.dto.shopSupplierId = value; }
  /**
  */
  get shopSupplierArticleNumber() { return this.dto.shopSupplierArticleNumber; }
  set shopSupplierArticleNumber(value) { this.dto.shopSupplierArticleNumber = value; }
  /**
  */
  get isDelivery() { return this.dto.isDelivery; }
  set isDelivery(value) { this.dto.isDelivery = value; }
  /**
  */
  get hasLicense() { return this.dto.hasLicense; }
  set hasLicense(value) { this.dto.hasLicense = value; }
  /**
  */
  get isDownload() { return this.dto.isDownload; }
  set isDownload(value) { this.dto.isDownload = value; }
  /**
  * @type {int64}
  */
  get digitalAssetId() { return this.dto.digitalAssetId; }
  set digitalAssetId(value) { this.dto.digitalAssetId = value; }
  /**
  * @type {int32}
  */
  get quantity() { return this.dto.quantity; }
  set quantity(value) { this.dto.quantity = value; }
  /**
  * @type {double}
  */
  get unitNetPrice() { return this.dto.unitNetPrice; }
  set unitNetPrice(value) { this.dto.unitNetPrice = value; }
  /**
  * @type {double}
  */
  get unitGrossPrice() { return this.dto.unitGrossPrice; }
  set unitGrossPrice(value) { this.dto.unitGrossPrice = value; }
  /**
  * @type {double}
  */
  get taxRate() { return this.dto.taxRate; }
  set taxRate(value) { this.dto.taxRate = value; }
  /**
  * @type {double}
  */
  get taxAmount() { return this.dto.taxAmount; }
  set taxAmount(value) { this.dto.taxAmount = value; }
  /**
  * @type {double}
  */
  get totalNetPrice() { return this.dto.totalNetPrice; }
  set totalNetPrice(value) { this.dto.totalNetPrice = value; }
  /**
  * @type {double}
  */
  get totalGrossPrice() { return this.dto.totalGrossPrice; }
  set totalGrossPrice(value) { this.dto.totalGrossPrice = value; }
  /**
  * @type {int64}
  */
  get crmItemPriceNumber() { return this.dto.crmItemPriceNumber; }
  set crmItemPriceNumber(value) { this.dto.crmItemPriceNumber = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.shoporderid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.shopOrderId = value;
    value = Number.parseFloat(qs.shoparticleid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.shopArticleId = value;
    this.dto.shopArticleNumber = qs.shoparticlenumber;
    this.dto.caption = qs.caption;
    value = Number.parseFloat(qs.shopsupplierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.shopSupplierId = value;
    this.dto.shopSupplierArticleNumber = qs.shopsupplierarticlenumber;
    value = qs.isDelivery === 'true' ? true : qs.isdelivery;
    value = qs.isDelivery === 'false' ? false : undefined;
    this.dto.isDelivery = value;
    value = qs.hasLicense === 'true' ? true : qs.haslicense;
    value = qs.hasLicense === 'false' ? false : undefined;
    this.dto.hasLicense = value;
    value = qs.isDownload === 'true' ? true : qs.isdownload;
    value = qs.isDownload === 'false' ? false : undefined;
    this.dto.isDownload = value;
    value = Number.parseFloat(qs.digitalassetid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.digitalAssetId = value;
    value = Number.parseFloat(qs.quantity);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.quantity = value;
    value = Number.parseFloat(qs.unitnetprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.unitNetPrice = value;
    value = Number.parseFloat(qs.unitgrossprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.unitGrossPrice = value;
    value = Number.parseFloat(qs.taxrate);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.taxRate = value;
    value = Number.parseFloat(qs.taxamount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.taxAmount = value;
    value = Number.parseFloat(qs.totalnetprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalNetPrice = value;
    value = Number.parseFloat(qs.totalgrossprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalGrossPrice = value;
    value = Number.parseFloat(qs.crmitempricenumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.crmItemPriceNumber = value;
  }
}
