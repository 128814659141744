// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetShopPaymentPagedListRequestModel from '../../model/get-shop-payment-paged-list-request-model';
import GetShopPaymentPagedListRequestDTO from '../dto/get-shop-payment-paged-list-request-dto';
import dtoSchema from '../schemas/get-shop-payment-paged-list-request-schema';

export default abstract class GetShopPaymentPagedListRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetShopPaymentPagedListRequestDTO>) {
    super();
    this.paging = {};
    if (data) {
      this.paging = {
        currentPage: data['paging.CurrentPage'],
        pageSize: data['paging.PageSize'],
        includeCount: data['paging.IncludeCount'],
        noPaging: data['paging.NoPaging'],
      };
      this.amountFrom = data.amountFrom;
      this.amountTo = data.amountTo;
      this.dateFrom = data.dateFrom;
      this.dateTo = data.dateTo;
      this.paymentStatus = data.paymentStatus;
      this.paymentMethod = data.paymentMethod;
      this.searchCriteria = data.searchCriteria;
    }
  }

  paging: {
    /**
    * @type {int32}
    */
    currentPage?: number;
    /**
    * @type {int32}
    */
    pageSize?: number;
    /**
    */
    includeCount?: boolean;
    /**
    */
    noPaging?: boolean;
  };

  /**
  * @type {double}
  */
  amountFrom?: number;
  /**
  * @type {double}
  */
  amountTo?: number;
  /**
  * @type {date-time}
  */
  dateFrom?: string;
  /**
  * @type {date-time}
  */
  dateTo?: string;
  /**
  * @type {int32}
  */
  paymentStatus?: 1 | 2 | 3; // ShopPaymentStatusDTO
  /**
  * @type {int64}
  */
  paymentMethod?: 1 | 2; // ShopPaymentMethodDTO
  /**
  */
  searchCriteria?: string;

  static toModel(dto: DeepPartial<GetShopPaymentPagedListRequestDTO>): GetShopPaymentPagedListRequestModel;
  static toModel(dto: DeepPartial<GetShopPaymentPagedListRequestDTO> | undefined | null): GetShopPaymentPagedListRequestModel | undefined;
  static toModel(dto: DeepPartial<GetShopPaymentPagedListRequestDTO> | undefined | null): GetShopPaymentPagedListRequestModel | undefined {
    return dto ? new GetShopPaymentPagedListRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetShopPaymentPagedListRequestModel> | GetShopPaymentPagedListRequestModel): GetShopPaymentPagedListRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      'paging.CurrentPage': unwrapped.paging?.currentPage,
      'paging.PageSize': unwrapped.paging?.pageSize,
      'paging.IncludeCount': unwrapped.paging?.includeCount,
      'paging.NoPaging': unwrapped.paging?.noPaging,
      amountFrom: unwrapped.amountFrom,
      amountTo: unwrapped.amountTo,
      dateFrom: unwrapped.dateFrom,
      dateTo: unwrapped.dateTo,
      paymentStatus: unwrapped.paymentStatus,
      paymentMethod: unwrapped.paymentMethod,
      searchCriteria: unwrapped.searchCriteria,
    } as GetShopPaymentPagedListRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetShopPaymentPagedListRequestModel, can not map to GetShopPaymentPagedListRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
