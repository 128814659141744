// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetShopArticleTaxHistoriesRequestModel from '../../model/get-shop-article-tax-histories-request-model';
import CreateShopArticleTaxHistoryRequestModel from '../../model/create-shop-article-tax-history-request-model';
import GetShopArticleTaxHistoryByShopArticleTaxIdRequestModel from '../../model/get-shop-article-tax-history-by-shop-article-tax-id-request-model';
import UpdateShopArticleTaxHistoryRequestModel from '../../model/update-shop-article-tax-history-request-model';
import DeleteShopArticleTaxHistoryByIdRequestModel from '../../model/delete-shop-article-tax-history-by-id-request-model';
import ShopArticleTaxHistoryListResultModel from '../../model/shop-article-tax-history-list-result-model';
import ShopArticleTaxHistoryModel from '../../model/shop-article-tax-history-model';

export default (service: Servicelayer) => ({
  /**  */
  async getShopArticleTaxHistories(request: GetShopArticleTaxHistoriesRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-article-tax-histories'] ?? 'ShopArticleTaxHistories';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopArticleTaxHistoryListResultModel.toModel(dto));
  },
  /**  */
  async createShopArticleTaxHistory(request: CreateShopArticleTaxHistoryRequestModel, config?: RequestConfig) {
    const requestDTO = CreateShopArticleTaxHistoryRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-shop-article-tax-history'] ?? 'ShopArticleTaxHistories';
    const response = await service.post<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        shopArticleTaxId: requestDTO.shopArticleTaxId,
        taxRate: requestDTO.taxRate,
        validFrom: requestDTO.validFrom,
        validTo: requestDTO.validTo,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getShopArticleTaxHistoryByShopArticleTaxId(request: GetShopArticleTaxHistoryByShopArticleTaxIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetShopArticleTaxHistoryByShopArticleTaxIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-article-tax-history-by-shop-article-tax-id'] ?? 'ShopArticleTaxHistories/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopArticleTaxHistoryModel.toModel(dto));
  },
  /**  */
  async updateShopArticleTaxHistory(request: UpdateShopArticleTaxHistoryRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateShopArticleTaxHistoryRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-shop-article-tax-history'] ?? 'ShopArticleTaxHistories/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        shopArticleTaxId: requestDTO.shopArticleTaxId,
        taxRate: requestDTO.taxRate,
        validFrom: requestDTO.validFrom,
        validTo: requestDTO.validTo,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async deleteShopArticleTaxHistoryById(request: DeleteShopArticleTaxHistoryByIdRequestModel, config?: RequestConfig) {
    const requestDTO = DeleteShopArticleTaxHistoryByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-shop-article-tax-history-by-id'] ?? 'ShopArticleTaxHistories/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
});
