// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetCategoriesRequestModel from '../../model/get-categories-request-model';
import CreateCategoryRequestModel from '../../model/create-category-request-model';
import LookupCategoriesRequestModel from '../../model/lookup-categories-request-model';
import GetArticlesListByCategoryRequestModel from '../../model/get-articles-list-by-category-request-model';
import UpdateArticlesInShopCategoryRequestModel from '../../model/update-articles-in-shop-category-request-model';
import GetCategoryByIdRequestModel from '../../model/get-category-by-id-request-model';
import UpdateShopCategoryRequestModel from '../../model/update-shop-category-request-model';
import DeleteShopCategoryRequestModel from '../../model/delete-shop-category-request-model';
import ShopCategoriesListListResultModel from '../../model/shop-categories-list-list-result-model';
import Int64LookupListResultModel from '../../model/int64-lookup-list-result-model';
import ShopArticlesListByCategoryPagingResultModel from '../../model/shop-articles-list-by-category-paging-result-model';
import ShopCategoryModel from '../../model/shop-category-model';

export default (service: Servicelayer) => ({
  /**  */
  async getCategories(request: GetCategoriesRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-categories'] ?? 'ShopCategories';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopCategoriesListListResultModel.toModel(dto));
  },
  /**  */
  async createCategory(request: CreateCategoryRequestModel, config?: RequestConfig) {
    const requestDTO = CreateCategoryRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-category'] ?? 'ShopCategories';
    const response = await service.post<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        creatorId: requestDTO.creatorId,
        creatorName: requestDTO.creatorName,
        created: requestDTO.created,
        modifierId: requestDTO.modifierId,
        modifierName: requestDTO.modifierName,
        modified: requestDTO.modified,
        parentCategoryId: requestDTO.parentCategoryId,
        caption: requestDTO.caption,
        sort: requestDTO.sort,
        translatedLanguage_ISO2: requestDTO.translatedLanguage_ISO2,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async lookupCategories(request: LookupCategoriesRequestModel, config?: RequestConfig) {
    const requestDTO = LookupCategoriesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-categories'] ?? 'ShopCategories/Lookup';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupListResultModel.toModel(dto));
  },
  /**  */
  async getArticlesListByCategory(request: GetArticlesListByCategoryRequestModel, config?: RequestConfig) {
    const requestDTO = GetArticlesListByCategoryRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-articles-list-by-category'] ?? 'ShopCategories/{id}/Articles';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        includeChildrenCategories: requestDTO.includeChildrenCategories,
        isPublished: requestDTO.isPublished,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopArticlesListByCategoryPagingResultModel.toModel(dto));
  },
  /**  */
  async updateArticlesInShopCategory(request: UpdateArticlesInShopCategoryRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateArticlesInShopCategoryRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-articles-in-shop-category'] ?? 'ShopCategories/{id}/Articles';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        articleIds: requestDTO.articleIds,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as boolean | undefined);
  },
  /**  */
  async getCategoryById(request: GetCategoryByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetCategoryByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-category-by-id'] ?? 'ShopCategories/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        translatedLanguage_ISO2: requestDTO.translatedLanguage_ISO2,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopCategoryModel.toModel(dto));
  },
  /**  */
  async updateShopCategory(request: UpdateShopCategoryRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateShopCategoryRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-shop-category'] ?? 'ShopCategories/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        creatorId: requestDTO.creatorId,
        creatorName: requestDTO.creatorName,
        created: requestDTO.created,
        modifierId: requestDTO.modifierId,
        modifierName: requestDTO.modifierName,
        modified: requestDTO.modified,
        parentCategoryId: requestDTO.parentCategoryId,
        caption: requestDTO.caption,
        sort: requestDTO.sort,
        translatedLanguage_ISO2: requestDTO.translatedLanguage_ISO2,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async deleteShopCategory(request: DeleteShopCategoryRequestModel, config?: RequestConfig) {
    const requestDTO = DeleteShopCategoryRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-shop-category'] ?? 'ShopCategories/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
});
