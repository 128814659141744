// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetDynamicFormDefinitionRequestViewModel from '../../viewModel/request/DynamicForm/GetDynamicFormDefinitionRequestViewModel';
import DynamicFormUploadDocumentRequestViewModel from '../../viewModel/request/DynamicForm/DynamicFormUploadDocumentRequestViewModel';
import DynamicFormDefinitionResourceViewModel from '../../viewModel/resource/DynamicFormDefinitionResourceViewModel';
import DynamicFormPostRequestViewModel from '../../viewModel/resource/DynamicFormPostRequestViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getDynamicFormDefinition(request: GetDynamicFormDefinitionRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-dynamic-form-definition'] ?? 'core/DynamicForm/Definition';
    const response = await service.get<any>(endpointPath, {
      query: {
        appSystemKey: requestDTO.appSystemKey,
        id: requestDTO.id,
        businessUnit: requestDTO.businessUnit,
      },
      ...config,
    });
    const dto = response.data.result;
    return new DynamicFormDefinitionResourceViewModel().fromDTO(dto);
  },
  /**  */
  async postDynamicForm(model: DynamicFormPostRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-post-dynamic-form'] ?? 'core/DynamicForm';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as string | undefined;
  },
  /**  */
  async dynamicFormUploadDocument(request: DynamicFormUploadDocumentRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    const formData = new FormData();
    formData.append('uploadFile', requestDTO.uploadFile as Blob);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-dynamic-form-upload-document'] ?? 'core/DynamicForm/Documents/Upload';
    const response = await service.post<any>(endpointPath, {
      body: formData,
      headers: {
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as string | undefined;
  },
});
