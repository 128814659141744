import Vue from 'vue';
import CreateMembershipRequestModel from '../../generated/types/model/resource/CreateMembershipRequestModel';
import RequestSummaryResourceViewModel from './RequestSummaryResourceViewModel';
import utils from '../utils';
import OperatingModeRequestViewModel from '../request/Attributes/OperatingModeRequestViewModel';
import OperatingModeLookupResourceViewModel from './OperatingModeLookupResourceViewModel';

export default class CreateMembershipRequestViewModel extends CreateMembershipRequestModel {
  static operatingModeItems:OperatingModeLookupResourceViewModel[] = [];

  constructor() {
    super();
    Vue.$service.api.attributes.operatingMode(new OperatingModeRequestViewModel()).then((result) => { CreateMembershipRequestViewModel.operatingModeItems = result.items; });
  }

  protected transformToDTO() {
    this.dto.summary = this.summary.getDTO();
    if (this.memberContact.address.contactHasChanged) {
      this.memberContact.contactId = undefined;
    }
  }

  protected transformFromDTO() {}

  hasGastronomy(id: string | undefined): boolean {
    return id !== undefined && (CreateMembershipRequestViewModel.operatingModeItems.find((x) => x.id === id)?.isGastronomy ?? false);
  }

  hasAccommodation(id: string | undefined): boolean {
    return id !== undefined && (CreateMembershipRequestViewModel.operatingModeItems.find((x) => x.id === id)?.isAccommodation ?? false);
  }

  // Additional properties that need to be present in the DTO because the DTO gets saved to localStorage
  // TODO: Figure out a smart way to avoid setting the properties like this
  get additionalInformationRequired() { return (this.dto as any).additionalInformationRequired; }

  set additionalInformationRequired(value) { (this.dto as any).additionalInformationRequired = value; }

  get hasClassifiedBusinesses() { return (this.dto as any).hasClassifiedBusinesses; }

  set hasClassifiedBusinesses(value) { (this.dto as any).hasClassifiedBusinesses = value; }

  get onlinePaymentAllowed() { return (this.dto as any).onlinePaymentAllowed; }

  set onlinePaymentAllowed(value) { (this.dto as any).onlinePaymentAllowed = value; }

  get isDirectRegistration() { return (this.dto as any).isDirectRegistration; }

  set isDirectRegistration(value) { (this.dto as any).isDirectRegistration = value; }

  get payrollDisplayValue() { return (this.dto as any).payrollDisplayValue; }

  set payrollDisplayValue(value) { (this.dto as any).payrollDisplayValue = value; }

  get socialInsuranceDisplayValue() { return (this.dto as any).socialInsuranceDisplayValue; }

  set socialInsuranceDisplayValue(value) { (this.dto as any).socialInsuranceDisplayValue = value; }

  get certificateOfProficiencyCantonDisplayValue() { return (this.dto as any).certificateOfProficiencyCantonDisplayValue; }

  set certificateOfProficiencyCantonDisplayValue(value) { (this.dto as any).certificateOfProficiencyCantonDisplayValue = value; }

  get businessLocationId() { return (this.dto as any).businessLocationId; }

  set businessLocationId(value) { (this.dto as any).businessLocationId = value; }
  // _____________________________________________________________________________

  get summary(): RequestSummaryResourceViewModel {
    const hasBusinessCharacteristicsData = this.membershipBusiness.businessCharacteristics.ownershipType[0]?.id || this.membershipBusiness.businessCharacteristics.operatingType[0]?.id;
    const hasGastronomy = this.hasGastronomy(this.membershipBusiness.businessCharacteristics.operatingType[0]?.id);
    const hasAccommodation = this.hasAccommodation(this.membershipBusiness.businessCharacteristics.operatingType[0]?.id);
    const section = [
      {
        title: Vue.$t('memberPortal.createMembership.label.stepTitles.2'),
        items: [
          {
            label: Vue.$t('memberPortal.createMembership.text.haveYouBeenAMemberDescription'),
            displayValue: this.isMembershipReEntry ? Vue.$t('app.yes') : Vue.$t('app.no'),
          },
        ],
      },

      {
        title: Vue.$t('memberPortal.createMembership.label.stepTitles.3'),
        items: [
          {
            label: Vue.$t('memberPortal.createMembership.table.postalcode'),
            displayValue: this.memberBusinessContact.address.postalCode,
          },
          {
            label: Vue.$t('memberPortal.createMembership.label.payRoll'),
            displayValue: this.payrollDisplayValue,
          },
          ...this.orderBasket.items.map((item : any) => ({
            label: item.caption!,
            displayValue: Vue.$format.currency(item.grossPrice!, item.currency as any ?? 'CHF'),
          })),
          {
            label: Vue.$t('memberPortal.createMembership.table.total'),
            displayValue: Vue.$format.currency(this.orderBasket.grossTotal!, this.orderBasket.currency as any ?? 'CHF'),
          },
        ],
      },

      {
        title: Vue.$t('memberPortal.createMembership.label.stepTitles.4'),
        items: [
          {
            label: Vue.$t('memberPortal.createMembership.label.desiredDateIfJoining'),
            displayValue: Vue.$format.localDate(this.entryDate!),
          },
          {
            label: Vue.$t('memberPortal.createMembership.label.socialInsurer'),
            displayValue: this.socialInsuranceDisplayValue,
          },
          {
            label: Vue.$t('memberPortal.createMembership.label.operatingLicence'),
            displayValue: this.hasOperatingLicence ? Vue.$t('app.yes') : Vue.$t('app.no'),
          },
          {
            ...utils.insertIf(this.additionalInformationRequired && this.hasOperatingLicence, {
              label: Vue.$t('memberPortal.createMembership.label.dateOperatingLicense'),
              displayValue: Vue.$format.localDate(this.dateOperatingLicenceIssued!),
            }),
          },
          {
            ...utils.insertIf(this.additionalInformationRequired && this.hasOperatingLicence, {
              label: Vue.$t('memberPortal.createMembership.label.holderOfOperatingLicence'),
              displayValue: this.operatingLicenceOwner,
            }),
          },
          {
            ...utils.insertIf(this.additionalInformationRequired, {
              label: Vue.$t('memberPortal.createMembership.label.certificateOfCapability'),
              displayValue: this.hasCertificateOfProficiency ? Vue.$t('app.yes') : Vue.$t('app.no'),
            }),
          },
          {
            ...utils.insertIf(this.additionalInformationRequired && this.hasCertificateOfProficiency, {
              label: Vue.$t('memberPortal.createMembership.label.dateOfCertificateOfProficiency'),
              displayValue: Vue.$format.localDate(this.dateCertificateOfProficiencyIssued!),
            }),
          },
          {
            ...utils.insertIf(this.additionalInformationRequired && this.hasCertificateOfProficiency, {
              label: Vue.$t('form.address.state'),
              displayValue: this.certificateOfProficiencyCantonDisplayValue,
            }),
          },
          {
            ...utils.insertIf(this.additionalInformationRequired && !this.hasCertificateOfProficiency, {
              label: Vue.$t('memberPortal.createMembership.label.coursePlanned'),
              displayValue: this.certificateOfProficiencyCourse,
            }),
          },
        ],
      },

      {
        title: Vue.$t('memberPortal.createMembership.label.stepTitles.5'),
        items: this.memberContact.address.getSummaryItems({ hideLanguageIso2: true }),
      },

      {
        title: Vue.$t('memberPortal.createMembership.label.stepTitles.6'),
        items: [
          {
            label: Vue.$t('memberPortal.createMembership.label.nameOfBusiness'),
            displayValue: this.membershipBusiness.companyName,
          },
          {
            label: Vue.$t('memberPortal.createMembership.table.businessOperations'),
            displayValue: Vue.$format.localDate(this.membershipBusiness.startDate!),
          },
          ...utils.insertIf(
            this.memberBusinessContact.isDifferentFromOwner!,
            this.memberBusinessContact.address.getSummaryItems({ hideLanguageIso2: true }),
            [{
              label: Vue.$t('form.address.address'),
              displayValue: Vue.$t('memberPortal.createMembership.text.isEqualToMembershipAddress'),
            }],
          ),
        ],
      }];
    if (hasBusinessCharacteristicsData) {
      section.push({
        title: Vue.$t('memberPortal.createMembership.label.stepTitles.7'),
        items: this.membershipBusiness.businessCharacteristics.getSummaryItems(),
      });
    }

    if (hasGastronomy) {
      section.push(
        {
          title: Vue.$t('memberPortal.createMembership.title.gastronomy'),
          items: this.membershipBusiness.businessCharacteristics.getGastronomySummaryItems(),
        },
      );
    }
    if (hasAccommodation) {
      section.push(
        {
          title: Vue.$t('memberPortal.createMembership.title.accommodation'),
          items: this.membershipBusiness.businessCharacteristics.getAccommodationSummaryItems(),
        },
      );
    }
    section.push(
      {
        title: Vue.$t('memberPortal.createMembership.label.stepTitles.9'),
        items: [
          {
            label: Vue.$t('memberPortal.createMembership.table.correspondenceLanguage'),
            displayValue: this.memberCorrespondenceContact.languageIso2,
          },
          {
            ...utils.insertIf(this.memberCorrespondenceContact.isDifferentFromOwner!, {
              label: Vue.$t('memberPortal.createMembership.label.addressOfTrustee'),
              displayValue: this.memberCorrespondenceContact.isTrusteeAddress ? Vue.$t('app.yes') : Vue.$t('app.no'),
            }),
          },
          ...utils.insertIf(
            this.memberCorrespondenceContact.isDifferentFromOwner!,
            this.memberCorrespondenceContact.address.getSummaryItems({ hideLanguageIso2: true }),
            [{
              label: Vue.$t('form.address.address'),
              displayValue: Vue.$t('memberPortal.createMembership.text.isEqualToMembershipAddress'),
            }],
          ),
        ],
      },
    );
    return new RequestSummaryResourceViewModel().fromDTO({
      sections: section.map((sectionItem) => ({
        ...sectionItem,
        items: sectionItem?.items?.filter((item: any) => item.label !== undefined && item.displayValue !== undefined && item.displayValue !== null) ?? [],
      })),
    });
  }
}
