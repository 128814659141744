// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetAppSystemRequestModel from '../../model/get-app-system-request-model';
import GetAppSystemLookupRequestModel from '../../model/get-app-system-lookup-request-model';
import GetAppSystemCustomFieldsRequestModel from '../../model/get-app-system-custom-fields-request-model';
import AppSystemListResultModel from '../../model/app-system-list-result-model';
import Int64LookupPagingResultModel from '../../model/int64-lookup-paging-result-model';
import CampaignCustomFieldListResultModel from '../../model/campaign-custom-field-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getAppSystem(request: GetAppSystemRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-app-system'] ?? 'core/integration/AppSystem';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(AppSystemListResultModel.toModel(dto));
  },
  /**  */
  async getAppSystemLookup(request: GetAppSystemLookupRequestModel, config?: RequestConfig) {
    const requestDTO = GetAppSystemLookupRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-app-system-lookup'] ?? 'core/integration/AppSystem/Lookup';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupPagingResultModel.toModel(dto));
  },
  /**  */
  async getAppSystemCustomFields(request: GetAppSystemCustomFieldsRequestModel, config?: RequestConfig) {
    const requestDTO = GetAppSystemCustomFieldsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-app-system-custom-fields'] ?? 'core/integration/AppSystem/{id}/CustomFields';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CampaignCustomFieldListResultModel.toModel(dto));
  },
});
