// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UpdateShopArticleTaxRequestModel from '../../model/update-shop-article-tax-request-model';
import UpdateShopArticleTaxRequestDTO from '../dto/update-shop-article-tax-request-dto';
import dtoSchema from '../schemas/update-shop-article-tax-request-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class UpdateShopArticleTaxRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<UpdateShopArticleTaxRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.shortName = data.shortName ?? undefined;
      this.name = data.name ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  shortName?: string;
  /**
  */
  name?: string;

  static toModel(dto: DeepPartial<UpdateShopArticleTaxRequestDTO>): UpdateShopArticleTaxRequestModel;
  static toModel(dto: DeepPartial<UpdateShopArticleTaxRequestDTO> | undefined | null): UpdateShopArticleTaxRequestModel | undefined;
  static toModel(dto: DeepPartial<UpdateShopArticleTaxRequestDTO> | undefined | null): UpdateShopArticleTaxRequestModel | undefined {
    return dto ? new UpdateShopArticleTaxRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<UpdateShopArticleTaxRequestModel> | UpdateShopArticleTaxRequestModel): UpdateShopArticleTaxRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      shortName: unwrapped.shortName,
      name: unwrapped.name,
    } as UpdateShopArticleTaxRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for UpdateShopArticleTaxRequestModel, can not map to UpdateShopArticleTaxRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
