// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/create-shop-order-request-schema',
  type: 'object',
  required: ['id', 'userId', 'totalGrossPrice', 'totalNetPrice', 'totalTaxAmount'],
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    userId: {
      type: 'integer',
    },
    orderNumber: {
      type: 'string',
      nullable: true,
    },
    orderReference: {
      type: 'string',
      nullable: true,
    },
    orderStateId: {
      enum: [
        1,
        2,
        3,
        4,
        5,
      ],
    },
    orderDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    currency: {
      type: 'string',
      nullable: true,
    },
    totalGrossPrice: {
      type: 'number',
    },
    totalNetPrice: {
      type: 'number',
    },
    totalTaxAmount: {
      type: 'number',
    },
    invoiceAddress_Salutation: {
      enum: [
        0,
        1,
      ],
    },
    invoiceAddress_Company: {
      type: 'string',
      nullable: true,
    },
    invoiceAddress_FirstName: {
      type: 'string',
      nullable: true,
    },
    invoiceAddress_LastName: {
      type: 'string',
      nullable: true,
    },
    invoiceAddress_Street: {
      type: 'string',
      nullable: true,
    },
    invoiceAddress_Number: {
      type: 'string',
      nullable: true,
    },
    invoiceAddress_POBox: {
      type: 'string',
      nullable: true,
    },
    invoiceAddress_AdditionalLine: {
      type: 'string',
      nullable: true,
    },
    invoiceAddress_PostalCode: {
      type: 'string',
      nullable: true,
    },
    invoiceAddress_City: {
      type: 'string',
      nullable: true,
    },
    invoiceAddress_CountryIso2: {
      type: 'string',
      nullable: true,
    },
    invoiceAddress_Phone: {
      type: 'string',
      nullable: true,
    },
    invoiceAddress_MobilePhone: {
      type: 'string',
      nullable: true,
    },
    invoiceAddress_Email: {
      type: 'string',
      nullable: true,
    },
    invoiceAddress_CrmContactId: {
      type: 'string',
      nullable: true,
    },
    deliveryAddress_IsDifferent: {
      type: 'boolean',
      nullable: true,
    },
    deliveryAddress_Salutation: {
      enum: [
        0,
        1,
      ],
    },
    deliveryAddress_Company: {
      type: 'string',
      nullable: true,
    },
    deliveryAddress_FirstName: {
      type: 'string',
      nullable: true,
    },
    deliveryAddress_LastName: {
      type: 'string',
      nullable: true,
    },
    deliveryAddress_Street: {
      type: 'string',
      nullable: true,
    },
    deliveryAddress_Number: {
      type: 'string',
      nullable: true,
    },
    deliveryAddress_POBox: {
      type: 'string',
      nullable: true,
    },
    deliveryAddress_AdditionalLine: {
      type: 'string',
      nullable: true,
    },
    deliveryAddress_PostalCode: {
      type: 'string',
      nullable: true,
    },
    deliveryAddress_City: {
      type: 'string',
      nullable: true,
    },
    deliveryAddress_CountryIso2: {
      type: 'string',
      nullable: true,
    },
    deliveryAddress_Phone: {
      type: 'string',
      nullable: true,
    },
    deliveryAddress_MobilePhone: {
      type: 'string',
      nullable: true,
    },
    deliveryAddress_Email: {
      type: 'string',
      nullable: true,
    },
    language_ISO2: {
      type: 'string',
      nullable: true,
    },
    membershipId: {
      type: 'integer',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
