// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ShopPaymentResourceViewModel from '../../../../viewModel/resource/ShopPaymentResourceViewModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ShopPaymentResourceListResultViewModel from '../../../../viewModel/resource/ShopPaymentResourceListResultViewModel';
import ShopPaymentResourceListResultDTO from '../../dto/resource/ShopPaymentResourceListResultDTO';

export default abstract class ShopPaymentResourceListResultModel extends BaseModel<ShopPaymentResourceListResultDTO> {
  /**
  */
  get items() { return this.getArrayModels(() => this.dto.items, ShopPaymentResourceViewModel)!; }
  set items(value) { this.setArrayModels(() => this.dto.items, value); }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
  }
}
