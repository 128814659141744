// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import CampaignCustomFieldResourceViewModel from '../../../../viewModel/resource/CampaignCustomFieldResourceViewModel';
import CampaignCustomFieldResourceDTO from '../../dto/resource/CampaignCustomFieldResourceDTO';

export default abstract class CampaignCustomFieldResourceModel extends BaseModel<CampaignCustomFieldResourceDTO> {
  /**
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  * @type {int64}
  */
  get appSystemId() { return this.dto.appSystemId; }
  set appSystemId(value) { this.dto.appSystemId = value; }
  /**
  */
  get caption() { return this.dto.caption; }
  set caption(value) { this.dto.caption = value; }
  /**
  */
  get type() { return this.dto.type; }
  set type(value) { this.dto.type = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.id = qs.id;
    value = Number.parseFloat(qs.appsystemid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.appSystemId = value;
    this.dto.caption = qs.caption;
    this.dto.type = qs.type;
  }
}
