<template>
  <div class="gs-basket-summary">
    <p-progress-linear buffer-color="transparent" :indeterminate="isLoading"></p-progress-linear>
    <p-row v-if="errorResponse || !hasItems">
      <p-col v-if="errorResponse" xs12>
        <p-request-alert v-model="errorResponse" :dismissable="false"></p-request-alert>
      </p-col>
      <p-col v-else-if="!hasItems && !isLoading">
        <p-row column>
          <p-col>
            <p-alert type="info" :value="true">
              {{ $t('shop.basket.emptyBasketMessage') }}
            </p-alert>
          </p-col>
          <p-col>
            <p-button @click="gotoShop">
              <p-icon left>
                arrow_back
              </p-icon>
              {{ $t('shop.basket.backToTheShop') }}
            </p-button>
          </p-col>
        </p-row>
      </p-col>
    </p-row>
    <template v-else>
      <p-row column>
        <p-col v-for="item in basket.items" :key="item.id">
          <gs-basket-item :disabled="disabled" :readonly="readonly" :value="item" @input="updateItemQuantity"></gs-basket-item>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12>
          <p-divider></p-divider>
        </p-col>
        <p-col xs12 justify-end>
          <p-row align-center>
            <p-col shrink>
              <slot name="footer"></slot>
            </p-col>
            <p-col grow>
              <p-row v-if="deliveryCost.totalGrossPrice !== undefined" dense>
                <p-col justify-end>
                  <span>{{ $t('shop.deliveryCost') }} {{ $format.currency(deliveryCost.totalGrossPrice, basket.currency) }}</span><br>
                </p-col>
              </p-row>
              <p-row dense>
                <p-col justify-end>
                  <span><strong>{{ isSubtotal ? $t('shop.subtotal') : $t('shop.total') }} {{ $format.currency(basket.totalGrossPrice, basket.currency) }}</strong></span>
                </p-col>
              </p-row>
            </p-col>
          </p-row>
        </p-col>
        <p-col xs12>
          <p-divider></p-divider>
        </p-col>
      </p-row>
    </template>
  </div>
</template>

<script lang="ts">
  import ShopBasketItemsListResourceViewModel from '../../services/v1/viewModel/resource/ShopBasketItemsListResourceViewModel';
  import ShopBasketResourceViewModel from '../../services/v1/viewModel/resource/ShopBasketResourceViewModel';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'GsBasketSummary',
    props: {
      readonly: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      isSubtotal: { type: Boolean, default: false },
    },
    data: () => ({
      deliveryCost: new ShopBasketItemsListResourceViewModel(),
      errorResponse: undefined as any,
      isLoading: false,
      basket: new ShopBasketResourceViewModel(),
    }),
    computed: {
      totalCost() {
        return this.basket.items.reduce((prev: number, item) => (item.quantity ?? 0) + prev, 0);
      },
      hasItems() {
        return (this.basket?.items?.length ?? 0) > 0;
      },
    },
    beforeMount() {
      this.populateData();
      this.$eventbus.on('BASKETCHANGE', this.populateData);
    },
    beforeDestroy() {
      this.$eventbus.off('BASKETCHANGE', this.populateData);
    },
    methods: {
      gotoShop() {
        this.$router.push('/gs/shop/', () => {});
      },
      updateItemQuantity(item: ShopBasketItemsListResourceViewModel) {
        // Because you should not bind a variable inside a loop it must be updated like this
        this.basket.items.forEach((i) => {
          if (i.id === item.id) {
            i.quantity = item.quantity;
          }
        });
      },
      async populateData() {
        this.isLoading = true;
        try {
          this.basket = await this.$service.api.shopBasket.getBasket();
          this.deliveryCost = this.basket.items.find((i) => i.isDeliveryCost) ?? new ShopBasketItemsListResourceViewModel();
          this.basket.items = this.basket.items.filter((i) => !i.isDeliveryCost);
          this.$emit('input', this.basket);
        } catch (error: any) {
          this.errorResponse = error;
        }
        this.isLoading = false;
      },
    },
  });
</script>
