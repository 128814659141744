// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/get-shop-orders-by-digital-asset-id-request-schema',
  type: 'object',
  required: ['id'],
  properties: {
    id: {
      type: 'integer',
    },
    currentPage: {
      type: 'integer',
    },
    pageSize: {
      type: 'integer',
    },
    includeCount: {
      type: 'boolean',
    },
    noPaging: {
      type: 'boolean',
    },
  },
} as IJSONSchema;

export default schema;
