// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetApiKeysRequestModel from '../../model/get-api-keys-request-model';
import GenerateApiKeyRequestModel from '../../model/generate-api-key-request-model';
import ReGenerateApiKeyRequestModel from '../../model/re-generate-api-key-request-model';
import DeleteApiKeyRequestModel from '../../model/delete-api-key-request-model';
import UserApiKeyPagingResultModel from '../../model/user-api-key-paging-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getApiKeys(request: GetApiKeysRequestModel, config?: RequestConfig) {
    const requestDTO = GetApiKeysRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-api-keys'] ?? 'core/ApiKeys';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(UserApiKeyPagingResultModel.toModel(dto));
  },
  /**  */
  async generateApiKey(request: GenerateApiKeyRequestModel, config?: RequestConfig) {
    const requestDTO = GenerateApiKeyRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-generate-api-key'] ?? 'core/ApiKeys/Generate';
    const response = await service.post<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        creatorId: requestDTO.creatorId,
        creatorName: requestDTO.creatorName,
        created: requestDTO.created,
        modifierId: requestDTO.modifierId,
        modifierName: requestDTO.modifierName,
        modified: requestDTO.modified,
        userId: requestDTO.userId,
        name: requestDTO.name,
        apiKeyOptions: requestDTO.apiKeyOptions,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as string | undefined);
  },
  /**  */
  async reGenerateApiKey(request: ReGenerateApiKeyRequestModel, config?: RequestConfig) {
    const requestDTO = ReGenerateApiKeyRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-re-generate-api-key'] ?? 'core/ApiKeys/{id}/ReGenerate';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.post<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as string | undefined);
  },
  /**  */
  async deleteApiKey(request: DeleteApiKeyRequestModel, config?: RequestConfig) {
    const requestDTO = DeleteApiKeyRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-api-key'] ?? 'core/ApiKeys/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
});
