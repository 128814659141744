p<template>
  <div class="gs-article-item fill-height hover-effect">
    <p-card :color="!value.isPublished ? 'app-1-darken-2' : undefined" fill-height @click="gotoArticleDetail">
      <gs-article-image :ratio="1.78" :image-id="value.articleNumber"></gs-article-image>
      <p-button v-if="isAdmin" class="absolute-top-right ma-1" dense @click.stop="gotoArticleEdit">
        <p-icon dense>
          edit
        </p-icon>
      </p-button>
      <h6 :class="{ 'article-title': true, 'my-3': true }">
        <p-icon v-if="!value.isPublished" left spacious>
          disabled_visible
        </p-icon> {{ $format.ellipsis(value.caption, 150) }}
      </h6>
      <span class="caption-2 mb-4">{{ $format.ellipsis(value.shortDescription, 150) }}</span>
      <p-row dense column>
        <p-col grow justify-end align-end>
          <gs-price-display :value="value"></gs-price-display>
        </p-col>
        <p-col shrink>
          <p-row align-end dense>
            <p-col grow>
              <gs-add-to-basket :article-id="value.id" stretch></gs-add-to-basket>
            </p-col>
          </p-row>
        </p-col>
      </p-row>
    </p-card>
  </div>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import ShopArticleResourceViewModel from '../../services/v1/viewModel/resource/ShopArticleResourceViewModel';

  export default Vue.extend({
    name: 'GsArticleItem',
    props: {
      value: { type: Object as PropType<ShopArticleResourceViewModel>, default: () => new ShopArticleResourceViewModel() },
    },
    data: () => ({

    }),
    computed: {
      isAdmin(): boolean {
        return this.$auth.isInRole('Admin', 'HostAdmin');
      },
      articleDescription(): string | undefined {
        return this.value.shortDescription ?? this.value.description ?? undefined;
      },
    },
    methods: {
      gotoArticleEdit() {
        this.$router.push(`/gs/shop/articles/${this.value.id}/edit`, () => {});
      },
      gotoArticleDetail() {
        this.$router.push(`/gs/shop/articles/${this.value.id}`, () => {});
      },
    },
  });
</script>
