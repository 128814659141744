// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetInvoiceAddressRequestModel from '../../model/get-invoice-address-request-model';
import UpdateInvoiceAddressRequestModel from '../../model/update-invoice-address-request-model';
import GetDeliveryAddressRequestModel from '../../model/get-delivery-address-request-model';
import UpdateDeliveryAddressRequestModel from '../../model/update-delivery-address-request-model';
import UpdateOrderInfoRequestModel from '../../model/update-order-info-request-model';
import SetPaymentRequestModel from '../../model/set-payment-request-model';
import GetOrderRequestModel from '../../model/get-order-request-model';
import ShopInvoiceAddressModel from '../../model/shop-invoice-address-model';
import ShopCheckoutOrderModel from '../../model/shop-checkout-order-model';
import ShopDeliveryAddressModel from '../../model/shop-delivery-address-model';

export default (service: Servicelayer) => ({
  /**  */
  async getInvoiceAddress(request: GetInvoiceAddressRequestModel, config?: RequestConfig) {
    const requestDTO = GetInvoiceAddressRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-invoice-address'] ?? 'ShopCheckout/{userId}/InvoiceAddress';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${requestDTO.userId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopInvoiceAddressModel.toModel(dto));
  },
  /**  */
  async updateInvoiceAddress(request: UpdateInvoiceAddressRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateInvoiceAddressRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-invoice-address'] ?? 'ShopCheckout/{userId}/InvoiceAddress';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${requestDTO.userId}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        salutation: requestDTO.salutation,
        company: requestDTO.company,
        firstName: requestDTO.firstName,
        lastName: requestDTO.lastName,
        street: requestDTO.street,
        houseNumber: requestDTO.houseNumber,
        poBox: requestDTO.poBox,
        additionalLine: requestDTO.additionalLine,
        postalCode: requestDTO.postalCode,
        city: requestDTO.city,
        countryIso2: requestDTO.countryIso2,
        phone: requestDTO.phone,
        mobilePhone: requestDTO.mobilePhone,
        email: requestDTO.email,
        isReadonly: requestDTO.isReadonly,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopCheckoutOrderModel.toModel(dto));
  },
  /**  */
  async getDeliveryAddress(request: GetDeliveryAddressRequestModel, config?: RequestConfig) {
    const requestDTO = GetDeliveryAddressRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-delivery-address'] ?? 'ShopCheckout/{userId}/DeliveryAddress';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${requestDTO.userId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopDeliveryAddressModel.toModel(dto));
  },
  /**  */
  async updateDeliveryAddress(request: UpdateDeliveryAddressRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateDeliveryAddressRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-delivery-address'] ?? 'ShopCheckout/{userId}/DeliveryAddress';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${requestDTO.userId}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        isDifferent: requestDTO.isDifferent,
        salutation: requestDTO.salutation,
        company: requestDTO.company,
        firstName: requestDTO.firstName,
        lastName: requestDTO.lastName,
        street: requestDTO.street,
        houseNumber: requestDTO.houseNumber,
        poBox: requestDTO.poBox,
        additionalLine: requestDTO.additionalLine,
        postalCode: requestDTO.postalCode,
        city: requestDTO.city,
        countryIso2: requestDTO.countryIso2,
        phone: requestDTO.phone,
        mobilePhone: requestDTO.mobilePhone,
        email: requestDTO.email,
        isReadonly: requestDTO.isReadonly,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopCheckoutOrderModel.toModel(dto));
  },
  /**  */
  async updateOrderInfo(request: UpdateOrderInfoRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateOrderInfoRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-order-info'] ?? 'ShopCheckout/{userId}/OrderInfo';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${requestDTO.userId}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        orderReference: requestDTO.orderReference,
        paymentMethod: requestDTO.paymentMethod,
        acceptAgb: requestDTO.acceptAgb,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopCheckoutOrderModel.toModel(dto));
  },
  /**  */
  async setPayment(request: SetPaymentRequestModel, config?: RequestConfig) {
    const requestDTO = SetPaymentRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-set-payment'] ?? 'ShopCheckout/{userId}/Payment';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${requestDTO.userId}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        transactionId: requestDTO.transactionId,
        amount: requestDTO.amount,
        currency: requestDTO.currency,
        paymentMethod: requestDTO.paymentMethod,
        onlinePaymentMethod: requestDTO.onlinePaymentMethod,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopCheckoutOrderModel.toModel(dto));
  },
  /**  */
  async getOrder(request: GetOrderRequestModel, config?: RequestConfig) {
    const requestDTO = GetOrderRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-order'] ?? 'ShopCheckout/{userId}/Order';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${requestDTO.userId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopCheckoutOrderModel.toModel(dto));
  },
});
