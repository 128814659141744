// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import DownloadContactIdMappingReportRequestModel from '../../model/download-contact-id-mapping-report-request-model';
import DownloadContactIdMappingReportRequestDTO from '../dto/download-contact-id-mapping-report-request-dto';
import dtoSchema from '../schemas/download-contact-id-mapping-report-request-schema';

export default abstract class DownloadContactIdMappingReportRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<DownloadContactIdMappingReportRequestDTO>) {
    super();
    if (data) {
      this.customerName = data.customerName;
      this.foreignAppSystemId = data.foreignAppSystemId;
      this.crmStatusId = data.crmStatusId;
      this.mappingStatusId = data.mappingStatusId;
      this.searchCriteria = data.searchCriteria;
    }
  }
  /**
  */
  customerName?: string;
  /**
  * @type {int64}
  */
  foreignAppSystemId?: number;
  /**
  * @type {int32}
  */
  crmStatusId?: number;
  /**
  * @type {int32}
  */
  mappingStatusId?: number;
  /**
  */
  searchCriteria?: string;

  static toModel(dto: DeepPartial<DownloadContactIdMappingReportRequestDTO>): DownloadContactIdMappingReportRequestModel;
  static toModel(dto: DeepPartial<DownloadContactIdMappingReportRequestDTO> | undefined | null): DownloadContactIdMappingReportRequestModel | undefined;
  static toModel(dto: DeepPartial<DownloadContactIdMappingReportRequestDTO> | undefined | null): DownloadContactIdMappingReportRequestModel | undefined {
    return dto ? new DownloadContactIdMappingReportRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<DownloadContactIdMappingReportRequestModel> | DownloadContactIdMappingReportRequestModel): DownloadContactIdMappingReportRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      customerName: unwrapped.customerName,
      foreignAppSystemId: unwrapped.foreignAppSystemId,
      crmStatusId: unwrapped.crmStatusId,
      mappingStatusId: unwrapped.mappingStatusId,
      searchCriteria: unwrapped.searchCriteria,
    } as DownloadContactIdMappingReportRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for DownloadContactIdMappingReportRequestModel, can not map to DownloadContactIdMappingReportRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
