// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/update-shop-order-item-request-schema',
  type: 'object',
  required: ['id', 'shopOrderId', 'shopArticleId', 'quantity', 'unitNetPrice', 'unitGrossPrice', 'taxRate', 'taxAmount', 'totalNetPrice', 'totalGrossPrice', 'crmItemPriceNumber', 'id'],
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    shopOrderId: {
      type: 'integer',
    },
    shopArticleId: {
      type: 'integer',
    },
    shopArticleNumber: {
      type: 'string',
      nullable: true,
    },
    caption: {
      type: 'string',
      nullable: true,
    },
    shopSupplierId: {
      type: 'integer',
      nullable: true,
    },
    shopSupplierArticleNumber: {
      type: 'string',
      nullable: true,
    },
    isDelivery: {
      type: 'boolean',
    },
    hasLicense: {
      type: 'boolean',
    },
    isDownload: {
      type: 'boolean',
    },
    digitalAssetId: {
      type: 'integer',
      nullable: true,
    },
    quantity: {
      type: 'integer',
    },
    unitNetPrice: {
      type: 'number',
    },
    unitGrossPrice: {
      type: 'number',
    },
    taxRate: {
      type: 'number',
    },
    taxAmount: {
      type: 'number',
    },
    totalNetPrice: {
      type: 'number',
    },
    totalGrossPrice: {
      type: 'number',
    },
    crmItemPriceNumber: {
      type: 'integer',
    },
  },
} as IJSONSchema;

export default schema;
