// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetAppSystemWebApiListRequestViewModel from '../../viewModel/request/AppSystemWebApis/GetAppSystemWebApiListRequestViewModel';
import AppSystemWebApiResourceViewModel from '../../viewModel/resource/AppSystemWebApiResourceViewModel';
import AppSystemWebApiResourcePagingResultViewModel from '../../viewModel/resource/AppSystemWebApiResourcePagingResultViewModel';
import AppSystemWebApiCreateRequestViewModel from '../../viewModel/resource/AppSystemWebApiCreateRequestViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getAppSystemWebApiById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-app-system-web-api-by-id'] ?? 'core/integration/AppSystemWebApis/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new AppSystemWebApiResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateAppSystemWebApi(id: number, model: AppSystemWebApiResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-app-system-web-api'] ?? 'core/integration/AppSystemWebApis/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
  },
  /**  */
  async deleteAppSystemWebApi(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-app-system-web-api'] ?? 'core/integration/AppSystemWebApis/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getAppSystemWebApiList(request: GetAppSystemWebApiListRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-app-system-web-api-list'] ?? 'core/integration/AppSystemWebApis';
    const response = await service.get<any>(endpointPath, {
      query: {
        appSystemId: requestDTO.appSystemId,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new AppSystemWebApiResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async createAppSystemWebApi(model: AppSystemWebApiCreateRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-app-system-web-api'] ?? 'core/integration/AppSystemWebApis';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async checkWebApiConnection(model: AppSystemWebApiResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-check-web-api-connection'] ?? 'core/integration/AppSystemWebApis/CheckWebApiConnection';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as boolean | undefined;
  },
});
