import Vue from 'vue';
import BusinessCharacteristicsResourceModel from '../../generated/types/model/resource/BusinessCharacteristicsResourceModel';

export default class BusinessCharacteristicsResourceViewModel extends BusinessCharacteristicsResourceModel {
  getSummaryItems() {
    const arr = [];
    if (this.ownershipType[0]) {
      arr.push({
        label: Vue.$t('memberPortal.createMembership.label.ownershipType'),
        displayValue: this.ownershipType[0].name,
      });
    }
    if (this.operatingType[0]) {
      arr.push({
        label: Vue.$t('memberPortal.createMembership.label.operatingMode'),
        displayValue: this.operatingType[0].name,
      });
    }
    return arr.concat(this.getAdditionalInformationSummaryItems());
  }

  getAdditionalInformationSummaryItems() {
    const arr = [];
    if (this.restDays != null && this.restDays.length > 0) {
      arr.push({
        label: Vue.$t('memberPortal.createMembership.label.restDays'),
        displayValue: this.restDays.map((x) => x.name).join(', '),
      });
    }
    if (this.communicationChannels != null && this.communicationChannels.length > 0) {
      arr.push({
        label: Vue.$t('memberPortal.createMembership.label.communicationChannels'),
        displayValue: this.communicationChannels.map((x) => x.name).join(', '),
      });
    }
    if (this.paymentMethods != null && this.paymentMethods.length > 0) {
      arr.push({
        label: Vue.$t('memberPortal.createMembership.label.paymentMethods'),
        displayValue: this.paymentMethods.map((x) => x.name).join(', '),
      });
    }
    if (this.equipmentFeatures != null && this.equipmentFeatures.length > 0) {
      arr.push({
        label: Vue.$t('memberPortal.createMembership.label.equipmentFeatures'),
        displayValue: this.equipmentFeatures.map((x) => x.name).join(', '),
      });
    }
    return arr;
  }

  getGastronomySummaryItems() {
    const arr = [];
    if (this.operatingArea[0]) {
      arr.push({
        label: Vue.$t('memberPortal.createMembership.label.operationArea'),
        displayValue: this.operatingArea[0].name,
      });
    }
    if (this.operatingArea != null && this.gastronomicType[0]) {
      arr.push({
        label: Vue.$t('memberPortal.createMembership.label.gastronomicType'),
        displayValue: this.gastronomicType[0].name,
      });
    }
    if (this.kitchenStyle != null && this.kitchenStyle.length > 0) {
      arr.push({
        label: Vue.$t('memberPortal.createMembership.label.kitchenStyle'),
        displayValue: this.kitchenStyle.map((x) => x.name).join(', '),
      });
    }
    if (this.expandedCateringOffers != null && this.expandedCateringOffers.length > 0) {
      arr.push({
        label: Vue.$t('memberPortal.createMembership.label.expandedCateringOffers'),
        displayValue: this.expandedCateringOffers.map((x) => x.name).join(', '),
      });
    }
    return arr;
  }

  getAccommodationSummaryItems() {
    const arr = [];
    if (this.accommodationType[0]) {
      arr.push({
        label: Vue.$t('memberPortal.createMembership.label.accommodationType'),
        displayValue: this.accommodationType.map((x) => x.name).join(', '),
      });
    }
    if (this.wantsConsulting === true) {
      arr.push({
        label: Vue.$t('memberPortal.createMembership.label.wantsConsulting'),
        displayValue: Vue.$t(`app.${this.wantsConsulting ? 'yes' : 'no'}`),
      });
    }
    return arr;
  }

  // For instructions on how to use ViewModels visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/155/ViewModels-Models-DTOs
}
