// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import OperationAreaRequestModel from '../../model/operation-area-request-model';
import OperationAreaRequestDTO from '../dto/operation-area-request-dto';
import dtoSchema from '../schemas/operation-area-request-schema';

export default abstract class OperationAreaRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<OperationAreaRequestDTO>) {
    super();
    this.paging = {};
    if (data) {
      this.paging = {
        currentPage: data['paging.CurrentPage'],
        pageSize: data['paging.PageSize'],
        includeCount: data['paging.IncludeCount'],
        noPaging: data['paging.NoPaging'],
      };
      this.id = data.id;
      this.key = data.key;
      this.searchCriteria = data.searchCriteria;
    }
  }

  paging: {
    /**
    * @type {int32}
    */
    currentPage?: number;
    /**
    * @type {int32}
    */
    pageSize?: number;
    /**
    */
    includeCount?: boolean;
    /**
    */
    noPaging?: boolean;
  };

  /**
  * @type {uuid}
  */
  id?: string;
  /**
  */
  key?: string;
  /**
  */
  searchCriteria?: string;

  static toModel(dto: DeepPartial<OperationAreaRequestDTO>): OperationAreaRequestModel;
  static toModel(dto: DeepPartial<OperationAreaRequestDTO> | undefined | null): OperationAreaRequestModel | undefined;
  static toModel(dto: DeepPartial<OperationAreaRequestDTO> | undefined | null): OperationAreaRequestModel | undefined {
    return dto ? new OperationAreaRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<OperationAreaRequestModel> | OperationAreaRequestModel): OperationAreaRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      'paging.CurrentPage': unwrapped.paging?.currentPage,
      'paging.PageSize': unwrapped.paging?.pageSize,
      'paging.IncludeCount': unwrapped.paging?.includeCount,
      'paging.NoPaging': unwrapped.paging?.noPaging,
      id: unwrapped.id,
      key: unwrapped.key,
      searchCriteria: unwrapped.searchCriteria,
    } as OperationAreaRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for OperationAreaRequestModel, can not map to OperationAreaRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
