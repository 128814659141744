// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import LookupCategoriesRequestViewModel from '../../viewModel/request/ShopCategories/LookupCategoriesRequestViewModel';
import GetArticlesListByCategoryRequestViewModel from '../../viewModel/request/ShopCategories/GetArticlesListByCategoryRequestViewModel';
import GetCategoryByIdRequestViewModel from '../../viewModel/request/ShopCategories/GetCategoryByIdRequestViewModel';
import ShopCategoriesListResourceListResultViewModel from '../../viewModel/resource/ShopCategoriesListResourceListResultViewModel';
import ShopCategoryResourceViewModel from '../../viewModel/resource/ShopCategoryResourceViewModel';
import Int64LookupResourceListResultViewModel from '../../viewModel/resource/Int64LookupResourceListResultViewModel';
import ShopArticlesListByCategoryResourcePagingResultViewModel from '../../viewModel/resource/ShopArticlesListByCategoryResourcePagingResultViewModel';
import UpdateArticlesInCategoryRequestViewModel from '../../viewModel/resource/UpdateArticlesInCategoryRequestViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getCategories(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-categories'] ?? 'ShopCategories';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new ShopCategoriesListResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async createCategory(model: ShopCategoryResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-category'] ?? 'ShopCategories';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async lookupCategories(request: LookupCategoriesRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-categories'] ?? 'ShopCategories/Lookup';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new Int64LookupResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getArticlesListByCategory(id: number, request: GetArticlesListByCategoryRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-articles-list-by-category'] ?? 'ShopCategories/{id}/Articles';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        includeChildrenCategories: requestDTO.includeChildrenCategories,
        isPublished: requestDTO.isPublished,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new ShopArticlesListByCategoryResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async updateArticlesInShopCategory(id: number, model: UpdateArticlesInCategoryRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-articles-in-shop-category'] ?? 'ShopCategories/{id}/Articles';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as boolean | undefined;
  },
  /**  */
  async getCategoryById(id: number, request: GetCategoryByIdRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-category-by-id'] ?? 'ShopCategories/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        translatedLanguage_ISO2: requestDTO.translatedLanguage_ISO2,
      },
      ...config,
    });
    const dto = response.data.result;
    return new ShopCategoryResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateShopCategory(id: number, model: ShopCategoryResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-shop-category'] ?? 'ShopCategories/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async deleteShopCategory(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-shop-category'] ?? 'ShopCategories/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
});
