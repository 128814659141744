// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ShopArticleTaxHistoryResourceViewModel from '../../../../viewModel/resource/ShopArticleTaxHistoryResourceViewModel';
import ShopArticleTaxHistoryResourceDTO from '../../dto/resource/ShopArticleTaxHistoryResourceDTO';

export default abstract class ShopArticleTaxHistoryResourceModel extends BaseModel<ShopArticleTaxHistoryResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get shopArticleTaxId() { return this.dto.shopArticleTaxId; }
  set shopArticleTaxId(value) { this.dto.shopArticleTaxId = value; }
  /**
  * @type {double}
  */
  get taxRate() { return this.dto.taxRate; }
  set taxRate(value) { this.dto.taxRate = value; }
  /**
  * @type {date-time}
  */
  get validFrom() { return this.dto.validFrom; }
  set validFrom(value) { this.dto.validFrom = value; }
  /**
  * @type {date-time}
  */
  get validTo() { return this.dto.validTo; }
  set validTo(value) { this.dto.validTo = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.shoparticletaxid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.shopArticleTaxId = value;
    value = Number.parseFloat(qs.taxrate);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.taxRate = value;
    this.dto.validFrom = qs.validfrom;
    this.dto.validTo = qs.validto;
  }
}
