// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import SequenceNumberListResourceViewModel from '../../../../viewModel/resource/SequenceNumberListResourceViewModel';
import SequenceNumberListResourceDTO from '../../dto/resource/SequenceNumberListResourceDTO';

export default abstract class SequenceNumberListResourceModel extends BaseModel<SequenceNumberListResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  */
  get name() { return this.dto.name; }
  set name(value) { this.dto.name = value; }
  /**
  */
  get sequenceName() { return this.dto.sequenceName; }
  set sequenceName(value) { this.dto.sequenceName = value; }
  /**
  * @type {int32}
  */
  get startNumber() { return this.dto.startNumber; }
  set startNumber(value) { this.dto.startNumber = value; }
  /**
  * @type {int32}
  */
  get increment() { return this.dto.increment; }
  set increment(value) { this.dto.increment = value; }
  /**
  * @type {int32}
  */
  get currentValue() { return this.dto.currentValue; }
  set currentValue(value) { this.dto.currentValue = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.creatorid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = qs.creatorname;
    this.dto.created = qs.created;
    value = Number.parseFloat(qs.modifierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = qs.modifiername;
    this.dto.modified = qs.modified;
    this.dto.name = qs.name;
    this.dto.sequenceName = qs.sequencename;
    value = Number.parseFloat(qs.startnumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.startNumber = value;
    value = Number.parseFloat(qs.increment);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.increment = value;
    value = Number.parseFloat(qs.currentvalue);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.currentValue = value;
  }
}
