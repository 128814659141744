// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ShopTaxedPriceResourceViewModel from '../../../../viewModel/resource/ShopTaxedPriceResourceViewModel';
import ShopTaxedPriceResourceDTO from '../../dto/resource/ShopTaxedPriceResourceDTO';

export default abstract class ShopTaxedPriceResourceModel extends BaseModel<ShopTaxedPriceResourceDTO> {
  /**
  * @type {double}
  */
  get unitGrossPrice() { return this.dto.unitGrossPrice; }
  set unitGrossPrice(value) { this.dto.unitGrossPrice = value; }
  /**
  * @type {double}
  */
  get unitNetPrice() { return this.dto.unitNetPrice; }
  set unitNetPrice(value) { this.dto.unitNetPrice = value; }
  /**
  * @type {double}
  */
  get taxRate() { return this.dto.taxRate; }
  set taxRate(value) { this.dto.taxRate = value; }
  /**
  * @type {double}
  */
  get taxAmount() { return this.dto.taxAmount; }
  set taxAmount(value) { this.dto.taxAmount = value; }
  /**
  * @type {int64}
  */
  get crmItemPriceNumber() { return this.dto.crmItemPriceNumber; }
  set crmItemPriceNumber(value) { this.dto.crmItemPriceNumber = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.unitgrossprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.unitGrossPrice = value;
    value = Number.parseFloat(qs.unitnetprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.unitNetPrice = value;
    value = Number.parseFloat(qs.taxrate);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.taxRate = value;
    value = Number.parseFloat(qs.taxamount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.taxAmount = value;
    value = Number.parseFloat(qs.crmitempricenumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.crmItemPriceNumber = value;
  }
}
