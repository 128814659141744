// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/update-shop-article-request-schema',
  type: 'object',
  required: ['id', 'hasShipping', 'validFrom', 'taxId', 'translatedLanguage_ISO2', 'id'],
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    supplierId: {
      type: 'integer',
      nullable: true,
    },
    articleNumber: {
      type: 'string',
      nullable: true,
    },
    crmArticleId: {
      type: 'string',
      nullable: true,
    },
    supplierArticleNumber: {
      type: 'string',
      nullable: true,
    },
    caption: {
      type: 'string',
      nullable: true,
    },
    shortDescription: {
      type: 'string',
      nullable: true,
    },
    description: {
      type: 'string',
      nullable: true,
    },
    mainPictureId: {
      type: 'integer',
      nullable: true,
    },
    picture1Id: {
      type: 'integer',
      nullable: true,
    },
    picture2Id: {
      type: 'integer',
      nullable: true,
    },
    picture3Id: {
      type: 'integer',
      nullable: true,
    },
    hasShipping: {
      type: 'boolean',
    },
    additionalInfo: {
      type: 'string',
      nullable: true,
    },
    validFrom: {
      type: 'string',
      format: 'date-time',
    },
    validTo: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    isPublished: {
      type: 'boolean',
    },
    taxId: {
      type: 'integer',
    },
    translatedLanguage_ISO2: {
      type: 'string',
      minLength: 1,
    },
    hasLicense: {
      type: 'boolean',
    },
    isDownload: {
      type: 'boolean',
    },
    digitalAssetId: {
      type: 'integer',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
