// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import DynamicFormPayloadSchema from './dynamic-form-payload-schema';

const schema = {
  $id: '/post-dynamic-form-request-schema',
  type: 'object',
  required: ['appSystemKey', 'form'],
  properties: {
    appSystemKey: {
      type: 'string',
      minLength: 1,
    },
    form: {
      ...DynamicFormPayloadSchema,
    },
  },
} as IJSONSchema;

export default schema;
