// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UploadDigitalAssetRequestModel from '../../model/upload-digital-asset-request-model';
import UploadDigitalAssetRequestDTO from '../dto/upload-digital-asset-request-dto';
import dtoSchema from '../schemas/upload-digital-asset-request-schema';

export default abstract class UploadDigitalAssetRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<UploadDigitalAssetRequestDTO>) {
    super();
    if (data) {
      this.uploadFile = data.uploadFile;
      this.name = data.name;
    }
  }
  /**
  * @type {binary}
  */
  uploadFile?: File;
  /**
  */
  name?: string;

  static toModel(dto: DeepPartial<UploadDigitalAssetRequestDTO>): UploadDigitalAssetRequestModel;
  static toModel(dto: DeepPartial<UploadDigitalAssetRequestDTO> | undefined | null): UploadDigitalAssetRequestModel | undefined;
  static toModel(dto: DeepPartial<UploadDigitalAssetRequestDTO> | undefined | null): UploadDigitalAssetRequestModel | undefined {
    return dto ? new UploadDigitalAssetRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<UploadDigitalAssetRequestModel> | UploadDigitalAssetRequestModel): UploadDigitalAssetRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      uploadFile: unwrapped.uploadFile,
      name: unwrapped.name,
    } as UploadDigitalAssetRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for UploadDigitalAssetRequestModel, can not map to UploadDigitalAssetRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
