// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetDigitalAssetsRequestViewModel from '../../viewModel/request/DigitalAsset/GetDigitalAssetsRequestViewModel';
import UpdateDigitalAssetRequestViewModel from '../../viewModel/request/DigitalAsset/UpdateDigitalAssetRequestViewModel';
import UploadDigitalAssetRequestViewModel from '../../viewModel/request/DigitalAsset/UploadDigitalAssetRequestViewModel';
import DigitalAssetPagingResultViewModel from '../../viewModel/resource/DigitalAssetPagingResultViewModel';
import DigitalAssetWithCountAndFileNameViewModel from '../../viewModel/resource/DigitalAssetWithCountAndFileNameViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getDigitalAssets(request: GetDigitalAssetsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-digital-assets'] ?? 'DigitalAsset';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        filename: requestDTO.filename,
        extension: requestDTO.extension,
        isAssigned: requestDTO.isAssigned,
        isSold: requestDTO.isSold,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new DigitalAssetPagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getDigitalAsset(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-digital-asset'] ?? 'DigitalAsset/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new DigitalAssetWithCountAndFileNameViewModel().fromDTO(dto);
  },
  /**  */
  async updateDigitalAsset(id: number, request: UpdateDigitalAssetRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-digital-asset'] ?? 'DigitalAsset/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.put<any>(endpointPath, {
      query: {
        fileName: requestDTO.fileName,
      },
      ...config,
    });
  },
  /**  */
  async deleteDigitalAsset(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-digital-asset'] ?? 'DigitalAsset/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
  },
  /**  */
  async uploadDigitalAsset(request: UploadDigitalAssetRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    const formData = new FormData();
    formData.append('uploadFile', requestDTO.uploadFile as Blob);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-upload-digital-asset'] ?? 'DigitalAsset/Upload';
    const response = await service.post<any>(endpointPath, {
      body: formData,
      headers: {
      },
      query: {
        name: requestDTO.name,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async digitalAssetFileNameById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-digital-asset-file-name-by-id'] ?? 'DigitalAsset/DigitalAssetFileNameById/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as string | undefined;
  },
  /**  */
  async adminDownloadAsset(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-admin-download-asset'] ?? 'DigitalAsset/DownloadAsset/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return new Blob([result], { type: 'application/octet-stream' });
  },
});
