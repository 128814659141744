// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import ShopArticleTaxHistoryResourceListResultViewModel from '../../viewModel/resource/ShopArticleTaxHistoryResourceListResultViewModel';
import ShopArticleTaxHistoryResourceViewModel from '../../viewModel/resource/ShopArticleTaxHistoryResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getShopArticleTaxHistories(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-article-tax-histories'] ?? 'ShopArticleTaxHistories';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new ShopArticleTaxHistoryResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async createShopArticleTaxHistory(model: ShopArticleTaxHistoryResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-shop-article-tax-history'] ?? 'ShopArticleTaxHistories';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getShopArticleTaxHistoryByShopArticleTaxId(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-article-tax-history-by-shop-article-tax-id'] ?? 'ShopArticleTaxHistories/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new ShopArticleTaxHistoryResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateShopArticleTaxHistory(id: number, model: ShopArticleTaxHistoryResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-shop-article-tax-history'] ?? 'ShopArticleTaxHistories/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async deleteShopArticleTaxHistoryById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-shop-article-tax-history-by-id'] ?? 'ShopArticleTaxHistories/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
});
