// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/calculate-membership-request-schema',
  type: 'object',
  required: ['businessUnitId', 'categoryId'],
  properties: {
    businessUnitId: {
      type: 'string',
      format: 'uuid',
    },
    categoryId: {
      type: 'integer',
    },
    isMembershipReEntry: {
      type: 'boolean',
    },
    postalCode: {
      type: 'string',
    },
  },
} as IJSONSchema;

export default schema;
