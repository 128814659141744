// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ConsultingCaseListResourceViewModel from '../../../../viewModel/resource/ConsultingCaseListResourceViewModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ConsultingCaseListResourceListResultViewModel from '../../../../viewModel/resource/ConsultingCaseListResourceListResultViewModel';
import ConsultingCaseListResourceListResultDTO from '../../dto/resource/ConsultingCaseListResourceListResultDTO';

export default abstract class ConsultingCaseListResourceListResultModel extends BaseModel<ConsultingCaseListResourceListResultDTO> {
  /**
  */
  get items() { return this.getArrayModels(() => this.dto.items, ConsultingCaseListResourceViewModel)!; }
  set items(value) { this.setArrayModels(() => this.dto.items, value); }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
  }
}
