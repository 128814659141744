// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import PaymentTypeViewModel from '../../../../viewModel/resource/PaymentTypeViewModel';
import OnlinePaymentResourceViewModel from '../../../../viewModel/resource/OnlinePaymentResourceViewModel';
import OnlinePaymentResourceDTO from '../../dto/resource/OnlinePaymentResourceDTO';

export default abstract class OnlinePaymentResourceModel extends BaseModel<OnlinePaymentResourceDTO> {
  /**
  */
  get transactionId() { return this.dto.transactionId; }
  set transactionId(value) { this.dto.transactionId = value; }
  /**
  * @type {date-time}
  */
  get paymentDate() { return this.dto.paymentDate; }
  set paymentDate(value) { this.dto.paymentDate = value; }
  /**
  */
  get currency() { return this.dto.currency; }
  set currency(value) { this.dto.currency = value; }
  /**
  * @type {double}
  */
  get amount() { return this.dto.amount; }
  set amount(value) { this.dto.amount = value; }
  /**
  * @type {int32}
  */
  get paymentType() { return this.dto.paymentType; }
  set paymentType(value) { this.dto.paymentType = value; }
  /**
  */
  get onlinePaymentMethodRaw() { return this.dto.onlinePaymentMethodRaw; }
  set onlinePaymentMethodRaw(value) { this.dto.onlinePaymentMethodRaw = value; }
  /**
  */
  get onlinePaymentBrandCodeRaw() { return this.dto.onlinePaymentBrandCodeRaw; }
  set onlinePaymentBrandCodeRaw(value) { this.dto.onlinePaymentBrandCodeRaw = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.transactionId = qs.transactionid;
    this.dto.paymentDate = qs.paymentdate;
    this.dto.currency = qs.currency;
    value = Number.parseFloat(qs.amount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.amount = value;
    value = Number.parseFloat(qs.paymenttype);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.paymentType = value;
    this.dto.onlinePaymentMethodRaw = qs.onlinepaymentmethodraw;
    this.dto.onlinePaymentBrandCodeRaw = qs.onlinepaymentbrandcoderaw;
  }
}
