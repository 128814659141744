// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ProcessTransactionRequestModel from '../../model/process-transaction-request-model';
import ProcessTransactionRequestDTO from '../dto/process-transaction-request-dto';
import dtoSchema from '../schemas/process-transaction-request-schema';

export default abstract class ProcessTransactionRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ProcessTransactionRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
    }
  }
  /**
  */
  id?: string;

  static toModel(dto: DeepPartial<ProcessTransactionRequestDTO>): ProcessTransactionRequestModel;
  static toModel(dto: DeepPartial<ProcessTransactionRequestDTO> | undefined | null): ProcessTransactionRequestModel | undefined;
  static toModel(dto: DeepPartial<ProcessTransactionRequestDTO> | undefined | null): ProcessTransactionRequestModel | undefined {
    return dto ? new ProcessTransactionRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ProcessTransactionRequestModel> | ProcessTransactionRequestModel): ProcessTransactionRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
    } as ProcessTransactionRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ProcessTransactionRequestModel, can not map to ProcessTransactionRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
