// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UpdateBusinessCharacteristicsRequestModel from '../../model/update-business-characteristics-request-model';
import UpdateBusinessCharacteristicsRequestDTO from '../dto/update-business-characteristics-request-dto';
import dtoSchema from '../schemas/update-business-characteristics-request-schema';
import BusinessCharacteristicModel from '../../model/business-characteristic-model';

export default abstract class UpdateBusinessCharacteristicsRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<UpdateBusinessCharacteristicsRequestDTO>) {
    super();
    if (data) {
      this.ownershipType = data.ownershipType?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.operatingType = data.operatingType?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.operatingArea = data.operatingArea?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.gastronomicType = data.gastronomicType?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.wantsConsulting = data.wantsConsulting;
      this.kitchenStyle = data.kitchenStyle?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.accommodationType = data.accommodationType?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.expandedCateringOffers = data.expandedCateringOffers?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.restDays = data.restDays?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.communicationChannels = data.communicationChannels?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.paymentMethods = data.paymentMethods?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.equipmentFeatures = data.equipmentFeatures?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.membershipId = data.membershipId;
      this.memberBusinessId = data.memberBusinessId;
      this.id = data.id;
      this.businessId = data.businessId;
    }
  }
  /**
  */
  ownershipType?: BusinessCharacteristicModel[];
  /**
  */
  operatingType?: BusinessCharacteristicModel[];
  /**
  */
  operatingArea?: BusinessCharacteristicModel[];
  /**
  */
  gastronomicType?: BusinessCharacteristicModel[];
  /**
  */
  wantsConsulting?: boolean;
  /**
  */
  kitchenStyle?: BusinessCharacteristicModel[];
  /**
  */
  accommodationType?: BusinessCharacteristicModel[];
  /**
  */
  expandedCateringOffers?: BusinessCharacteristicModel[];
  /**
  */
  restDays?: BusinessCharacteristicModel[];
  /**
  */
  communicationChannels?: BusinessCharacteristicModel[];
  /**
  */
  paymentMethods?: BusinessCharacteristicModel[];
  /**
  */
  equipmentFeatures?: BusinessCharacteristicModel[];
  /**
  * @type {int64}
  */
  membershipId?: number;
  /**
  * @type {uuid}
  */
  memberBusinessId?: string;
  /**
  * @type {int64}
  */
  id?: number;
  /**
  * @type {uuid}
  */
  businessId?: string;

  static toModel(dto: DeepPartial<UpdateBusinessCharacteristicsRequestDTO>): UpdateBusinessCharacteristicsRequestModel;
  static toModel(dto: DeepPartial<UpdateBusinessCharacteristicsRequestDTO> | undefined | null): UpdateBusinessCharacteristicsRequestModel | undefined;
  static toModel(dto: DeepPartial<UpdateBusinessCharacteristicsRequestDTO> | undefined | null): UpdateBusinessCharacteristicsRequestModel | undefined {
    return dto ? new UpdateBusinessCharacteristicsRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<UpdateBusinessCharacteristicsRequestModel> | UpdateBusinessCharacteristicsRequestModel): UpdateBusinessCharacteristicsRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      ownershipType: unwrapped.ownershipType,
      operatingType: unwrapped.operatingType,
      operatingArea: unwrapped.operatingArea,
      gastronomicType: unwrapped.gastronomicType,
      wantsConsulting: unwrapped.wantsConsulting,
      kitchenStyle: unwrapped.kitchenStyle,
      accommodationType: unwrapped.accommodationType,
      expandedCateringOffers: unwrapped.expandedCateringOffers,
      restDays: unwrapped.restDays,
      communicationChannels: unwrapped.communicationChannels,
      paymentMethods: unwrapped.paymentMethods,
      equipmentFeatures: unwrapped.equipmentFeatures,
      membershipId: unwrapped.membershipId,
      memberBusinessId: unwrapped.memberBusinessId,
      id: unwrapped.id,
      businessId: unwrapped.businessId,
    } as UpdateBusinessCharacteristicsRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for UpdateBusinessCharacteristicsRequestModel, can not map to UpdateBusinessCharacteristicsRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
