// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetUsersRequestViewModel from '../../viewModel/request/Users/GetUsersRequestViewModel';
import ContactResourceViewModel from '../../viewModel/resource/ContactResourceViewModel';
import UserRegisterRequestViewModel from '../../viewModel/resource/UserRegisterRequestViewModel';
import UserForgotPasswordRequestViewModel from '../../viewModel/resource/UserForgotPasswordRequestViewModel';
import UserChangePasswordRequestViewModel from '../../viewModel/resource/UserChangePasswordRequestViewModel';
import SetPasswordForUserRequestViewModel from '../../viewModel/resource/SetPasswordForUserRequestViewModel';
import UserResourcePagingResultViewModel from '../../viewModel/resource/UserResourcePagingResultViewModel';
import UserResourceViewModel from '../../viewModel/resource/UserResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getUserContact(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-user-contact'] ?? 'Users/{id}/UserContact';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new ContactResourceViewModel().fromDTO(dto);
  },
  /**  */
  async register(model: UserRegisterRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-register'] ?? 'core/Users/Register';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async forgotPassword(model: UserForgotPasswordRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-forgot-password'] ?? 'core/Users/ForgotPassword';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
  },
  /**  */
  async changePassword(model: UserChangePasswordRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-change-password'] ?? 'core/Users/ChangePassword';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
  },
  /**  */
  async setPasswordForUser(id: number, model: SetPasswordForUserRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-set-password-for-user'] ?? 'core/Users/{id}/SetPassword';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
  },
  /**  */
  async getUsers(request: GetUsersRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-users'] ?? 'core/Users';
    const response = await service.get<any>(endpointPath, {
      query: {
        searchCriteria: requestDTO.searchCriteria,
        statusId: requestDTO.statusId,
        isDeleted: requestDTO.isDeleted,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new UserResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getUserById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-user-by-id'] ?? 'core/Users/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new UserResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateUser(id: number, model: UserResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-user'] ?? 'core/Users/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
  },
  /**  */
  async deleteUser(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-user'] ?? 'core/Users/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as boolean | undefined;
  },
  /**  */
  async setUserStatus(id: number, newStatusId: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-set-user-status'] ?? 'core/Users/{id}/SetStatus/{newStatusId}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    endpointPath = endpointPath.replace(/\{newStatusId\}/g, `${newStatusId}`);
    const response = await service.put<any>(endpointPath, {
      ...config,
    });
  },
  /**  */
  async setUserAppRole(id: number, newRoleId: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-set-user-app-role'] ?? 'core/Users/{id}/SetAppRole/{newRoleId}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    endpointPath = endpointPath.replace(/\{newRoleId\}/g, `${newRoleId}`);
    const response = await service.put<any>(endpointPath, {
      ...config,
    });
  },
  /**  */
  async restoreUser(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-restore-user'] ?? 'core/Users/restore/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.patch<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as boolean | undefined;
  },
});
