// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ESuranceMembershipBusinessModel from '../../model/e-surance-membership-business-model';
import ESuranceMembershipBusinessDTO from '../dto/e-surance-membership-business-dto';
import dtoSchema from '../schemas/e-surance-membership-business-schema';
import BusinessCharacteristicsModel from '../../model/business-characteristics-model';

export default abstract class ESuranceMembershipBusinessModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ESuranceMembershipBusinessDTO>) {
    super();
    if (data) {
      this.accessionDate = data.accessionDate;
      this.name = data.name;
      this.street = data.street;
      this.houseNumber = data.houseNumber;
      this.zipCode = data.zipCode;
      this.city = data.city;
      this.hasApprenticeTraining = data.hasApprenticeTraining ?? undefined;
      this.isSeasonalBusiness = data.isSeasonalBusiness ?? undefined;
      this.businessCharacteristics = data.businessCharacteristics ? BusinessCharacteristicsModel.toModel(data.businessCharacteristics) : undefined;
    }
  }
  /**
  * @type {date-time}
  */
  accessionDate?: string;
  /**
  * @minimum 1
  */
  name?: string;
  /**
  * @minimum 1
  */
  street?: string;
  /**
  * @minimum 1
  */
  houseNumber?: string;
  /**
  * @minimum 1
  */
  zipCode?: string;
  /**
  * @minimum 1
  */
  city?: string;
  /**
  */
  hasApprenticeTraining?: boolean;
  /**
  */
  isSeasonalBusiness?: boolean;
  /**
  */
  businessCharacteristics?: BusinessCharacteristicsModel;

  static toModel(dto: DeepPartial<ESuranceMembershipBusinessDTO>): ESuranceMembershipBusinessModel;
  static toModel(dto: DeepPartial<ESuranceMembershipBusinessDTO> | undefined | null): ESuranceMembershipBusinessModel | undefined;
  static toModel(dto: DeepPartial<ESuranceMembershipBusinessDTO> | undefined | null): ESuranceMembershipBusinessModel | undefined {
    return dto ? new ESuranceMembershipBusinessModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ESuranceMembershipBusinessModel> | ESuranceMembershipBusinessModel): ESuranceMembershipBusinessDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      accessionDate: unwrapped.accessionDate,
      name: unwrapped.name,
      street: unwrapped.street,
      houseNumber: unwrapped.houseNumber,
      zipCode: unwrapped.zipCode,
      city: unwrapped.city,
      hasApprenticeTraining: unwrapped.hasApprenticeTraining,
      isSeasonalBusiness: unwrapped.isSeasonalBusiness,
      businessCharacteristics: unwrapped.businessCharacteristics,
    } as ESuranceMembershipBusinessDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ESuranceMembershipBusinessModel, can not map to ESuranceMembershipBusinessDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
