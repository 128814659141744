// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/basket-item-schema',
  type: 'object',
  required: ['itemNumber', 'itemPriceNumber', 'itemPriceType', 'quantity'],
  nullable: true,
  properties: {
    id: {
      type: 'string',
      format: 'uuid',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    itemNumber: {
      type: 'integer',
    },
    itemPriceNumber: {
      type: 'integer',
    },
    itemPriceType: {
      type: 'integer',
    },
    type: {
      type: 'string',
      nullable: true,
    },
    caption: {
      type: 'string',
      nullable: true,
    },
    quantity: {
      type: 'number',
    },
    currency: {
      type: 'string',
      nullable: true,
    },
    unitPrice: {
      type: 'number',
      nullable: true,
    },
    grossPrice: {
      type: 'number',
      nullable: true,
    },
    vatRate: {
      type: 'number',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
