// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import SubscriptionTypeResourceViewModel from '../../../../viewModel/resource/SubscriptionTypeResourceViewModel';
import ContactResourceViewModel from '../../../../viewModel/resource/ContactResourceViewModel';
import DeliveryContactResourceViewModel from '../../../../viewModel/resource/DeliveryContactResourceViewModel';
import SubscriptionArticleResourceViewModel from '../../../../viewModel/resource/SubscriptionArticleResourceViewModel';
import SubscriptionContractResourceViewModel from '../../../../viewModel/resource/SubscriptionContractResourceViewModel';
import SubscriptionContractResourceDTO from '../../dto/resource/SubscriptionContractResourceDTO';

export default abstract class SubscriptionContractResourceModel extends BaseModel<SubscriptionContractResourceDTO> {
  /**
  * @type {int32}
  */
  get subscriptionType() { return this.dto.subscriptionType; }
  set subscriptionType(value) { this.dto.subscriptionType = value; }
  /**
  * @type {date-time}
  */
  get subscriptionStartDate() { return this.dto.subscriptionStartDate; }
  set subscriptionStartDate(value) { this.dto.subscriptionStartDate = value; }
  /**
  */
  get billingContact() { return this.getObjectModel(() => this.dto.billingContact, ContactResourceViewModel)!; }
  set billingContact(value) { this.setObjectModel(() => this.dto.billingContact, value); }
  /**
  */
  get deliveryContact() { return this.getObjectModel(() => this.dto.deliveryContact, DeliveryContactResourceViewModel)!; }
  set deliveryContact(value) { this.setObjectModel(() => this.dto.deliveryContact, value); }
  /**
  */
  get subscriptionArticle() { return this.getObjectModel(() => this.dto.subscriptionArticle, SubscriptionArticleResourceViewModel)!; }
  set subscriptionArticle(value) { this.setObjectModel(() => this.dto.subscriptionArticle, value); }
  /**
  */
  get canBeCancelled() { return this.dto.canBeCancelled; }
  set canBeCancelled(value) { this.dto.canBeCancelled = value; }
  /**
  */
  get hasCombiSubscription() { return this.dto.hasCombiSubscription; }
  set hasCombiSubscription(value) { this.dto.hasCombiSubscription = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.subscriptiontype);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.subscriptionType = value;
    this.dto.subscriptionStartDate = qs.subscriptionstartdate;
    value = qs.canBeCancelled === 'true' ? true : qs.canbecancelled;
    value = qs.canBeCancelled === 'false' ? false : undefined;
    this.dto.canBeCancelled = value;
    value = qs.hasCombiSubscription === 'true' ? true : qs.hascombisubscription;
    value = qs.hasCombiSubscription === 'false' ? false : undefined;
    this.dto.hasCombiSubscription = value;
  }
}
