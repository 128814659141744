// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetShopArticleTaxHistoryByShopArticleTaxIdRequestModel from '../../model/get-shop-article-tax-history-by-shop-article-tax-id-request-model';
import GetShopArticleTaxHistoryByShopArticleTaxIdRequestDTO from '../dto/get-shop-article-tax-history-by-shop-article-tax-id-request-dto';
import dtoSchema from '../schemas/get-shop-article-tax-history-by-shop-article-tax-id-request-schema';

export default abstract class GetShopArticleTaxHistoryByShopArticleTaxIdRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetShopArticleTaxHistoryByShopArticleTaxIdRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;

  static toModel(dto: DeepPartial<GetShopArticleTaxHistoryByShopArticleTaxIdRequestDTO>): GetShopArticleTaxHistoryByShopArticleTaxIdRequestModel;
  static toModel(dto: DeepPartial<GetShopArticleTaxHistoryByShopArticleTaxIdRequestDTO> | undefined | null): GetShopArticleTaxHistoryByShopArticleTaxIdRequestModel | undefined;
  static toModel(dto: DeepPartial<GetShopArticleTaxHistoryByShopArticleTaxIdRequestDTO> | undefined | null): GetShopArticleTaxHistoryByShopArticleTaxIdRequestModel | undefined {
    return dto ? new GetShopArticleTaxHistoryByShopArticleTaxIdRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetShopArticleTaxHistoryByShopArticleTaxIdRequestModel> | GetShopArticleTaxHistoryByShopArticleTaxIdRequestModel): GetShopArticleTaxHistoryByShopArticleTaxIdRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
    } as GetShopArticleTaxHistoryByShopArticleTaxIdRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetShopArticleTaxHistoryByShopArticleTaxIdRequestModel, can not map to GetShopArticleTaxHistoryByShopArticleTaxIdRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
