// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import BasketItemResourceViewModel from '../../../../viewModel/resource/BasketItemResourceViewModel';
import BasketItemResourceDTO from '../../dto/resource/BasketItemResourceDTO';

export default abstract class BasketItemResourceModel extends BaseModel<BasketItemResourceDTO> {
  /**
  * @type {uuid}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int32}
  */
  get itemNumber() { return this.dto.itemNumber; }
  set itemNumber(value) { this.dto.itemNumber = value; }
  /**
  * @type {int32}
  */
  get itemPriceNumber() { return this.dto.itemPriceNumber; }
  set itemPriceNumber(value) { this.dto.itemPriceNumber = value; }
  /**
  * @type {int32}
  */
  get itemPriceType() { return this.dto.itemPriceType; }
  set itemPriceType(value) { this.dto.itemPriceType = value; }
  /**
  */
  get type() { return this.dto.type; }
  set type(value) { this.dto.type = value; }
  /**
  */
  get caption() { return this.dto.caption; }
  set caption(value) { this.dto.caption = value; }
  /**
  * @type {double}
  */
  get quantity() { return this.dto.quantity; }
  set quantity(value) { this.dto.quantity = value; }
  /**
  */
  get currency() { return this.dto.currency; }
  set currency(value) { this.dto.currency = value; }
  /**
  * @type {double}
  */
  get unitPrice() { return this.dto.unitPrice; }
  set unitPrice(value) { this.dto.unitPrice = value; }
  /**
  * @type {double}
  */
  get grossPrice() { return this.dto.grossPrice; }
  set grossPrice(value) { this.dto.grossPrice = value; }
  /**
  * @type {double}
  */
  get vatRate() { return this.dto.vatRate; }
  set vatRate(value) { this.dto.vatRate = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.id = qs.id;
    value = Number.parseFloat(qs.itemnumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.itemNumber = value;
    value = Number.parseFloat(qs.itempricenumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.itemPriceNumber = value;
    value = Number.parseFloat(qs.itempricetype);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.itemPriceType = value;
    this.dto.type = qs.type;
    this.dto.caption = qs.caption;
    value = Number.parseFloat(qs.quantity);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.quantity = value;
    this.dto.currency = qs.currency;
    value = Number.parseFloat(qs.unitprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.unitPrice = value;
    value = Number.parseFloat(qs.grossprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.grossPrice = value;
    value = Number.parseFloat(qs.vatrate);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.vatRate = value;
  }
}
