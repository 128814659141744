// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import MembershipOpenInvoiceListResourceViewModel from '../../../../viewModel/resource/MembershipOpenInvoiceListResourceViewModel';
import MembershipOpenInvoiceListResourceDTO from '../../dto/resource/MembershipOpenInvoiceListResourceDTO';

export default abstract class MembershipOpenInvoiceListResourceModel extends BaseModel<MembershipOpenInvoiceListResourceDTO> {
  /**
  * @type {uuid}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get subject() { return this.dto.subject; }
  set subject(value) { this.dto.subject = value; }
  /**
  */
  get businessDivision() { return this.dto.businessDivision; }
  set businessDivision(value) { this.dto.businessDivision = value; }
  /**
  * @type {date-time}
  */
  get invoiceDate() { return this.dto.invoiceDate; }
  set invoiceDate(value) { this.dto.invoiceDate = value; }
  /**
  * @type {date-time}
  */
  get dueDate() { return this.dto.dueDate; }
  set dueDate(value) { this.dto.dueDate = value; }
  /**
  */
  get currency() { return this.dto.currency; }
  set currency(value) { this.dto.currency = value; }
  /**
  * @type {double}
  */
  get amountInvoiced() { return this.dto.amountInvoiced; }
  set amountInvoiced(value) { this.dto.amountInvoiced = value; }
  /**
  * @type {double}
  */
  get amountOutstanding() { return this.dto.amountOutstanding; }
  set amountOutstanding(value) { this.dto.amountOutstanding = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.id = qs.id;
    this.dto.subject = qs.subject;
    this.dto.businessDivision = qs.businessdivision;
    this.dto.invoiceDate = qs.invoicedate;
    this.dto.dueDate = qs.duedate;
    this.dto.currency = qs.currency;
    value = Number.parseFloat(qs.amountinvoiced);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.amountInvoiced = value;
    value = Number.parseFloat(qs.amountoutstanding);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.amountOutstanding = value;
  }
}
