// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ContactSchema from './contact-schema';
import DeliveryAddressSchema from './delivery-address-schema';
import RequestSummarySchema from './request-summary-schema';

const schema = {
  $id: '/create-hotel-classification-request-schema',
  type: 'object',
  required: ['userId', 'businessName', 'languageIso2', 'classificationStars', 'accommodationTypeId'],
  properties: {
    userId: {
      type: 'integer',
    },
    businessName: {
      type: 'string',
      minLength: 1,
    },
    languageIso2: {
      type: 'string',
      minLength: 1,
    },
    businessContactPersonFirstName: {
      type: 'string',
      nullable: true,
    },
    businessContactPersonLastName: {
      type: 'string',
      nullable: true,
    },
    businessContactCeoSalutation: {
      enum: [
        0,
        1,
        2,
      ],
    },
    businessContactPersonSalutation: {
      enum: [
        0,
        1,
        2,
      ],
    },
    businessContactCeoLastName: {
      type: 'string',
      nullable: true,
    },
    businessContactCeoFirstName: {
      type: 'string',
      nullable: true,
    },
    isBusinessContactReadonly: {
      type: 'boolean',
    },
    businessContact: {
      ...ContactSchema,
    },
    isBillingContactReadonly: {
      type: 'boolean',
    },
    billingContact: {
      ...DeliveryAddressSchema,
    },
    classificationStars: {
      type: 'integer',
    },
    accommodationTypeId: {
      type: 'string',
      format: 'uuid',
    },
    accommodationTypeName: {
      type: 'string',
      nullable: true,
    },
    isSeasonal: {
      type: 'boolean',
    },
    countOfRooms: {
      type: 'integer',
      nullable: true,
    },
    webAddress: {
      type: 'string',
      nullable: true,
    },
    countOfBeds: {
      type: 'integer',
      nullable: true,
    },
    summary: {
      ...RequestSummarySchema,
    },
    acceptAgb: {
      type: 'boolean',
    },
  },
} as IJSONSchema;

export default schema;
