// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import OperatingModeLookupModel from '../../model/operating-mode-lookup-model';
import OperatingModeLookupDTO from '../dto/operating-mode-lookup-dto';
import dtoSchema from '../schemas/operating-mode-lookup-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class OperatingModeLookupModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<OperatingModeLookupDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.caption = data.caption ?? undefined;
      this.details = data.details ?? undefined;
      this.sort = data.sort;
      this.isGastronomy = data.isGastronomy;
      this.isAccommodation = data.isAccommodation;
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  caption?: string;
  /**
  */
  details?: string;
  /**
  * @type {int32}
  */
  sort?: number;
  /**
  */
  isGastronomy?: boolean;
  /**
  */
  isAccommodation?: boolean;

  static toModel(dto: DeepPartial<OperatingModeLookupDTO>): OperatingModeLookupModel;
  static toModel(dto: DeepPartial<OperatingModeLookupDTO> | undefined | null): OperatingModeLookupModel | undefined;
  static toModel(dto: DeepPartial<OperatingModeLookupDTO> | undefined | null): OperatingModeLookupModel | undefined {
    return dto ? new OperatingModeLookupModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<OperatingModeLookupModel> | OperatingModeLookupModel): OperatingModeLookupDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      caption: unwrapped.caption,
      details: unwrapped.details,
      sort: unwrapped.sort,
      isGastronomy: unwrapped.isGastronomy,
      isAccommodation: unwrapped.isAccommodation,
    } as OperatingModeLookupDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for OperatingModeLookupModel, can not map to OperatingModeLookupDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
