// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/re-execute-integration-call-request-schema',
  type: 'object',
  required: ['comment', 'payload', 'id'],
  properties: {
    comment: {
      type: 'string',
      minLength: 1,
    },
    payload: {
      type: 'string',
      minLength: 1,
    },
    id: {
      type: 'integer',
    },
  },
} as IJSONSchema;

export default schema;
