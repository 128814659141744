// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/app-log-schema',
  type: 'object',
  required: ['id'],
  nullable: true,
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    message: {
      type: 'string',
      nullable: true,
    },
    level: {
      type: 'string',
      nullable: true,
    },
    exception: {
      type: 'string',
      nullable: true,
    },
    timeStamp: {
      type: 'string',
      format: 'date-time',
    },
    correlationId: {
      type: 'string',
      nullable: true,
    },
    appTransactionId: {
      type: 'string',
      nullable: true,
    },
    appUserId: {
      type: 'integer',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
