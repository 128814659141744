// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import DownloadIntegrationCallReportRequestModel from '../../model/download-integration-call-report-request-model';
import DownloadIntegrationCallReportRequestDTO from '../dto/download-integration-call-report-request-dto';
import dtoSchema from '../schemas/download-integration-call-report-request-schema';

export default abstract class DownloadIntegrationCallReportRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<DownloadIntegrationCallReportRequestDTO>) {
    super();
    if (data) {
      this.dateFrom = data.dateFrom;
      this.dateTo = data.dateTo;
      this.customerName = data.customerName;
      this.taskId = data.taskId;
      this.integrationStatusId = data.integrationStatusId;
      this.searchCriteria = data.searchCriteria;
    }
  }
  /**
  * @type {date-time}
  */
  dateFrom?: string;
  /**
  * @type {date-time}
  */
  dateTo?: string;
  /**
  */
  customerName?: string;
  /**
  * @type {int64}
  */
  taskId?: number;
  /**
  * @type {int64}
  */
  integrationStatusId?: number;
  /**
  */
  searchCriteria?: string;

  static toModel(dto: DeepPartial<DownloadIntegrationCallReportRequestDTO>): DownloadIntegrationCallReportRequestModel;
  static toModel(dto: DeepPartial<DownloadIntegrationCallReportRequestDTO> | undefined | null): DownloadIntegrationCallReportRequestModel | undefined;
  static toModel(dto: DeepPartial<DownloadIntegrationCallReportRequestDTO> | undefined | null): DownloadIntegrationCallReportRequestModel | undefined {
    return dto ? new DownloadIntegrationCallReportRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<DownloadIntegrationCallReportRequestModel> | DownloadIntegrationCallReportRequestModel): DownloadIntegrationCallReportRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      dateFrom: unwrapped.dateFrom,
      dateTo: unwrapped.dateTo,
      customerName: unwrapped.customerName,
      taskId: unwrapped.taskId,
      integrationStatusId: unwrapped.integrationStatusId,
      searchCriteria: unwrapped.searchCriteria,
    } as DownloadIntegrationCallReportRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for DownloadIntegrationCallReportRequestModel, can not map to DownloadIntegrationCallReportRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
