// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import TenantModel from '../../model/tenant-model';
import TenantDTO from '../dto/tenant-dto';
import dtoSchema from '../schemas/tenant-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class TenantModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<TenantDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.name = data.name ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  creatorId?: number;
  /**
  */
  creatorName?: string;
  /**
  * @type {date-time}
  */
  created?: string;
  /**
  * @type {int64}
  */
  modifierId?: number;
  /**
  */
  modifierName?: string;
  /**
  * @type {date-time}
  */
  modified?: string;
  /**
  */
  name?: string;

  static toModel(dto: DeepPartial<TenantDTO>): TenantModel;
  static toModel(dto: DeepPartial<TenantDTO> | undefined | null): TenantModel | undefined;
  static toModel(dto: DeepPartial<TenantDTO> | undefined | null): TenantModel | undefined {
    return dto ? new TenantModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<TenantModel> | TenantModel): TenantDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      creatorId: unwrapped.creatorId,
      creatorName: unwrapped.creatorName,
      created: unwrapped.created,
      modifierId: unwrapped.modifierId,
      modifierName: unwrapped.modifierName,
      modified: unwrapped.modified,
      name: unwrapped.name,
    } as TenantDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for TenantModel, can not map to TenantDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
