// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ShopPaymentMethodResourceViewModel from '../../../../viewModel/resource/ShopPaymentMethodResourceViewModel';
import PaymentTypeViewModel from '../../../../viewModel/resource/PaymentTypeViewModel';
import ShopPaymentStatusResourceViewModel from '../../../../viewModel/resource/ShopPaymentStatusResourceViewModel';
import ShopPaymentResourceViewModel from '../../../../viewModel/resource/ShopPaymentResourceViewModel';
import ShopPaymentResourceDTO from '../../dto/resource/ShopPaymentResourceDTO';

export default abstract class ShopPaymentResourceModel extends BaseModel<ShopPaymentResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get shopOrderId() { return this.dto.shopOrderId; }
  set shopOrderId(value) { this.dto.shopOrderId = value; }
  /**
  * @type {date-time}
  */
  get paymentDate() { return this.dto.paymentDate; }
  set paymentDate(value) { this.dto.paymentDate = value; }
  /**
  * @type {int64}
  */
  get paymentMethod() { return this.dto.paymentMethod; }
  set paymentMethod(value) { this.dto.paymentMethod = value; }
  /**
  * @type {int32}
  */
  get onlinePaymentMethod() { return this.dto.onlinePaymentMethod; }
  set onlinePaymentMethod(value) { this.dto.onlinePaymentMethod = value; }
  /**
  */
  get currency() { return this.dto.currency; }
  set currency(value) { this.dto.currency = value; }
  /**
  * @type {double}
  */
  get amount() { return this.dto.amount; }
  set amount(value) { this.dto.amount = value; }
  /**
  */
  get transactionId() { return this.dto.transactionId; }
  set transactionId(value) { this.dto.transactionId = value; }
  /**
  * @type {int32}
  */
  get paymentStatus() { return this.dto.paymentStatus; }
  set paymentStatus(value) { this.dto.paymentStatus = value; }
  /**
  */
  get paymentMessage() { return this.dto.paymentMessage; }
  set paymentMessage(value) { this.dto.paymentMessage = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.shoporderid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.shopOrderId = value;
    this.dto.paymentDate = qs.paymentdate;
    value = Number.parseFloat(qs.paymentmethod);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.paymentMethod = value;
    value = Number.parseFloat(qs.onlinepaymentmethod);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.onlinePaymentMethod = value;
    this.dto.currency = qs.currency;
    value = Number.parseFloat(qs.amount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.amount = value;
    this.dto.transactionId = qs.transactionid;
    value = Number.parseFloat(qs.paymentstatus);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.paymentStatus = value;
    this.dto.paymentMessage = qs.paymentmessage;
  }
}
