<template>
  <p-child nested namespace="gs-wizard" class="gs-wizard-step">
    <p-container class="pt-1">
      <slot></slot>
    </p-container>
    <div class="mt-4">
      <p-divider></p-divider>
      <p-alert :value="(isLoading && processingLabel)" type="info">
        {{ processingLabel }}
      </p-alert>
      <p-container fluid>
        <p-row no-gutters>
          <p-col shrink class="pr-4">
            <slot name="back-action">
              <p-button :disabled="isBackDisabled || isLoading" :loading="isLoading" class="mr-3" secondary @click="goBack">
                <p-icon left>
                  chevron_left
                </p-icon>{{ $t('app.back') }}
              </p-button>
            </slot>
            <slot name="step-actions" :is-loading="isLoading" :proceed-disabled="proceedDisabled">
              <p-button
                :loading="isLoading"
                :disabled="proceedDisabled || isLoading"
                @click="proceedOrComplete"
              >
                {{ proceedLabelComputed }}
                <p-icon right>
                  {{ isLastStep ? 'send' : 'chevron_right' }}
                </p-icon>
              </p-button>
            </slot>
          </p-col>
          <p-col grow>
            <p-request-alert v-model="errorResponse" :dismissable="false"></p-request-alert>
          </p-col>
        </p-row>
      </p-container>
    </div>
  </p-child>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { StepData } from './gs-wizard.vue';

  export default Vue.extend({
    props: {
      value: { type: Object, default: undefined },
      loading: { type: Boolean, default: false },
      proceedDisabled: { type: Boolean, default: false },
      proceedLabel: { type: String, default: undefined },
      processingLabel: { type: String, default: undefined },
      customProceedFunction: { type: Function, default: undefined },
      customBackFunction: { type: Function, default: undefined },
      backDisabled: { type: Boolean, default: undefined },
    },
    data: () => ({
      stepData: {} as Partial<StepData>,
      // eslint-disable-next-line no-unused-vars
      proceedFunction: undefined as undefined | (() => void),
      validateFunction: undefined as undefined | (()=>boolean),
      backFunction: undefined as undefined | (() => void),
      // eslint-disable-next-line no-unused-vars
      completeFunction: undefined as undefined | (() => void),
      isLastStep: false,
      isFirstStep: false,
      errorResponse: undefined,
      internalLoading: false,
    }),
    computed: {
      isLoading: {
        get(): boolean {
          return this.internalLoading || this.loading;
        },
        set(value: boolean) {
          this.internalLoading = value;
        },
      },
      isBackDisabled(): boolean {
        if (this.backDisabled !== undefined) {
          return this.backDisabled;
        }

        if (this.isLastStep || this.isFirstStep) {
          return true;
        }

        return false;
      },
      proceedLabelComputed(): string {
        const defaultLabel = this.isLastStep ? this.$t('app.saveAndComplete') : this.$t('app.saveAndContinue');
        return this.proceedLabel ?? defaultLabel;
      },
    },
    methods: {
      goBack() {
        if (typeof this.customBackFunction === 'function') {
          this.customBackFunction();
        } else if (this.backFunction) {
          this.backFunction();
        }
      },
      async proceedOrComplete() {
        // #14887: Timeout wird benötigt, damit Daten aus p-select-multiple richtig gesetzt werden,
        // wenn mit offener Auswahl der Speichern-Button gedrückt wird
        window.setTimeout(async () => {
          if (this.isLastStep) {
            await this.complete();
          } else {
            await this.proceed();
          }
        }, 1);
      },
      async proceed() {
        if (typeof this.customProceedFunction === 'function') {
          this.isLoading = true;
          try {
            const result = this.customProceedFunction();
            if (this._.isPromise(result)) {
              await result;
            }
          } catch (error: any) {
            this.$log.error(error);
            this.errorResponse = error;
          }
          this.isLoading = false;
        } else if (typeof this.proceedFunction === 'function') {
          this.proceedFunction();
        }
      },
      async complete() {
        if (typeof this.customProceedFunction === 'function') {
          this.isLoading = true;
          try {
            const result = this.customProceedFunction();
            if (this._.isPromise(result)) {
              await result;
            }
          } catch (error: any) {
            this.$log.error(error);
            this.errorResponse = error;
          }
          this.isLoading = false;
        } else if (typeof this.completeFunction === 'function') {
          this.completeFunction();
        }
      },
    },
  });
</script>

<style lang="scss" scoped>
  .gs-wizard-step{
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .p-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
</style>
