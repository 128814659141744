// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetShopPaymentPagedListRequestViewModel from '../../viewModel/request/ShopPayments/GetShopPaymentPagedListRequestViewModel';
import ShopPaymentResourcePagingResultViewModel from '../../viewModel/resource/ShopPaymentResourcePagingResultViewModel';
import ShopPaymentResourceViewModel from '../../viewModel/resource/ShopPaymentResourceViewModel';
import ShopOrderResourceViewModel from '../../viewModel/resource/ShopOrderResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getShopPaymentPagedList(request: GetShopPaymentPagedListRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-payment-paged-list'] ?? 'ShopPayments';
    const response = await service.get<any>(endpointPath, {
      query: {
        amountFrom: requestDTO.amountFrom,
        amountTo: requestDTO.amountTo,
        dateFrom: requestDTO.dateFrom,
        dateTo: requestDTO.dateTo,
        paymentStatus: requestDTO.paymentStatus,
        paymentMethod: requestDTO.paymentMethod,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new ShopPaymentResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async createShopPayment(model: ShopPaymentResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-shop-payment'] ?? 'ShopPayments';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getShopPayment(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-payment'] ?? 'ShopPayments/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new ShopOrderResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateShopPayment(id: number, model: ShopPaymentResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-shop-payment'] ?? 'ShopPayments/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async deleteShopPayment(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-shop-payment'] ?? 'ShopPayments/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
});
