// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetTransactionByIdRequestModel from '../../model/get-transaction-by-id-request-model';
import CreateTransactionRequestModel from '../../model/create-transaction-request-model';
import CreateRechargeTransactionRequestModel from '../../model/create-recharge-transaction-request-model';
import UpdateTransactionRequestModel from '../../model/update-transaction-request-model';
import GetWidgetJavaScriptUrlRequestModel from '../../model/get-widget-java-script-url-request-model';
import ProcessTransactionRequestModel from '../../model/process-transaction-request-model';
import OnlinePaymentTransactionModel from '../../model/online-payment-transaction-model';
import OnlinePaymentTransactionUpdatedModel from '../../model/online-payment-transaction-updated-model';
import OnlinePaymentTransactionProcessingModel from '../../model/online-payment-transaction-processing-model';

export default (service: Servicelayer) => ({
  /**  */
  async getTransactionById(request: GetTransactionByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetTransactionByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-transaction-by-id'] ?? 'core/OnlinePaymentTransaction/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(OnlinePaymentTransactionModel.toModel(dto));
  },
  /**  */
  async createTransaction(request: CreateTransactionRequestModel, config?: RequestConfig) {
    const requestDTO = CreateTransactionRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-transaction'] ?? 'core/OnlinePaymentTransaction/Create';
    const response = await service.post<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        merchantReference: requestDTO.merchantReference,
        currency: requestDTO.currency,
        totalPrice: requestDTO.totalPrice,
        language: requestDTO.language,
        billingAddress: requestDTO.billingAddress,
        shippingAddress: requestDTO.shippingAddress,
        orderItems: requestDTO.orderItems,
        state: requestDTO.state,
        isRecurring: requestDTO.isRecurring,
        recurringOnlinePaymentTokenId: requestDTO.recurringOnlinePaymentTokenId,
        paymentConnectorId: requestDTO.paymentConnectorId,
        createdOn: requestDTO.createdOn,
        completedOn: requestDTO.completedOn,
        failedOn: requestDTO.failedOn,
        version: requestDTO.version,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as string | undefined);
  },
  /**  */
  async createRechargeTransaction(request: CreateRechargeTransactionRequestModel, config?: RequestConfig) {
    const requestDTO = CreateRechargeTransactionRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-recharge-transaction'] ?? 'core/OnlinePaymentTransaction/CreateRecharge';
    const response = await service.post<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        merchantReference: requestDTO.merchantReference,
        currency: requestDTO.currency,
        totalPrice: requestDTO.totalPrice,
        language: requestDTO.language,
        billingAddress: requestDTO.billingAddress,
        shippingAddress: requestDTO.shippingAddress,
        orderItems: requestDTO.orderItems,
        state: requestDTO.state,
        isRecurring: requestDTO.isRecurring,
        recurringOnlinePaymentTokenId: requestDTO.recurringOnlinePaymentTokenId,
        paymentConnectorId: requestDTO.paymentConnectorId,
        createdOn: requestDTO.createdOn,
        completedOn: requestDTO.completedOn,
        failedOn: requestDTO.failedOn,
        version: requestDTO.version,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as string | undefined);
  },
  /**  */
  async updateTransaction(request: UpdateTransactionRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateTransactionRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-transaction'] ?? 'core/OnlinePaymentTransaction/Update';
    const response = await service.post<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        merchantReference: requestDTO.merchantReference,
        currency: requestDTO.currency,
        totalPrice: requestDTO.totalPrice,
        language: requestDTO.language,
        billingAddress: requestDTO.billingAddress,
        shippingAddress: requestDTO.shippingAddress,
        orderItems: requestDTO.orderItems,
        state: requestDTO.state,
        isRecurring: requestDTO.isRecurring,
        recurringOnlinePaymentTokenId: requestDTO.recurringOnlinePaymentTokenId,
        paymentConnectorId: requestDTO.paymentConnectorId,
        createdOn: requestDTO.createdOn,
        completedOn: requestDTO.completedOn,
        failedOn: requestDTO.failedOn,
        version: requestDTO.version,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(OnlinePaymentTransactionUpdatedModel.toModel(dto));
  },
  /**  */
  async getWidgetJavaScriptUrl(request: GetWidgetJavaScriptUrlRequestModel, config?: RequestConfig) {
    const requestDTO = GetWidgetJavaScriptUrlRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-java-script-url'] ?? 'core/OnlinePaymentTransaction/{id}/GetWidgetJavaScriptUrl';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.post<any>(endpointPath, {
      body: {
        successUrl: requestDTO.successUrl,
        failedUrl: requestDTO.failedUrl,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as string | undefined);
  },
  /**  */
  async processTransaction(request: ProcessTransactionRequestModel, config?: RequestConfig) {
    const requestDTO = ProcessTransactionRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-process-transaction'] ?? 'core/OnlinePaymentTransaction/{id}/Process';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.post<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(OnlinePaymentTransactionProcessingModel.toModel(dto));
  },
});
