// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import UploadDocumentRequestDTO from '../../dto/request/UploadDocumentRequestDTO';

export default abstract class UploadDocumentRequestModel extends BaseModel<UploadDocumentRequestDTO> {
  // #region    ──FormRequest──────────────────────────╮
  /**
  * @type {binary}
  */
  get uploadFile() { return this.dto.uploadFile; }
  set uploadFile(value) { this.dto.uploadFile = value; }
  // #endregion ───────────────────────────────────────╯
  // #region    ──HeaderRequest──────────────────────────╮
  // #endregion ─────────────────────────────────────────╯
  // #region    ──QueryRequest──────────────────────────╮
  // #endregion ────────────────────────────────────────╯

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
  }
}
