<template>
  <p-select
    :value="value"
    class="gs-article-tax-lookup"
    v-bind="$props"
    :label="label"
    :clearable="clearable"
    :errors="errorMessage"
    :loading="isLoading"
    :items="items"
    :disabled="disabled"
    @input="$emit('input', $event)"
  ></p-select>
</template>

<script lang="ts">
  import Int64LookupResourceViewModel from '../../services/v1/viewModel/resource/Int64LookupResourceViewModel';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'GsArticleTaxtLookup',
    props: {
      value: { type: undefined, default: undefined },
      label: { type: String, default: undefined },
      clearable: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
    },
    data: () => ({
      items: [] as Int64LookupResourceViewModel[],
      errorMessage: undefined as undefined | string,
      isLoading: false,
    }),
    mounted() {
      this.populateData();
    },
    methods: {
      async populateData() {
        this.isLoading = true;
        try {
          const response = await this.$service.api.shopArticleTaxes.getShopArticleTaxLookup();
          this.items = response.items;
        } catch (error: any) {
          this.errorMessage = error?.message ?? error;
        }
        this.isLoading = false;
      },
    },
  });
</script>
