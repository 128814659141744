// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetIssuuDocumentsRequestViewModel from '../../viewModel/request/IssuuDocuments/GetIssuuDocumentsRequestViewModel';
import GetIssuuEmbedDocumentRequestViewModel from '../../viewModel/request/IssuuDocuments/GetIssuuEmbedDocumentRequestViewModel';
import GetIssuuDocumentCoverRequestViewModel from '../../viewModel/request/IssuuDocuments/GetIssuuDocumentCoverRequestViewModel';
import IssuuLookupResourceListResultViewModel from '../../viewModel/resource/IssuuLookupResourceListResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getIssuuDocuments(request: GetIssuuDocumentsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-issuu-documents'] ?? 'IssuuDocuments/GetIssuuDocuments';
    const response = await service.get<any>(endpointPath, {
      query: {
        pageSize: requestDTO.pageSize,
        startIndex: requestDTO.startIndex,
      },
      ...config,
    });
    const dto = response.data.result;
    return new IssuuLookupResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getIssuuEmbedDocument(id: string, request: GetIssuuEmbedDocumentRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-issuu-embed-document'] ?? 'IssuuDocuments/GetIssuuDocument/{id}/embed';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as string | undefined;
  },
  /**  */
  async getIssuuDocumentCover(id: string, request: GetIssuuDocumentCoverRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-issuu-document-cover'] ?? 'IssuuDocuments/GetIssuuDocument/{id}/cover';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as string | undefined;
  },
});
