// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import LookupAttributesRequestModel from '../../model/lookup-attributes-request-model';
import OperatingModeRequestModel from '../../model/operating-mode-request-model';
import OperationAreaRequestModel from '../../model/operation-area-request-model';
import GuidLookupPagingResultModel from '../../model/guid-lookup-paging-result-model';
import OperatingModeLookupPagingResultModel from '../../model/operating-mode-lookup-paging-result-model';
import AttributeLookupPagingResultModel from '../../model/attribute-lookup-paging-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async lookupAttributes(request: LookupAttributesRequestModel, config?: RequestConfig) {
    const requestDTO = LookupAttributesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-attributes'] ?? 'Attributes';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(GuidLookupPagingResultModel.toModel(dto));
  },
  /**  */
  async operatingMode(request: OperatingModeRequestModel, config?: RequestConfig) {
    const requestDTO = OperatingModeRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-operating-mode'] ?? 'Attributes/OperatingMode';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(OperatingModeLookupPagingResultModel.toModel(dto));
  },
  /**  */
  async operationArea(request: OperationAreaRequestModel, config?: RequestConfig) {
    const requestDTO = OperationAreaRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-operation-area'] ?? 'Attributes/OperationArea';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(AttributeLookupPagingResultModel.toModel(dto));
  },
});
