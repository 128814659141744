// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ContactSchema from './contact-schema';
import CreateDynamicFormFieldRequestSchema from './create-dynamic-form-field-request-schema';

const schema = {
  $id: '/create-dynamic-form-request-schema',
  type: 'object',
  required: [],
  properties: {
    dynamicFormDefinitionId: {
      type: 'string',
      format: 'uuid',
    },
    languageIso: {
      type: 'string',
      nullable: true,
    },
    contactId: {
      type: 'string',
      format: 'uuid',
      nullable: true,
    },
    contact: {
      ...ContactSchema,
    },
    formFields: {
      type: 'array',
      nullable: true,
      items: { ...CreateDynamicFormFieldRequestSchema },
    },
  },
} as IJSONSchema;

export default schema;
