// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CreateMembershipRequestModel from '../../model/create-membership-request-model';
import CreateMembershipRequestDTO from '../dto/create-membership-request-dto';
import dtoSchema from '../schemas/create-membership-request-schema';
import ContactModel from '../../model/contact-model';
import MemberBusinessAddressModel from '../../model/member-business-address-model';
import CorrespondenceAddressModel from '../../model/correspondence-address-model';
import MembershipBusinessModel from '../../model/membership-business-model';
import BasketModel from '../../model/basket-model';
import RequestSummaryModel from '../../model/request-summary-model';

export default abstract class CreateMembershipRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CreateMembershipRequestDTO>) {
    super();
    if (data) {
      this.userId = data.userId;
      this.businessUnitId = data.businessUnitId;
      this.membershipTemplateId = data.membershipTemplateId;
      this.payrollCategoryId = data.payrollCategoryId;
      this.isMembershipReEntry = data.isMembershipReEntry;
      this.entryDate = data.entryDate ?? undefined;
      this.socialInsuranceId = data.socialInsuranceId ?? undefined;
      this.hasOperatingLicence = data.hasOperatingLicence ?? undefined;
      this.operatingLicenceOwner = data.operatingLicenceOwner ?? undefined;
      this.dateOperatingLicenceIssued = data.dateOperatingLicenceIssued ?? undefined;
      this.hasCertificateOfProficiency = data.hasCertificateOfProficiency ?? undefined;
      this.dateCertificateOfProficiencyIssued = data.dateCertificateOfProficiencyIssued ?? undefined;
      this.certificateOfProficiencyCantonId = data.certificateOfProficiencyCantonId ?? undefined;
      this.certificateOfProficiencyCourse = data.certificateOfProficiencyCourse ?? undefined;
      this.isMemberContactReadonly = data.isMemberContactReadonly;
      this.memberContact = data.memberContact ? ContactModel.toModel(data.memberContact) : undefined;
      this.isMemberBusinessContactReadonly = data.isMemberBusinessContactReadonly;
      this.memberBusinessContact = data.memberBusinessContact ? MemberBusinessAddressModel.toModel(data.memberBusinessContact) : undefined;
      this.isMemberCorrespondeceContactReadonly = data.isMemberCorrespondeceContactReadonly;
      this.memberCorrespondenceContact = data.memberCorrespondenceContact ? CorrespondenceAddressModel.toModel(data.memberCorrespondenceContact) : undefined;
      this.isMembershipBusinessReadonly = data.isMembershipBusinessReadonly;
      this.membershipBusiness = data.membershipBusiness ? MembershipBusinessModel.toModel(data.membershipBusiness) : undefined;
      this.orderBasket = data.orderBasket ? BasketModel.toModel(data.orderBasket) : undefined;
      this.summary = data.summary ? RequestSummaryModel.toModel(data.summary) : undefined;
      this.acceptIsRelatedToGastro = data.acceptIsRelatedToGastro;
      this.acceptGsoc = data.acceptGsoc;
      this.acceptAgb = data.acceptAgb;
      this.acceptFilledOutTruthful = data.acceptFilledOutTruthful;
      this.acceptGsStatutes = data.acceptGsStatutes;
      this.newsletter = data.newsletter;
      this.acceptGdpr = data.acceptGdpr;
      this.acceptContactForClassification = data.acceptContactForClassification;
    }
  }
  /**
  * @type {int64}
  */
  userId?: number;
  /**
  * @type {uuid}
  */
  businessUnitId?: string;
  /**
  * @type {uuid}
  */
  membershipTemplateId?: string;
  /**
  * @type {int32}
  */
  payrollCategoryId?: number;
  /**
  */
  isMembershipReEntry?: boolean;
  /**
  * @type {date-time}
  */
  entryDate?: string;
  /**
  * @type {uuid}
  */
  socialInsuranceId?: string;
  /**
  */
  hasOperatingLicence?: boolean;
  /**
  */
  operatingLicenceOwner?: string;
  /**
  * @type {date-time}
  */
  dateOperatingLicenceIssued?: string;
  /**
  */
  hasCertificateOfProficiency?: boolean;
  /**
  * @type {date-time}
  */
  dateCertificateOfProficiencyIssued?: string;
  /**
  * @type {int64}
  */
  certificateOfProficiencyCantonId?: number;
  /**
  */
  certificateOfProficiencyCourse?: string;
  /**
  */
  isMemberContactReadonly?: boolean;
  /**
  */
  memberContact?: ContactModel;
  /**
  */
  isMemberBusinessContactReadonly?: boolean;
  /**
  */
  memberBusinessContact?: MemberBusinessAddressModel;
  /**
  */
  isMemberCorrespondeceContactReadonly?: boolean;
  /**
  */
  memberCorrespondenceContact?: CorrespondenceAddressModel;
  /**
  */
  isMembershipBusinessReadonly?: boolean;
  /**
  */
  membershipBusiness?: MembershipBusinessModel;
  /**
  */
  orderBasket?: BasketModel;
  /**
  */
  summary?: RequestSummaryModel;
  /**
  */
  acceptIsRelatedToGastro?: boolean;
  /**
  */
  acceptGsoc?: boolean;
  /**
  */
  acceptAgb?: boolean;
  /**
  */
  acceptFilledOutTruthful?: boolean;
  /**
  */
  acceptGsStatutes?: boolean;
  /**
  */
  newsletter?: boolean;
  /**
  */
  acceptGdpr?: boolean;
  /**
  */
  acceptContactForClassification?: boolean;

  static toModel(dto: DeepPartial<CreateMembershipRequestDTO>): CreateMembershipRequestModel;
  static toModel(dto: DeepPartial<CreateMembershipRequestDTO> | undefined | null): CreateMembershipRequestModel | undefined;
  static toModel(dto: DeepPartial<CreateMembershipRequestDTO> | undefined | null): CreateMembershipRequestModel | undefined {
    return dto ? new CreateMembershipRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CreateMembershipRequestModel> | CreateMembershipRequestModel): CreateMembershipRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      userId: unwrapped.userId,
      businessUnitId: unwrapped.businessUnitId,
      membershipTemplateId: unwrapped.membershipTemplateId,
      payrollCategoryId: unwrapped.payrollCategoryId,
      isMembershipReEntry: unwrapped.isMembershipReEntry,
      entryDate: unwrapped.entryDate,
      socialInsuranceId: unwrapped.socialInsuranceId,
      hasOperatingLicence: unwrapped.hasOperatingLicence,
      operatingLicenceOwner: unwrapped.operatingLicenceOwner,
      dateOperatingLicenceIssued: unwrapped.dateOperatingLicenceIssued,
      hasCertificateOfProficiency: unwrapped.hasCertificateOfProficiency,
      dateCertificateOfProficiencyIssued: unwrapped.dateCertificateOfProficiencyIssued,
      certificateOfProficiencyCantonId: unwrapped.certificateOfProficiencyCantonId,
      certificateOfProficiencyCourse: unwrapped.certificateOfProficiencyCourse,
      isMemberContactReadonly: unwrapped.isMemberContactReadonly,
      memberContact: unwrapped.memberContact,
      isMemberBusinessContactReadonly: unwrapped.isMemberBusinessContactReadonly,
      memberBusinessContact: unwrapped.memberBusinessContact,
      isMemberCorrespondeceContactReadonly: unwrapped.isMemberCorrespondeceContactReadonly,
      memberCorrespondenceContact: unwrapped.memberCorrespondenceContact,
      isMembershipBusinessReadonly: unwrapped.isMembershipBusinessReadonly,
      membershipBusiness: unwrapped.membershipBusiness,
      orderBasket: unwrapped.orderBasket,
      summary: unwrapped.summary,
      acceptIsRelatedToGastro: unwrapped.acceptIsRelatedToGastro,
      acceptGsoc: unwrapped.acceptGsoc,
      acceptAgb: unwrapped.acceptAgb,
      acceptFilledOutTruthful: unwrapped.acceptFilledOutTruthful,
      acceptGsStatutes: unwrapped.acceptGsStatutes,
      newsletter: unwrapped.newsletter,
      acceptGdpr: unwrapped.acceptGdpr,
      acceptContactForClassification: unwrapped.acceptContactForClassification,
    } as CreateMembershipRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CreateMembershipRequestModel, can not map to CreateMembershipRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
