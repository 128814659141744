// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetAppSystemsRequestModel from '../../model/get-app-systems-request-model';
import CreateAppSystemRequestModel from '../../model/create-app-system-request-model';
import LookupAppSystemsRequestModel from '../../model/lookup-app-systems-request-model';
import GetAppSystemByIdRequestModel from '../../model/get-app-system-by-id-request-model';
import UpdateAppSystemRequestModel from '../../model/update-app-system-request-model';
import DeleteAppSystemRequestModel from '../../model/delete-app-system-request-model';
import GetCustomFieldsRequestModel from '../../model/get-custom-fields-request-model';
import AppSystemPagingResultModel from '../../model/app-system-paging-result-model';
import Int64LookupPagingResultModel from '../../model/int64-lookup-paging-result-model';
import AppSystemModel from '../../model/app-system-model';
import CampaignCustomFieldListResultModel from '../../model/campaign-custom-field-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getAppSystems(request: GetAppSystemsRequestModel, config?: RequestConfig) {
    const requestDTO = GetAppSystemsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-app-systems'] ?? 'core/integration/AppSystems';
    const response = await service.get<any>(endpointPath, {
      query: {
        isActive: requestDTO.isActive,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(AppSystemPagingResultModel.toModel(dto));
  },
  /**  */
  async createAppSystem(request: CreateAppSystemRequestModel, config?: RequestConfig) {
    const requestDTO = CreateAppSystemRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-app-system'] ?? 'core/integration/AppSystems';
    const response = await service.post<any>(endpointPath, {
      body: {
        name: requestDTO.name,
        tenantKey: requestDTO.tenantKey,
        isActive: requestDTO.isActive,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async lookupAppSystems(request: LookupAppSystemsRequestModel, config?: RequestConfig) {
    const requestDTO = LookupAppSystemsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-app-systems'] ?? 'core/integration/AppSystems/Lookup';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupPagingResultModel.toModel(dto));
  },
  /**  */
  async getAppSystemById(request: GetAppSystemByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetAppSystemByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-app-system-by-id'] ?? 'core/integration/AppSystems/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(AppSystemModel.toModel(dto));
  },
  /**  */
  async updateAppSystem(request: UpdateAppSystemRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateAppSystemRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-app-system'] ?? 'core/integration/AppSystems/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        name: requestDTO.name,
        tenantKey: requestDTO.tenantKey,
        isActive: requestDTO.isActive,
        caption: requestDTO.caption,
      },
      ...config,
    });
  },
  /**  */
  async deleteAppSystem(request: DeleteAppSystemRequestModel, config?: RequestConfig) {
    const requestDTO = DeleteAppSystemRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-app-system'] ?? 'core/integration/AppSystems/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
  },
  /**  */
  async getCustomFields(request: GetCustomFieldsRequestModel, config?: RequestConfig) {
    const requestDTO = GetCustomFieldsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-custom-fields'] ?? 'core/integration/AppSystems/{id}/CustomFields';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CampaignCustomFieldListResultModel.toModel(dto));
  },
});
