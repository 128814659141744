// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetDocumentByExternalIdRequestModel from '../../model/get-document-by-external-id-request-model';
import UploadDocumentRequestModel from '../../model/upload-document-request-model';
import DownloadDocumentRequestModel from '../../model/download-document-request-model';
import DocumentModel from '../../model/document-model';

export default (service: Servicelayer) => ({
  /**  */
  async getDocumentByExternalId(request: GetDocumentByExternalIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetDocumentByExternalIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-document-by-external-id'] ?? 'core/Documents/{externalId}';
    endpointPath = endpointPath.replace(/\{externalId\}/g, `${requestDTO.externalId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(DocumentModel.toModel(dto));
  },
  /**  */
  async uploadDocument(request: UploadDocumentRequestModel, config?: RequestConfig) {
    const requestDTO = UploadDocumentRequestModel.toDTO(request);
    const formData = new FormData();
    formData.append('uploadFile', requestDTO.uploadFile as Blob);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-upload-document'] ?? 'core/Documents/Upload';
    const response = await service.post<any>(endpointPath, {
      body: formData,
      headers: {
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as string | undefined);
  },
  /**  */
  async downloadDocument(request: DownloadDocumentRequestModel, config?: RequestConfig) {
    const requestDTO = DownloadDocumentRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-document'] ?? 'core/Documents/{externalId}/Download';
    endpointPath = endpointPath.replace(/\{externalId\}/g, `${requestDTO.externalId}`);
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(new Blob([result], { type: 'application/octet-stream' }));
  },
});
