// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CreateESuranceMembershipRequestModel from '../../model/create-e-surance-membership-request-model';
import CreateESuranceMembershipRequestDTO from '../dto/create-e-surance-membership-request-dto';
import dtoSchema from '../schemas/create-e-surance-membership-request-schema';
import ESuranceMembershipModel from '../../model/e-surance-membership-model';
import ESuranceMembershipContactModel from '../../model/e-surance-membership-contact-model';
import ESuranceMembershipBusinessModel from '../../model/e-surance-membership-business-model';
import ESuranceInvoiceAddressModel from '../../model/e-surance-invoice-address-model';

export default abstract class CreateESuranceMembershipRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CreateESuranceMembershipRequestDTO>) {
    super();
    if (data) {
      this.membership = ESuranceMembershipModel.toModel(data.membership);
      this.membershipContact = ESuranceMembershipContactModel.toModel(data.membershipContact);
      this.membershipBusiness = ESuranceMembershipBusinessModel.toModel(data.membershipBusiness);
      this.invoiceAddress = ESuranceInvoiceAddressModel.toModel(data.invoiceAddress);
    }
  }
  /**
  */
  membership?: ESuranceMembershipModel;
  /**
  */
  membershipContact?: ESuranceMembershipContactModel;
  /**
  */
  membershipBusiness?: ESuranceMembershipBusinessModel;
  /**
  */
  invoiceAddress?: ESuranceInvoiceAddressModel;

  static toModel(dto: DeepPartial<CreateESuranceMembershipRequestDTO>): CreateESuranceMembershipRequestModel;
  static toModel(dto: DeepPartial<CreateESuranceMembershipRequestDTO> | undefined | null): CreateESuranceMembershipRequestModel | undefined;
  static toModel(dto: DeepPartial<CreateESuranceMembershipRequestDTO> | undefined | null): CreateESuranceMembershipRequestModel | undefined {
    return dto ? new CreateESuranceMembershipRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CreateESuranceMembershipRequestModel> | CreateESuranceMembershipRequestModel): CreateESuranceMembershipRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      membership: unwrapped.membership,
      membershipContact: unwrapped.membershipContact,
      membershipBusiness: unwrapped.membershipBusiness,
      invoiceAddress: unwrapped.invoiceAddress,
    } as CreateESuranceMembershipRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CreateESuranceMembershipRequestModel, can not map to CreateESuranceMembershipRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
