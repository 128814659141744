// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GenerateApiKeyRequestModel from '../../model/generate-api-key-request-model';
import GenerateApiKeyRequestDTO from '../dto/generate-api-key-request-dto';
import dtoSchema from '../schemas/generate-api-key-request-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import UserApiKeySettingsModel from '../../model/user-api-key-settings-model';

export default abstract class GenerateApiKeyRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GenerateApiKeyRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.userId = data.userId;
      this.name = data.name;
      this.apiKeyOptions = data.apiKeyOptions ? UserApiKeySettingsModel.toModel(data.apiKeyOptions) : undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  creatorId?: number;
  /**
  */
  creatorName?: string;
  /**
  * @type {date-time}
  */
  created?: string;
  /**
  * @type {int64}
  */
  modifierId?: number;
  /**
  */
  modifierName?: string;
  /**
  * @type {date-time}
  */
  modified?: string;
  /**
  * @type {int64}
  */
  userId?: number;
  /**
  * @minimum 6
  */
  name?: string;
  /**
  */
  apiKeyOptions?: UserApiKeySettingsModel;

  static toModel(dto: DeepPartial<GenerateApiKeyRequestDTO>): GenerateApiKeyRequestModel;
  static toModel(dto: DeepPartial<GenerateApiKeyRequestDTO> | undefined | null): GenerateApiKeyRequestModel | undefined;
  static toModel(dto: DeepPartial<GenerateApiKeyRequestDTO> | undefined | null): GenerateApiKeyRequestModel | undefined {
    return dto ? new GenerateApiKeyRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GenerateApiKeyRequestModel> | GenerateApiKeyRequestModel): GenerateApiKeyRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      creatorId: unwrapped.creatorId,
      creatorName: unwrapped.creatorName,
      created: unwrapped.created,
      modifierId: unwrapped.modifierId,
      modifierName: unwrapped.modifierName,
      modified: unwrapped.modified,
      userId: unwrapped.userId,
      name: unwrapped.name,
      apiKeyOptions: unwrapped.apiKeyOptions,
    } as GenerateApiKeyRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GenerateApiKeyRequestModel, can not map to GenerateApiKeyRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
