// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ContactSchema from './contact-schema';
import DeliveryAddressSchema from './delivery-address-schema';
import BasketSchema from './basket-schema';
import RequestSummarySchema from './request-summary-schema';

const schema = {
  $id: '/add-subscription-request-schema',
  type: 'object',
  required: ['userId', 'subscriptionType', 'subscriptionTemplateNumber', 'subscriptionType'],
  properties: {
    userId: {
      type: 'integer',
    },
    subscriptionType: {
      enum: [
        1,
        2,
      ],
    },
    subscriptionTemplateNumber: {
      type: 'integer',
    },
    entryDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    isBillingContactReadonly: {
      type: 'boolean',
    },
    billingContact: {
      ...ContactSchema,
    },
    isDeliveryContactReadonly: {
      type: 'boolean',
    },
    deliveryContact: {
      ...DeliveryAddressSchema,
    },
    orderBasket: {
      ...BasketSchema,
    },
    summary: {
      ...RequestSummarySchema,
    },
    newsletter: {
      type: 'boolean',
    },
    languageIso2: {
      type: 'string',
      nullable: true,
    },
    acceptGdpr: {
      type: 'boolean',
    },
    acceptAgb: {
      type: 'boolean',
    },
  },
} as IJSONSchema;

export default schema;
