// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/create-app-system-web-api-request-schema',
  type: 'object',
  required: ['appSystemId', 'timeoutSeconds'],
  properties: {
    appSystemId: {
      type: 'integer',
    },
    apiUrl: {
      type: 'string',
      nullable: true,
    },
    apiUser: {
      type: 'string',
      nullable: true,
    },
    apiPassword: {
      type: 'string',
      nullable: true,
    },
    apiAuthenticationType: {
      type: 'string',
      nullable: true,
    },
    timeoutSeconds: {
      type: 'integer',
    },
    useProxy: {
      type: 'boolean',
    },
    proxyUrl: {
      type: 'string',
      nullable: true,
    },
    proxyUser: {
      type: 'string',
      nullable: true,
    },
    proxyPassword: {
      type: 'string',
      nullable: true,
    },
    enableHttpsCertificateValidation: {
      type: 'boolean',
    },
    workspaceId: {
      type: 'string',
      nullable: true,
    },
    secret: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
