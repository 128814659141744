// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetIntegrationCallOverviewRequestModel from '../../model/get-integration-call-overview-request-model';
import GetIntegrationCallsRequestModel from '../../model/get-integration-calls-request-model';
import GetIntegrationCallByIdRequestModel from '../../model/get-integration-call-by-id-request-model';
import GetIntegrationCallStepsByCallIdRequestModel from '../../model/get-integration-call-steps-by-call-id-request-model';
import CompleteIntegrationCallRequestModel from '../../model/complete-integration-call-request-model';
import ReExecuteIntegrationCallRequestModel from '../../model/re-execute-integration-call-request-model';
import DownloadIntegrationCallReportRequestModel from '../../model/download-integration-call-report-request-model';
import IntegrationCallStateOverviewListResultModel from '../../model/integration-call-state-overview-list-result-model';
import IntegrationCallListPagingResultModel from '../../model/integration-call-list-paging-result-model';
import IntegrationCallModel from '../../model/integration-call-model';
import IntegrationCallStepPagingResultModel from '../../model/integration-call-step-paging-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getIntegrationCallOverview(request: GetIntegrationCallOverviewRequestModel, config?: RequestConfig) {
    const requestDTO = GetIntegrationCallOverviewRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-integration-call-overview'] ?? 'core/integration/IntegrationCalls/Overview';
    const response = await service.get<any>(endpointPath, {
      query: {
        from: requestDTO.from,
        to: requestDTO.to,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(IntegrationCallStateOverviewListResultModel.toModel(dto));
  },
  /**  */
  async getIntegrationCalls(request: GetIntegrationCallsRequestModel, config?: RequestConfig) {
    const requestDTO = GetIntegrationCallsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-integration-calls'] ?? 'core/integration/IntegrationCalls';
    const response = await service.get<any>(endpointPath, {
      query: {
        dateFrom: requestDTO.dateFrom,
        dateTo: requestDTO.dateTo,
        integrationTaskId: requestDTO.integrationTaskId,
        integrationStatusId: requestDTO.integrationStatusId,
        correlationId: requestDTO.correlationId,
        appTransactionId: requestDTO.appTransactionId,
        appUserId: requestDTO.appUserId,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(IntegrationCallListPagingResultModel.toModel(dto));
  },
  /**  */
  async getIntegrationCallById(request: GetIntegrationCallByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetIntegrationCallByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-integration-call-by-id'] ?? 'core/integration/IntegrationCalls/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(IntegrationCallModel.toModel(dto));
  },
  /**  */
  async getIntegrationCallStepsByCallId(request: GetIntegrationCallStepsByCallIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetIntegrationCallStepsByCallIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-integration-call-steps-by-call-id'] ?? 'core/integration/IntegrationCalls/{id}/steps';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        runNo: requestDTO.runNo,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(IntegrationCallStepPagingResultModel.toModel(dto));
  },
  /**  */
  async completeIntegrationCall(request: CompleteIntegrationCallRequestModel, config?: RequestConfig) {
    const requestDTO = CompleteIntegrationCallRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-complete-integration-call'] ?? 'core/integration/IntegrationCalls/{id}/Complete';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.post<any>(endpointPath, {
      body: {
        userId: requestDTO.userId,
        comment: requestDTO.comment,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async reExecuteIntegrationCall(request: ReExecuteIntegrationCallRequestModel, config?: RequestConfig) {
    const requestDTO = ReExecuteIntegrationCallRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-re-execute-integration-call'] ?? 'core/integration/IntegrationCalls/{id}/ReExecute';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.post<any>(endpointPath, {
      body: {
        comment: requestDTO.comment,
        payload: requestDTO.payload,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async downloadIntegrationCallReport(request: DownloadIntegrationCallReportRequestModel, config?: RequestConfig) {
    const requestDTO = DownloadIntegrationCallReportRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-integration-call-report'] ?? 'core/integration/IntegrationCalls/DownloadIntegrationCallReport';
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      query: {
        dateFrom: requestDTO.dateFrom,
        dateTo: requestDTO.dateTo,
        customerName: requestDTO.customerName,
        taskId: requestDTO.taskId,
        integrationStatusId: requestDTO.integrationStatusId,
        searchCriteria: requestDTO.searchCriteria,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(new Blob([result], { type: 'application/octet-stream' }));
  },
});
