// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import AuthTokenModel from '../../model/auth-token-model';
import AuthTokenDTO from '../dto/auth-token-dto';
import dtoSchema from '../schemas/auth-token-schema';
import AccessTokenModel from '../../model/access-token-model';

export default abstract class AuthTokenModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<AuthTokenDTO>) {
    super();
    if (data) {
      this.accessToken = data.accessToken ? AccessTokenModel.toModel(data.accessToken) : undefined;
      this.refreshToken = data.refreshToken ?? undefined;
    }
  }
  /**
  */
  accessToken?: AccessTokenModel;
  /**
  */
  refreshToken?: string;

  static toModel(dto: DeepPartial<AuthTokenDTO>): AuthTokenModel;
  static toModel(dto: DeepPartial<AuthTokenDTO> | undefined | null): AuthTokenModel | undefined;
  static toModel(dto: DeepPartial<AuthTokenDTO> | undefined | null): AuthTokenModel | undefined {
    return dto ? new AuthTokenModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<AuthTokenModel> | AuthTokenModel): AuthTokenDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      accessToken: unwrapped.accessToken,
      refreshToken: unwrapped.refreshToken,
    } as AuthTokenDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for AuthTokenModel, can not map to AuthTokenDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
