// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import BusinessCharacteristicsModel from '../../model/business-characteristics-model';
import BusinessCharacteristicsDTO from '../dto/business-characteristics-dto';
import dtoSchema from '../schemas/business-characteristics-schema';
import BusinessCharacteristicModel from '../../model/business-characteristic-model';

export default abstract class BusinessCharacteristicsModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<BusinessCharacteristicsDTO>) {
    super();
    if (data) {
      this.ownershipType = data.ownershipType?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.operatingType = data.operatingType?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.operatingArea = data.operatingArea?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.gastronomicType = data.gastronomicType?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.wantsConsulting = data.wantsConsulting;
      this.kitchenStyle = data.kitchenStyle?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.accommodationType = data.accommodationType?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.expandedCateringOffers = data.expandedCateringOffers?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.restDays = data.restDays?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.communicationChannels = data.communicationChannels?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.paymentMethods = data.paymentMethods?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
      this.equipmentFeatures = data.equipmentFeatures?.map((i) => BusinessCharacteristicModel.toModel(i)) ?? [];
    }
  }
  /**
  */
  ownershipType?: BusinessCharacteristicModel[];
  /**
  */
  operatingType?: BusinessCharacteristicModel[];
  /**
  */
  operatingArea?: BusinessCharacteristicModel[];
  /**
  */
  gastronomicType?: BusinessCharacteristicModel[];
  /**
  */
  wantsConsulting?: boolean;
  /**
  */
  kitchenStyle?: BusinessCharacteristicModel[];
  /**
  */
  accommodationType?: BusinessCharacteristicModel[];
  /**
  */
  expandedCateringOffers?: BusinessCharacteristicModel[];
  /**
  */
  restDays?: BusinessCharacteristicModel[];
  /**
  */
  communicationChannels?: BusinessCharacteristicModel[];
  /**
  */
  paymentMethods?: BusinessCharacteristicModel[];
  /**
  */
  equipmentFeatures?: BusinessCharacteristicModel[];

  static toModel(dto: DeepPartial<BusinessCharacteristicsDTO>): BusinessCharacteristicsModel;
  static toModel(dto: DeepPartial<BusinessCharacteristicsDTO> | undefined | null): BusinessCharacteristicsModel | undefined;
  static toModel(dto: DeepPartial<BusinessCharacteristicsDTO> | undefined | null): BusinessCharacteristicsModel | undefined {
    return dto ? new BusinessCharacteristicsModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<BusinessCharacteristicsModel> | BusinessCharacteristicsModel): BusinessCharacteristicsDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      ownershipType: unwrapped.ownershipType,
      operatingType: unwrapped.operatingType,
      operatingArea: unwrapped.operatingArea,
      gastronomicType: unwrapped.gastronomicType,
      wantsConsulting: unwrapped.wantsConsulting,
      kitchenStyle: unwrapped.kitchenStyle,
      accommodationType: unwrapped.accommodationType,
      expandedCateringOffers: unwrapped.expandedCateringOffers,
      restDays: unwrapped.restDays,
      communicationChannels: unwrapped.communicationChannels,
      paymentMethods: unwrapped.paymentMethods,
      equipmentFeatures: unwrapped.equipmentFeatures,
    } as BusinessCharacteristicsDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for BusinessCharacteristicsModel, can not map to BusinessCharacteristicsDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
