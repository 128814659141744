// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import IntegrationCallStepResourceViewModel from '../../../../viewModel/resource/IntegrationCallStepResourceViewModel';
import IntegrationCallStepResourceDTO from '../../dto/resource/IntegrationCallStepResourceDTO';

export default abstract class IntegrationCallStepResourceModel extends BaseModel<IntegrationCallStepResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  * @type {int64}
  */
  get integrationCallId() { return this.dto.integrationCallId; }
  set integrationCallId(value) { this.dto.integrationCallId = value; }
  /**
  * @type {int64}
  */
  get targetAppSystemId() { return this.dto.targetAppSystemId; }
  set targetAppSystemId(value) { this.dto.targetAppSystemId = value; }
  /**
  */
  get targetAppSystemName() { return this.dto.targetAppSystemName; }
  set targetAppSystemName(value) { this.dto.targetAppSystemName = value; }
  /**
  */
  get name() { return this.dto.name; }
  set name(value) { this.dto.name = value; }
  /**
  * @type {int32}
  */
  get number() { return this.dto.number; }
  set number(value) { this.dto.number = value; }
  /**
  */
  get isSuccess() { return this.dto.isSuccess; }
  set isSuccess(value) { this.dto.isSuccess = value; }
  /**
  */
  get isOperative() { return this.dto.isOperative; }
  set isOperative(value) { this.dto.isOperative = value; }
  /**
  */
  get message() { return this.dto.message; }
  set message(value) { this.dto.message = value; }
  /**
  * @type {int32}
  */
  get runNo() { return this.dto.runNo; }
  set runNo(value) { this.dto.runNo = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.creatorid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = qs.creatorname;
    this.dto.created = qs.created;
    value = Number.parseFloat(qs.modifierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = qs.modifiername;
    this.dto.modified = qs.modified;
    value = Number.parseFloat(qs.integrationcallid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.integrationCallId = value;
    value = Number.parseFloat(qs.targetappsystemid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.targetAppSystemId = value;
    this.dto.targetAppSystemName = qs.targetappsystemname;
    this.dto.name = qs.name;
    value = Number.parseFloat(qs.number);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.number = value;
    value = qs.isSuccess === 'true' ? true : qs.issuccess;
    value = qs.isSuccess === 'false' ? false : undefined;
    this.dto.isSuccess = value;
    value = qs.isOperative === 'true' ? true : qs.isoperative;
    value = qs.isOperative === 'false' ? false : undefined;
    this.dto.isOperative = value;
    this.dto.message = qs.message;
    value = Number.parseFloat(qs.runno);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.runNo = value;
  }
}
