// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import BusinessCharacteristicSchema from './business-characteristic-schema';

const schema = {
  $id: '/business-characteristics-schema',
  type: 'object',
  required: [],
  properties: {
    ownershipType: {
      type: 'array',
      nullable: true,
      items: { ...BusinessCharacteristicSchema },
    },
    operatingType: {
      type: 'array',
      nullable: true,
      items: { ...BusinessCharacteristicSchema },
    },
    operatingArea: {
      type: 'array',
      nullable: true,
      items: { ...BusinessCharacteristicSchema },
    },
    gastronomicType: {
      type: 'array',
      nullable: true,
      items: { ...BusinessCharacteristicSchema },
    },
    wantsConsulting: {
      type: 'boolean',
    },
    kitchenStyle: {
      type: 'array',
      nullable: true,
      items: { ...BusinessCharacteristicSchema },
    },
    accommodationType: {
      type: 'array',
      nullable: true,
      items: { ...BusinessCharacteristicSchema },
    },
    expandedCateringOffers: {
      type: 'array',
      nullable: true,
      items: { ...BusinessCharacteristicSchema },
    },
    restDays: {
      type: 'array',
      nullable: true,
      items: { ...BusinessCharacteristicSchema },
    },
    communicationChannels: {
      type: 'array',
      nullable: true,
      items: { ...BusinessCharacteristicSchema },
    },
    paymentMethods: {
      type: 'array',
      nullable: true,
      items: { ...BusinessCharacteristicSchema },
    },
    equipmentFeatures: {
      type: 'array',
      nullable: true,
      items: { ...BusinessCharacteristicSchema },
    },
  },
} as IJSONSchema;

export default schema;
