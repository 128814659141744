// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ESuranceInvoiceAddressModel from '../../model/e-surance-invoice-address-model';
import ESuranceInvoiceAddressDTO from '../dto/e-surance-invoice-address-dto';
import dtoSchema from '../schemas/e-surance-invoice-address-schema';

export default abstract class ESuranceInvoiceAddressModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ESuranceInvoiceAddressDTO>) {
    super();
    if (data) {
      this.correspondenceLanguageIso2 = data.correspondenceLanguageIso2;
      this.isInvoiceAddressNotMemberAddress = data.isInvoiceAddressNotMemberAddress;
      this.isTrusteeAddress = data.isTrusteeAddress ?? undefined;
      this.salutationKey = data.salutationKey ?? undefined;
      this.salutationName = data.salutationName ?? undefined;
      this.firstName = data.firstName ?? undefined;
      this.lastName = data.lastName ?? undefined;
      this.street = data.street ?? undefined;
      this.houseNumber = data.houseNumber ?? undefined;
      this.zipCode = data.zipCode ?? undefined;
      this.city = data.city ?? undefined;
      this.email = data.email ?? undefined;
    }
  }
  /**
  * @minimum 1
  */
  correspondenceLanguageIso2?: string;
  /**
  */
  isInvoiceAddressNotMemberAddress?: boolean;
  /**
  */
  isTrusteeAddress?: boolean;
  /**
  * @type {int32}
  */
  salutationKey?: number;
  /**
  */
  salutationName?: string;
  /**
  */
  firstName?: string;
  /**
  */
  lastName?: string;
  /**
  */
  street?: string;
  /**
  */
  houseNumber?: string;
  /**
  */
  zipCode?: string;
  /**
  */
  city?: string;
  /**
  */
  email?: string;

  static toModel(dto: DeepPartial<ESuranceInvoiceAddressDTO>): ESuranceInvoiceAddressModel;
  static toModel(dto: DeepPartial<ESuranceInvoiceAddressDTO> | undefined | null): ESuranceInvoiceAddressModel | undefined;
  static toModel(dto: DeepPartial<ESuranceInvoiceAddressDTO> | undefined | null): ESuranceInvoiceAddressModel | undefined {
    return dto ? new ESuranceInvoiceAddressModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ESuranceInvoiceAddressModel> | ESuranceInvoiceAddressModel): ESuranceInvoiceAddressDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      correspondenceLanguageIso2: unwrapped.correspondenceLanguageIso2,
      isInvoiceAddressNotMemberAddress: unwrapped.isInvoiceAddressNotMemberAddress,
      isTrusteeAddress: unwrapped.isTrusteeAddress,
      salutationKey: unwrapped.salutationKey,
      salutationName: unwrapped.salutationName,
      firstName: unwrapped.firstName,
      lastName: unwrapped.lastName,
      street: unwrapped.street,
      houseNumber: unwrapped.houseNumber,
      zipCode: unwrapped.zipCode,
      city: unwrapped.city,
      email: unwrapped.email,
    } as ESuranceInvoiceAddressDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ESuranceInvoiceAddressModel, can not map to ESuranceInvoiceAddressDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
