<template>
  <div class="gs-shop-categories fill-height">
    <p-side-drawer
      flat
      :overlay="isOverlay"
      :value="visible"
      @input="$emit('update:visible', $event)"
    >
      <template #drawer-content>
        <p-list-group v-model="selection" mandatory>
          <gs-shop-category-entry
            v-for="category in value.sortedItems"
            :key="category.caption"
            :class="'category-list-entry depth-' + category.depth"
            :value="category"
            @click="onCategoryClick"
          ></gs-shop-category-entry>
        </p-list-group>
      </template>
      <template v-if="isAdmin" #drawer-footer>
        <p-button tertiary dense @click="gotoCreateCategory">
          <p-icon color="success" dense>
            add_circle
          </p-icon>
        </p-button>
      </template>
      <template #content>
        <p-container>
          <slot name="content"></slot>
        </p-container>
      </template>
    </p-side-drawer>
    <slot></slot>
  </div>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import ShopCategoriesListResourceListResultViewModel from '../../services/v1/viewModel/resource/ShopCategoriesListResourceListResultViewModel';
  import ShopCategoriesListResourceViewModel from '../../services/v1/viewModel/resource/ShopCategoriesListResourceViewModel';

  export default Vue.extend({
    name: 'GsShopCategories',
    props: {
      value: { type: Object as PropType<ShopCategoriesListResourceListResultViewModel>, default: () => new ShopCategoriesListResourceListResultViewModel() },
      visible: { type: Boolean, default: false },
      overlay: { type: Boolean, default: false },
      selectedCategory: { type: [String, Number], default: undefined },
    },
    data: () => ({
      selection: undefined as any,
    }),
    computed: {
      isAdmin(): boolean {
        return this.$auth.isInRole('admin', 'hostadmin');
      },
      isOverlay(): boolean {
        return this.$media.isTablet || this.$media.isMobile;
      },
    },
    beforeMount() {
      this.$emit('update:visible', !this.isOverlay);
    },
    methods: {
      gotoCreateCategory() {
        this.$router.push('/gs/shop/categories/create', () => {});
      },
      onCategoryClick(category: ShopCategoriesListResourceViewModel) {
        if (this.selection === undefined) {
          this.$emit('update:selected-category', undefined);
        } else {
          this.$emit('update:selected-category', category.id);
        }
        if (this.isOverlay) {
          this.$emit('update:visible', false);
        }
      },
    },
  });
</script>
