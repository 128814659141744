// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ShopTaxedPriceModel from '../../model/shop-taxed-price-model';
import ShopTaxedPriceDTO from '../dto/shop-taxed-price-dto';
import dtoSchema from '../schemas/shop-taxed-price-schema';

export default abstract class ShopTaxedPriceModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ShopTaxedPriceDTO>) {
    super();
    if (data) {
      this.unitGrossPrice = data.unitGrossPrice;
      this.unitNetPrice = data.unitNetPrice;
      this.taxRate = data.taxRate;
      this.taxAmount = data.taxAmount;
      this.crmItemPriceNumber = data.crmItemPriceNumber ?? undefined;
    }
  }
  /**
  * @type {double}
  */
  unitGrossPrice?: number;
  /**
  * @type {double}
  */
  unitNetPrice?: number;
  /**
  * @type {double}
  */
  taxRate?: number;
  /**
  * @type {double}
  */
  taxAmount?: number;
  /**
  * @type {int64}
  */
  crmItemPriceNumber?: number;

  static toModel(dto: DeepPartial<ShopTaxedPriceDTO>): ShopTaxedPriceModel;
  static toModel(dto: DeepPartial<ShopTaxedPriceDTO> | undefined | null): ShopTaxedPriceModel | undefined;
  static toModel(dto: DeepPartial<ShopTaxedPriceDTO> | undefined | null): ShopTaxedPriceModel | undefined {
    return dto ? new ShopTaxedPriceModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ShopTaxedPriceModel> | ShopTaxedPriceModel): ShopTaxedPriceDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      unitGrossPrice: unwrapped.unitGrossPrice,
      unitNetPrice: unwrapped.unitNetPrice,
      taxRate: unwrapped.taxRate,
      taxAmount: unwrapped.taxAmount,
      crmItemPriceNumber: unwrapped.crmItemPriceNumber,
    } as ShopTaxedPriceDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ShopTaxedPriceModel, can not map to ShopTaxedPriceDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
