// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import Int32LookupResourceViewModel from '../../../../viewModel/resource/Int32LookupResourceViewModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import Int32LookupResourceListResultViewModel from '../../../../viewModel/resource/Int32LookupResourceListResultViewModel';
import Int32LookupResourceListResultDTO from '../../dto/resource/Int32LookupResourceListResultDTO';

export default abstract class Int32LookupResourceListResultModel extends BaseModel<Int32LookupResourceListResultDTO> {
  /**
  */
  get items() { return this.getArrayModels(() => this.dto.items, Int32LookupResourceViewModel)!; }
  set items(value) { this.setArrayModels(() => this.dto.items, value); }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
  }
}
