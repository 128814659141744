// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ESuranceMembershipContactModel from '../../model/e-surance-membership-contact-model';
import ESuranceMembershipContactDTO from '../dto/e-surance-membership-contact-dto';
import dtoSchema from '../schemas/e-surance-membership-contact-schema';

export default abstract class ESuranceMembershipContactModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ESuranceMembershipContactDTO>) {
    super();
    if (data) {
      this.isCompany = data.isCompany;
      this.salutationKey = data.salutationKey ?? undefined;
      this.salutationName = data.salutationName ?? undefined;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.companyName = data.companyName;
      this.street = data.street;
      this.houseNumber = data.houseNumber;
      this.zipCode = data.zipCode;
      this.city = data.city;
      this.birthDate = data.birthDate ?? undefined;
      this.email = data.email;
    }
  }
  /**
  */
  isCompany?: boolean;
  /**
  * @type {int32}
  */
  salutationKey?: number;
  /**
  */
  salutationName?: string;
  /**
  * @minimum 1
  */
  firstName?: string;
  /**
  * @minimum 1
  */
  lastName?: string;
  /**
  * @minimum 1
  */
  companyName?: string;
  /**
  * @minimum 1
  */
  street?: string;
  /**
  * @minimum 1
  */
  houseNumber?: string;
  /**
  * @minimum 1
  */
  zipCode?: string;
  /**
  * @minimum 1
  */
  city?: string;
  /**
  * @type {date-time}
  */
  birthDate?: string;
  /**
  * @minimum 1
  */
  email?: string;

  static toModel(dto: DeepPartial<ESuranceMembershipContactDTO>): ESuranceMembershipContactModel;
  static toModel(dto: DeepPartial<ESuranceMembershipContactDTO> | undefined | null): ESuranceMembershipContactModel | undefined;
  static toModel(dto: DeepPartial<ESuranceMembershipContactDTO> | undefined | null): ESuranceMembershipContactModel | undefined {
    return dto ? new ESuranceMembershipContactModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ESuranceMembershipContactModel> | ESuranceMembershipContactModel): ESuranceMembershipContactDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      isCompany: unwrapped.isCompany,
      salutationKey: unwrapped.salutationKey,
      salutationName: unwrapped.salutationName,
      firstName: unwrapped.firstName,
      lastName: unwrapped.lastName,
      companyName: unwrapped.companyName,
      street: unwrapped.street,
      houseNumber: unwrapped.houseNumber,
      zipCode: unwrapped.zipCode,
      city: unwrapped.city,
      birthDate: unwrapped.birthDate,
      email: unwrapped.email,
    } as ESuranceMembershipContactDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ESuranceMembershipContactModel, can not map to ESuranceMembershipContactDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
