// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetAppSystemsRequestViewModel from '../../viewModel/request/AppSystems/GetAppSystemsRequestViewModel';
import LookupAppSystemsRequestViewModel from '../../viewModel/request/AppSystems/LookupAppSystemsRequestViewModel';
import AppSystemResourcePagingResultViewModel from '../../viewModel/resource/AppSystemResourcePagingResultViewModel';
import AppSystemCreateRequestViewModel from '../../viewModel/resource/AppSystemCreateRequestViewModel';
import Int64LookupResourcePagingResultViewModel from '../../viewModel/resource/Int64LookupResourcePagingResultViewModel';
import AppSystemResourceViewModel from '../../viewModel/resource/AppSystemResourceViewModel';
import CampaignCustomFieldResourceListResultViewModel from '../../viewModel/resource/CampaignCustomFieldResourceListResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getAppSystems(request: GetAppSystemsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-app-systems'] ?? 'core/integration/AppSystems';
    const response = await service.get<any>(endpointPath, {
      query: {
        isActive: requestDTO.isActive,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new AppSystemResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async createAppSystem(model: AppSystemCreateRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-app-system'] ?? 'core/integration/AppSystems';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async lookupAppSystems(request: LookupAppSystemsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-app-systems'] ?? 'core/integration/AppSystems/Lookup';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new Int64LookupResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getAppSystemById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-app-system-by-id'] ?? 'core/integration/AppSystems/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new AppSystemResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateAppSystem(id: number, model: AppSystemResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-app-system'] ?? 'core/integration/AppSystems/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
  },
  /**  */
  async deleteAppSystem(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-app-system'] ?? 'core/integration/AppSystems/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
  },
  /**  */
  async getCustomFields(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-custom-fields'] ?? 'core/integration/AppSystems/{id}/CustomFields';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new CampaignCustomFieldResourceListResultViewModel().fromDTO(dto);
  },
});
