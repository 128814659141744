// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetShopOrdersByDigitalAssetIdRequestModel from '../../model/get-shop-orders-by-digital-asset-id-request-model';
import GetShopOrdersByDigitalAssetIdRequestDTO from '../dto/get-shop-orders-by-digital-asset-id-request-dto';
import dtoSchema from '../schemas/get-shop-orders-by-digital-asset-id-request-schema';

export default abstract class GetShopOrdersByDigitalAssetIdRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetShopOrdersByDigitalAssetIdRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.currentPage = data.currentPage;
      this.pageSize = data.pageSize;
      this.includeCount = data.includeCount;
      this.noPaging = data.noPaging;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  * @type {int32}
  */
  currentPage?: number;
  /**
  * @type {int32}
  */
  pageSize?: number;
  /**
  */
  includeCount?: boolean;
  /**
  */
  noPaging?: boolean;

  static toModel(dto: DeepPartial<GetShopOrdersByDigitalAssetIdRequestDTO>): GetShopOrdersByDigitalAssetIdRequestModel;
  static toModel(dto: DeepPartial<GetShopOrdersByDigitalAssetIdRequestDTO> | undefined | null): GetShopOrdersByDigitalAssetIdRequestModel | undefined;
  static toModel(dto: DeepPartial<GetShopOrdersByDigitalAssetIdRequestDTO> | undefined | null): GetShopOrdersByDigitalAssetIdRequestModel | undefined {
    return dto ? new GetShopOrdersByDigitalAssetIdRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetShopOrdersByDigitalAssetIdRequestModel> | GetShopOrdersByDigitalAssetIdRequestModel): GetShopOrdersByDigitalAssetIdRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      currentPage: unwrapped.currentPage,
      pageSize: unwrapped.pageSize,
      includeCount: unwrapped.includeCount,
      noPaging: unwrapped.noPaging,
    } as GetShopOrdersByDigitalAssetIdRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetShopOrdersByDigitalAssetIdRequestModel, can not map to GetShopOrdersByDigitalAssetIdRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
