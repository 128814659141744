// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetShopArticlesRequestViewModel from '../../viewModel/request/ShopArticles/GetShopArticlesRequestViewModel';
import GetShopArticlesByDigitalAssetIdRequestViewModel from '../../viewModel/request/ShopArticles/GetShopArticlesByDigitalAssetIdRequestViewModel';
import GetShopArticleByIdRequestViewModel from '../../viewModel/request/ShopArticles/GetShopArticleByIdRequestViewModel';
import GetShopArticlePriceRequestViewModel from '../../viewModel/request/ShopArticles/GetShopArticlePriceRequestViewModel';
import ShopArticlesListResourcePagingResultViewModel from '../../viewModel/resource/ShopArticlesListResourcePagingResultViewModel';
import ShopArticleResourceViewModel from '../../viewModel/resource/ShopArticleResourceViewModel';
import ShopArticleResourcePagingResultViewModel from '../../viewModel/resource/ShopArticleResourcePagingResultViewModel';
import ShopTaxedPriceResourceViewModel from '../../viewModel/resource/ShopTaxedPriceResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getShopArticles(request: GetShopArticlesRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-articles'] ?? 'ShopArticles';
    const response = await service.get<any>(endpointPath, {
      query: {
        isPublished: requestDTO.isPublished,
        productType: requestDTO.productType,
        hasShipping: requestDTO.hasShipping,
        validFrom: requestDTO.validFrom,
        validTo: requestDTO.validTo,
        exclude: requestDTO.exclude,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new ShopArticlesListResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async createShopArticle(model: ShopArticleResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-shop-article'] ?? 'ShopArticles';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getShopArticlesByDigitalAssetId(id: number, request: GetShopArticlesByDigitalAssetIdRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-articles-by-digital-asset-id'] ?? 'ShopArticles/ByDigitalAssetId/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        currentPage: requestDTO.currentPage,
        pageSize: requestDTO.pageSize,
        includeCount: requestDTO.includeCount,
        noPaging: requestDTO.noPaging,
      },
      ...config,
    });
    const dto = response.data.result;
    return new ShopArticleResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getShopArticleById(id: number, request: GetShopArticleByIdRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-article-by-id'] ?? 'ShopArticles/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        translatedLanguage_ISO2: requestDTO.translatedLanguage_ISO2,
      },
      ...config,
    });
    const dto = response.data.result;
    return new ShopArticleResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateShopArticle(id: number, model: ShopArticleResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-shop-article'] ?? 'ShopArticles/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getShopArticlePrice(id: number, request: GetShopArticlePriceRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-article-price'] ?? 'ShopArticles/{id}/Price';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
      },
      ...config,
    });
    const dto = response.data.result;
    return new ShopTaxedPriceResourceViewModel().fromDTO(dto);
  },
});
