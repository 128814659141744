// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import DigitalAssetWithCountAndFileNameModel from '../../model/digital-asset-with-count-and-file-name-model';
import DigitalAssetWithCountAndFileNameDTO from '../dto/digital-asset-with-count-and-file-name-dto';
import dtoSchema from '../schemas/digital-asset-with-count-and-file-name-schema';

export default abstract class DigitalAssetWithCountAndFileNameModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<DigitalAssetWithCountAndFileNameDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.creatorId = data.creatorId;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modified = data.modified ?? undefined;
      this.documentId = data.documentId;
      this.name = data.name ?? undefined;
      this.filename = data.filename ?? undefined;
      this.extension = data.extension ?? undefined;
      this.countAssigned = data.countAssigned;
      this.countSold = data.countSold;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  * @type {int64}
  */
  creatorId?: number;
  /**
  * @type {date-time}
  */
  created?: string;
  /**
  * @type {int64}
  */
  modifierId?: number;
  /**
  * @type {date-time}
  */
  modified?: string;
  /**
  * @type {int64}
  */
  documentId?: number;
  /**
  */
  name?: string;
  /**
  */
  filename?: string;
  /**
  */
  extension?: string;
  /**
  * @type {int64}
  */
  countAssigned?: number;
  /**
  * @type {int64}
  */
  countSold?: number;

  static toModel(dto: DeepPartial<DigitalAssetWithCountAndFileNameDTO>): DigitalAssetWithCountAndFileNameModel;
  static toModel(dto: DeepPartial<DigitalAssetWithCountAndFileNameDTO> | undefined | null): DigitalAssetWithCountAndFileNameModel | undefined;
  static toModel(dto: DeepPartial<DigitalAssetWithCountAndFileNameDTO> | undefined | null): DigitalAssetWithCountAndFileNameModel | undefined {
    return dto ? new DigitalAssetWithCountAndFileNameModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<DigitalAssetWithCountAndFileNameModel> | DigitalAssetWithCountAndFileNameModel): DigitalAssetWithCountAndFileNameDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      creatorId: unwrapped.creatorId,
      created: unwrapped.created,
      modifierId: unwrapped.modifierId,
      modified: unwrapped.modified,
      documentId: unwrapped.documentId,
      name: unwrapped.name,
      filename: unwrapped.filename,
      extension: unwrapped.extension,
      countAssigned: unwrapped.countAssigned,
      countSold: unwrapped.countSold,
    } as DigitalAssetWithCountAndFileNameDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for DigitalAssetWithCountAndFileNameModel, can not map to DigitalAssetWithCountAndFileNameDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
