// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import DynamicFormDefinitionModel from '../../model/dynamic-form-definition-model';
import DynamicFormDefinitionDTO from '../dto/dynamic-form-definition-dto';
import dtoSchema from '../schemas/dynamic-form-definition-schema';
import DynamicFormSettingsModel from '../../model/dynamic-form-settings-model';
import DynamicFormFieldDefinitionModel from '../../model/dynamic-form-field-definition-model';

export default abstract class DynamicFormDefinitionModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<DynamicFormDefinitionDTO>) {
    super();
    if (data) {
      this.id = data.id ?? undefined;
      this.caption = data.caption ?? undefined;
      this.settings = data.settings ? DynamicFormSettingsModel.toModel(data.settings) : undefined;
      this.fields = data.fields?.map((i) => DynamicFormFieldDefinitionModel.toModel(i)) ?? [];
    }
  }
  /**
  */
  id?: string;
  /**
  */
  caption?: string;
  /**
  */
  settings?: DynamicFormSettingsModel;
  /**
  */
  fields?: DynamicFormFieldDefinitionModel[];

  static toModel(dto: DeepPartial<DynamicFormDefinitionDTO>): DynamicFormDefinitionModel;
  static toModel(dto: DeepPartial<DynamicFormDefinitionDTO> | undefined | null): DynamicFormDefinitionModel | undefined;
  static toModel(dto: DeepPartial<DynamicFormDefinitionDTO> | undefined | null): DynamicFormDefinitionModel | undefined {
    return dto ? new DynamicFormDefinitionModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<DynamicFormDefinitionModel> | DynamicFormDefinitionModel): DynamicFormDefinitionDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      caption: unwrapped.caption,
      settings: unwrapped.settings,
      fields: unwrapped.fields,
    } as DynamicFormDefinitionDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for DynamicFormDefinitionModel, can not map to DynamicFormDefinitionDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
