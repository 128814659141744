// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import ShopInvoiceAddressResourceViewModel from '../../viewModel/resource/ShopInvoiceAddressResourceViewModel';
import ShopCheckoutOrderResourceViewModel from '../../viewModel/resource/ShopCheckoutOrderResourceViewModel';
import ShopDeliveryAddressResourceViewModel from '../../viewModel/resource/ShopDeliveryAddressResourceViewModel';
import UpdateCheckoutOrderInfoRequestViewModel from '../../viewModel/resource/UpdateCheckoutOrderInfoRequestViewModel';
import ShopCheckoutSetPaymentRequestViewModel from '../../viewModel/resource/ShopCheckoutSetPaymentRequestViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getInvoiceAddress(userId: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-invoice-address'] ?? 'ShopCheckout/{userId}/InvoiceAddress';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${userId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new ShopInvoiceAddressResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateInvoiceAddress(userId: number, model: ShopInvoiceAddressResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-invoice-address'] ?? 'ShopCheckout/{userId}/InvoiceAddress';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${userId}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const dto = response.data.result;
    return new ShopCheckoutOrderResourceViewModel().fromDTO(dto);
  },
  /**  */
  async getDeliveryAddress(userId: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-delivery-address'] ?? 'ShopCheckout/{userId}/DeliveryAddress';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${userId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new ShopDeliveryAddressResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateDeliveryAddress(userId: number, model: ShopDeliveryAddressResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-delivery-address'] ?? 'ShopCheckout/{userId}/DeliveryAddress';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${userId}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const dto = response.data.result;
    return new ShopCheckoutOrderResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateOrderInfo(userId: number, model: UpdateCheckoutOrderInfoRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-order-info'] ?? 'ShopCheckout/{userId}/OrderInfo';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${userId}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const dto = response.data.result;
    return new ShopCheckoutOrderResourceViewModel().fromDTO(dto);
  },
  /**  */
  async setPayment(userId: number, model: ShopCheckoutSetPaymentRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-set-payment'] ?? 'ShopCheckout/{userId}/Payment';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${userId}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const dto = response.data.result;
    return new ShopCheckoutOrderResourceViewModel().fromDTO(dto);
  },
  /**  */
  async getOrder(userId: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-order'] ?? 'ShopCheckout/{userId}/Order';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${userId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new ShopCheckoutOrderResourceViewModel().fromDTO(dto);
  },
});
