// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ContactAddressResourceViewModel from '../../../../viewModel/resource/ContactAddressResourceViewModel';
import ContactResourceViewModel from '../../../../viewModel/resource/ContactResourceViewModel';
import ContactResourceDTO from '../../dto/resource/ContactResourceDTO';

export default abstract class ContactResourceModel extends BaseModel<ContactResourceDTO> {
  /**
  * @type {int32}
  */
  get customerNumber() { return this.dto.customerNumber; }
  set customerNumber(value) { this.dto.customerNumber = value; }
  /**
  * @type {uuid}
  */
  get contactId() { return this.dto.contactId; }
  set contactId(value) { this.dto.contactId = value; }
  /**
  * @type {uuid}
  */
  get parentContactId() { return this.dto.parentContactId; }
  set parentContactId(value) { this.dto.parentContactId = value; }
  /**
  */
  get languageIso2() { return this.dto.languageIso2; }
  set languageIso2(value) { this.dto.languageIso2 = value; }
  /**
  */
  get address() { return this.getObjectModel(() => this.dto.address, ContactAddressResourceViewModel)!; }
  set address(value) { this.setObjectModel(() => this.dto.address, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.customernumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.customerNumber = value;
    this.dto.contactId = qs.contactid;
    this.dto.parentContactId = qs.parentcontactid;
    this.dto.languageIso2 = qs.languageiso2;
  }
}
