// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetSuppliersLookupRequestViewModel from '../../viewModel/request/ShopSuppliers/GetSuppliersLookupRequestViewModel';
import GetShopSuppliersRequestViewModel from '../../viewModel/request/ShopSuppliers/GetShopSuppliersRequestViewModel';
import Int64LookupResourceListResultViewModel from '../../viewModel/resource/Int64LookupResourceListResultViewModel';
import ShopSupplierResourceListResultViewModel from '../../viewModel/resource/ShopSupplierResourceListResultViewModel';
import ShopSupplierResourceViewModel from '../../viewModel/resource/ShopSupplierResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getSuppliersLookup(request: GetSuppliersLookupRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-suppliers-lookup'] ?? 'ShopSuppliers/Lookup';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new Int64LookupResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getShopSuppliers(request: GetShopSuppliersRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-suppliers'] ?? 'ShopSuppliers';
    const response = await service.get<any>(endpointPath, {
      query: {
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new ShopSupplierResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async createShopSupplier(model: ShopSupplierResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-shop-supplier'] ?? 'ShopSuppliers';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async getShopSupplierById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-supplier-by-id'] ?? 'ShopSuppliers/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new ShopSupplierResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateShopSupplier(id: number, model: ShopSupplierResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-shop-supplier'] ?? 'ShopSuppliers/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async deleteShopSupplierById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-shop-supplier-by-id'] ?? 'ShopSuppliers/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
});
