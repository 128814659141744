import Vue from 'vue';
import MembershipBusinessResourceModel from '../../generated/types/model/resource/MembershipBusinessResourceModel';
import RequestSummaryResourceViewModel from './RequestSummaryResourceViewModel';
import utils from '../utils';

export default class MembershipBusinessResourceViewModel extends MembershipBusinessResourceModel {
  protected transformToDTO() {}
  protected transformFromDTO() {}

  get salutationDisplay() { return Vue.$t(`form.address.salutationEnum.${this.salutation!}`); }

  get summary() {
    return new RequestSummaryResourceViewModel().fromDTO({
      sections: [
        {
          title: `${this.name}, ${this.city}`,
          items:
          [
            {
              label: Vue.$t('memberPortal.createMembership.label.nameOfBusiness'),
              displayValue: this.companyName,
            },
            {
              label: Vue.$t('memberPortal.createMembership.table.businessOperations'),
              displayValue: Vue.$format.localDate(this.startDate!),
            },
            {
              ...utils.insertIf(Vue._.isSet(this.salutation), {
                label: Vue.$t('form.address.salutation'),
                displayValue: this.salutationDisplay,
              }),
            },
            {
              ...utils.insertIf(!!this.firstName, {
                label: Vue.$t('form.address.firstName'),
                displayValue: this.firstName,
              }),
            },
            {
              ...utils.insertIf(!!this.firstName, {
                label: Vue.$t('form.address.lastName'),
                displayValue: this.lastName,
              }),
            },
            {
              ...utils.insertIf(!!this.street, {
                label: Vue.$t('form.address.address'),
                displayValue: `${this.street} ${this.houseNumber}`,
              }),
            },
            {
              ...utils.insertIf(!!this.addressLine1, {
                label: Vue.$t('form.address.additionalAddress'),
                displayValue: this.addressLine1,
              }),
            },
            {
              ...utils.insertIf(!!this.poBox, {
                label: Vue.$t('form.address.post'),
                displayValue: this.poBox,
              }),
            },
            {
              ...utils.insertIf(!!this.postalCode, {
                label: `${Vue.$t('form.address.plz')} / ${Vue.$t('form.address.place')}`,
                displayValue: `${this.postalCode} / ${this.city}`,
              }),
            },
            {
              ...utils.insertIf(!!this.countryIso2, {
                label: Vue.$t('form.address.country'),
                displayValue: this.countryIso2,
              }),
            },
            {
              ...utils.insertIf(!!this.eMail, {
                label: Vue.$t('form.address.email'),
                displayValue: this.eMail,
              }),
            },
            {
              ...utils.insertIf(!!this.website, {
                label: Vue.$t('form.address.website'),
                displayValue: this.website,
              }),
            },
          ],
        },
      ].map((section) => ({
        ...section,
        items: section?.items?.filter((item: any) => item.label !== undefined && item.displayValue !== undefined && item.displayValue !== null) ?? [],
      })),
    });
  }
}
