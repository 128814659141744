// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import DigitalAssetWithCountAndFileNameViewModel from '../../../../viewModel/resource/DigitalAssetWithCountAndFileNameViewModel';
import DigitalAssetWithCountAndFileNameDTO from '../../dto/resource/DigitalAssetWithCountAndFileNameDTO';

export default abstract class DigitalAssetWithCountAndFileNameModel extends BaseModel<DigitalAssetWithCountAndFileNameDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  * @type {int64}
  */
  get documentId() { return this.dto.documentId; }
  set documentId(value) { this.dto.documentId = value; }
  /**
  */
  get name() { return this.dto.name; }
  set name(value) { this.dto.name = value; }
  /**
  */
  get filename() { return this.dto.filename; }
  set filename(value) { this.dto.filename = value; }
  /**
  */
  get extension() { return this.dto.extension; }
  set extension(value) { this.dto.extension = value; }
  /**
  * @type {int64}
  */
  get countAssigned() { return this.dto.countAssigned; }
  set countAssigned(value) { this.dto.countAssigned = value; }
  /**
  * @type {int64}
  */
  get countSold() { return this.dto.countSold; }
  set countSold(value) { this.dto.countSold = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.creatorid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.created = qs.created;
    value = Number.parseFloat(qs.modifierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modified = qs.modified;
    value = Number.parseFloat(qs.documentid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.documentId = value;
    this.dto.name = qs.name;
    this.dto.filename = qs.filename;
    this.dto.extension = qs.extension;
    value = Number.parseFloat(qs.countassigned);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.countAssigned = value;
    value = Number.parseFloat(qs.countsold);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.countSold = value;
  }
}
