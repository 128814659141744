<template>
  <div>
    <p-progress-linear v-if="isLoading" indeterminate></p-progress-linear>
    <div v-if="!isLoading">
      <p-row spacious>
        <p-col xs12>
          <p-alert type="info" :value="true">
            <div>
              {{ $t('memberPortal.createMembership.infoBox.businessCharacteristics.title') }}
            </div>
            <p-row>
              <div>
                <p>{{ $t('memberPortal.createMembership.infoBox.businessCharacteristics.body') }}</p>
                <span>{{ $t('memberPortal.createMembership.infoBox.businessCharacteristics.dataprotection') }} <a target="_blank" :href="$t('links.dataprotection.url')">{{ $t('links.dataprotection.text') }}</a></span> 
              </div>
            </p-row>
          </p-alert>
        </p-col>
      </p-row>
      <!--Betriebsmerkmale-->
      <div>
        <p-row>
          <p-col xs12 md6>
            <p-select
              v-model="innerValue.ownershipType"
              :label="$t('memberPortal.createMembership.label.ownershipType')"
              :items="ownershipTypeItems"
            ></p-select>
          </p-col>
          <p-col xs12 md6>
            <p-select
              v-model="innerValue.operatingType"
              :label="$t('memberPortal.createMembership.label.operatingMode')"
              :items="operatingModeItems"
            ></p-select>
          </p-col>
        </p-row>
      </div>

      <!--Gastronomie-->
      <div v-if="hasGastronomy">
        <h5 class="mb-2">
          {{ $t('memberPortal.createMembership.title.gastronomy') }}
        </h5>
        <p-row>
          <p-col xs12 md6>
            <p-select
              v-model="innerValue.operatingArea"
              :label="$t('memberPortal.createMembership.label.operationArea')"
              :items="operationAreaItems"
            ></p-select>
            <p-select
              v-if="innerValue.operatingArea != null"
              v-model="innerValue.gastronomicType"
              :label="$t('memberPortal.createMembership.label.gastronomicType')"
              :items="operationTypeItems"
            ></p-select>
          </p-col>
          <p-col xs12 md6>
            <p-select-multiple
              v-model="innerValue.kitchenStyle"
              :label="$t('memberPortal.createMembership.label.kitchenStyle')"
              :items="kitchenStyleItems"
            ></p-select-multiple>
            <p-select-multiple
              v-model="innerValue.expandedCateringOffers"
              :label="$t('memberPortal.createMembership.label.expandedCateringOffers')"
              :items="expandedCateringOffersItems"
            ></p-select-multiple>
          </p-col>
        </p-row>
      </div>

      <!--Beherbergung-->
      <div v-if="hasAccommodation">
        <h5 class="mb-2">
          {{ $t('memberPortal.createMembership.title.accommodation') }}
        </h5>
        <p-row>
          <p-col xs12 md6>
            <p-select
              v-model="innerValue.accommodationType"
              :label="$t('memberPortal.createMembership.label.accommodationType')"
              :items="accommodationTypeItems"
            ></p-select>
          </p-col>
          <p-col xs12 md6>
            <p-checkbox
              v-model="innerValue.wantsConsulting"
              :label="$t('memberPortal.createMembership.label.wantsConsulting')"
            ></p-checkbox>
          </p-col>
        </p-row>
      </div>

      <!--Zusatzinformationen-->
      <div>
        <h5 class="mb-2">
          {{ $t('memberPortal.createMembership.title.additionalInformation') }}
        </h5>
        <p-row>
          <p-col xs12 md6>
            <p-select-multiple
              v-model="innerValue.restDays"
              :label="$t('memberPortal.createMembership.label.restDays')"
              :items="restDaysItems"
            ></p-select-multiple>
            <p-select-multiple
              v-model="innerValue.communicationChannels"
              :label="$t('memberPortal.createMembership.label.communicationChannels')"
              :items="communicationChannelsItems"
            ></p-select-multiple>
          </p-col>
          <p-col xs12 md6>
            <p-select-multiple
              v-model="innerValue.paymentMethods"
              :label="$t('memberPortal.createMembership.label.paymentMethods')"
              :items="paymentMethodsItems"
            ></p-select-multiple>
            <p-select-multiple
              v-model="innerValue.equipmentFeatures"
              :label="$t('memberPortal.createMembership.label.equipmentFeatures')"
              :items="equipmentFeaturesItems"
            ></p-select-multiple>
          </p-col>
        </p-row>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import OperatingModeRequestViewModel from '../../services/v1/viewModel/request/Attributes/OperatingModeRequestViewModel';
  import OperationAreaRequestViewModel from '../../services/v1/viewModel/request/Attributes/OperationAreaRequestViewModel';
  import AttributeLookupResourceViewModel from '../../services/v1/viewModel/resource/AttributeLookupResourceViewModel';
  import GuidLookupResourcePagingResultViewModel from '../../services/v1/viewModel/resource/GuidLookupResourcePagingResultViewModel';
  import GuidLookupResourceViewModel from '../../services/v1/viewModel/resource/GuidLookupResourceViewModel';
  import OperatingModeLookupResourceViewModel from '../../services/v1/viewModel/resource/OperatingModeLookupResourceViewModel';
  import BusinessCharacteristicViewModel from '../../services/v1/viewModel/resource/BusinessCharacteristicViewModel';
  import BusinessCharacteristicsResourceViewModel from '../../services/v1/viewModel/resource/BusinessCharacteristicsResourceViewModel';

  export interface InternalBusinessCharacteristics {
    ownershipType: string | undefined,
    operatingType: string | undefined,
    operatingArea: string | undefined,
    gastronomicType: string | undefined,
    wantsConsulting: boolean | undefined,
    kitchenStyle: string [] | undefined,
    accommodationType: string | undefined,
    expandedCateringOffers: string[] | undefined,
    restDays: string[] | undefined,
    paymentMethods: string[] | undefined,
    communicationChannels: string[] | undefined,
    equipmentFeatures: string[] | undefined,
  }

  export default Vue.extend({
    props: {
      value: { type: Object as PropType<BusinessCharacteristicsResourceViewModel>, default: undefined },
      membershipId: { type: Number, default: undefined },
      businessId: { type: String, default: undefined },
    },
    data: () => ({
      isLoading: true as boolean,
      accommodationTypeItems: [] as GuidLookupResourceViewModel[],
      ownershipTypeItems: [] as GuidLookupResourceViewModel[],
      operatingModeItems: [] as OperatingModeLookupResourceViewModel[],
      operationAreaItems: [] as AttributeLookupResourceViewModel[],
      servicedCateringItems: [] as GuidLookupResourceViewModel[],
      entertainmentCateringItems: [] as GuidLookupResourceViewModel[],
      communityCateringItems: [] as GuidLookupResourceViewModel[],
      otherCateringItems: [] as GuidLookupResourceViewModel[],
      fastFoodCateringItems: [] as GuidLookupResourceViewModel[],
      kitchenStyleItems: [] as GuidLookupResourceViewModel[],
      expandedCateringOffersItems: [] as GuidLookupResourceViewModel[],
      restDaysItems: [] as GuidLookupResourceViewModel[],
      communicationChannelsItems: [] as GuidLookupResourceViewModel[],
      paymentMethodsItems: [] as GuidLookupResourceViewModel[],
      equipmentFeaturesItems: [] as GuidLookupResourceViewModel[],
      gastronomicTypeSelectionDictionary: new Map<string, GuidLookupResourceViewModel[]>(),
      innerValue: {} as InternalBusinessCharacteristics,
    }),
    computed: {
      operationTypeItems(): GuidLookupResourceViewModel[] {
        const key = this.operationAreaItems.find((x) => x.id === this.innerValue.operatingArea)?.childKey;
        const selectedTypes = this.gastronomicTypeSelectionDictionary.get(key ?? '') ?? [];
        const isGastronomicTypeInSelectedTypes = selectedTypes.some((type) => type.id === this.innerValue.gastronomicType);

        if (!isGastronomicTypeInSelectedTypes && this.innerValue.gastronomicType !== undefined) {
          this.innerValue.gastronomicType = undefined;
        }
        return selectedTypes;
      },
      hasGastronomy(): boolean {
        return this.operatingModeItems.find((x) => x.id === this.innerValue.operatingType)?.isGastronomy ?? false;
      },
      hasAccommodation(): boolean {
        return this.operatingModeItems.find((x) => x.id === this.innerValue.operatingType)?.isAccommodation ?? false;
      },

    },
    watch: {
      innerValue: {
        deep: true,
        handler(newVal) {
          this.$emit('input', this.mapToBusinessCharacteristicsResourceViewModel(newVal));
        },
      },
      value(newVal) {
        this.$emit('innerValue', this.mapToInternalBusinessCharacteristics(newVal));
      },
      hasGastronomy(newVal) {
        if (!newVal) {
          this.innerValue.operatingArea = undefined;
          this.innerValue.gastronomicType = undefined;
          this.innerValue.kitchenStyle = undefined;
          this.innerValue.expandedCateringOffers = undefined;
        }
      },
      hasAccommodation(newVal) {
        if (!newVal) {
          this.innerValue.accommodationType = undefined;
          this.innerValue.wantsConsulting = undefined;
        }
      },
    },
    async mounted() {
      await this.loadData();
      this.innerValue = this.mapToInternalBusinessCharacteristics(this.value);
      if (this.membershipId && this.businessId) {
        const attributes = (await this.$service.api.memberships.getBusinessDetail(this.membershipId, this.businessId)).businessCharacteristics;
        this.innerValue = this.mapToInternalBusinessCharacteristics(attributes);
      }
      this.isLoading = false;
    },
    methods: {
      async loadData() {
        this.isLoading = true;
        const promises = [
          this.ownershipTypeItems = await this.loadItems(this.$service.api.attributes.getOwnershipTypes()),
          this.operatingModeItems = (await this.$service.api.attributes.operatingMode(new OperatingModeRequestViewModel())).items,
          this.restDaysItems = await this.loadItems(this.$service.api.attributes.getRestDays()),
          this.communicationChannelsItems = await this.loadItems(this.$service.api.attributes.getCommunicationChannels()),
          this.paymentMethodsItems = await this.loadItems(this.$service.api.attributes.getPaymentMethods()),
          this.equipmentFeaturesItems = await this.loadItems(this.$service.api.attributes.getEquipmentFeatures()),
          this.operationAreaItems = (await this.$service.api.attributes.operationArea(new OperationAreaRequestViewModel())).items,
          this.kitchenStyleItems = await this.loadItems(this.$service.api.attributes.getKitchenStyle()),
          this.expandedCateringOffersItems = await this.loadItems(this.$service.api.attributes.getExpandedCateringOffers()),
          this.accommodationTypeItems = await this.loadItems(this.$service.api.attributes.getAccommodationTypes()),
          this.gastronomicTypeSelectionDictionary.set('ServicedCatering', (await this.loadItems(this.$service.api.attributes.getServicedCatering()))),
          this.gastronomicTypeSelectionDictionary.set('EntertainmentCatering', (await this.loadItems(this.$service.api.attributes.getEntertainmentCatering()))),
          this.gastronomicTypeSelectionDictionary.set('CommunityCatering', (await this.loadItems(this.$service.api.attributes.getCommunityCatering()))),
          this.gastronomicTypeSelectionDictionary.set('OtherCatering', (await this.loadItems(this.$service.api.attributes.getOtherCatering()))),
          this.gastronomicTypeSelectionDictionary.set('FastFoodCatering', (await this.loadItems(this.$service.api.attributes.getFastFoodCatering()))),
        ];
        await Promise.all(promises).finally(() => {
        });
      },
      async loadItems(result: Promise<GuidLookupResourcePagingResultViewModel>) {
        return (await result).items;
      },
      mapToBusinessCharacteristicViewModel(value:GuidLookupResourceViewModel | AttributeLookupResourceViewModel | OperatingModeLookupResourceViewModel | undefined):BusinessCharacteristicViewModel {
        if (!value) return new BusinessCharacteristicViewModel();
        return new BusinessCharacteristicViewModel().fromModel({ id: value.id, name: value.caption });
      },
      mapToBusinessCharacteristicViewModelArray(values:GuidLookupResourceViewModel[] | AttributeLookupResourceViewModel[] | OperatingModeLookupResourceViewModel[]):BusinessCharacteristicViewModel[] {
        return values.map((x) => this.mapToBusinessCharacteristicViewModel(x));
      },
      mapToInternalBusinessCharacteristics(value: BusinessCharacteristicsResourceViewModel): InternalBusinessCharacteristics {
        if (!value) {
          return {} as InternalBusinessCharacteristics;
        }

        return {
          ownershipType: ((value.ownershipType?.length > 0) ? value.ownershipType[0].id : undefined),
          operatingType: ((value.operatingType?.length > 0) ? value.operatingType[0].id : undefined),
          operatingArea: ((value.operatingArea?.length > 0) ? value.operatingArea[0].id : undefined),
          gastronomicType: ((value.gastronomicType?.length > 0) ? value.gastronomicType[0].id : undefined),
          wantsConsulting: value.wantsConsulting,
          kitchenStyle: (value.kitchenStyle ?? []).map((x) => x.id!),
          accommodationType: ((value.accommodationType?.length > 0) ? value.accommodationType[0].id : undefined),
          expandedCateringOffers: (value.expandedCateringOffers ?? []).map((x) => x.id!),
          restDays: (value.restDays ?? []).map((x) => x.id!),
          paymentMethods: (value.paymentMethods ?? []).map((x) => x.id!),
          communicationChannels: (value.communicationChannels ?? []).map((x) => x.id!),
          equipmentFeatures: (value.equipmentFeatures ?? []).map((x) => x.id!),
        } as InternalBusinessCharacteristics;
      },
      mapToBusinessCharacteristicsResourceViewModel(value: InternalBusinessCharacteristics): BusinessCharacteristicsResourceViewModel {
        const objRetrun = new BusinessCharacteristicsResourceViewModel().fromModel({
          ownershipType: value.ownershipType ? [this.mapToBusinessCharacteristicViewModel(this.ownershipTypeItems.find((x) => x.id === value.ownershipType))] : [],
          operatingType: value.operatingType ? [this.mapToBusinessCharacteristicViewModel(this.operatingModeItems.find((x) => x.id === value.operatingType))] : [],
          operatingArea: value.operatingArea ? [this.mapToBusinessCharacteristicViewModel(this.operationAreaItems.find((x) => x.id === value.operatingArea))] : [],
          gastronomicType: value.gastronomicType && this.operationAreaItems.some((x) => x.id === value.operatingArea)
            ? [this.mapToBusinessCharacteristicViewModel(
              this.gastronomicTypeSelectionDictionary.get(
                this.operationAreaItems.find((x) => x.id === value.operatingArea)!.childKey!,
              )!.find((x) => x.id === value.gastronomicType),
            )] : [],
          wantsConsulting: value.wantsConsulting,
          kitchenStyle: this.mapToBusinessCharacteristicViewModelArray(this.kitchenStyleItems.filter((y) => value.kitchenStyle?.includes(y.id!))),
          accommodationType: this.mapToBusinessCharacteristicViewModelArray(this.accommodationTypeItems.filter((y) => value.accommodationType?.includes(y.id!))),
          expandedCateringOffers: this.mapToBusinessCharacteristicViewModelArray(this.expandedCateringOffersItems.filter((y) => value.expandedCateringOffers?.includes(y.id!))),
          restDays: this.mapToBusinessCharacteristicViewModelArray(this.restDaysItems.filter((y) => value.restDays?.includes(y.id!))),
          paymentMethods: this.mapToBusinessCharacteristicViewModelArray(this.paymentMethodsItems.filter((y) => value.paymentMethods?.includes(y.id!))),
          communicationChannels: this.mapToBusinessCharacteristicViewModelArray(this.communicationChannelsItems.filter((y) => value.communicationChannels?.includes(y.id!))),
          equipmentFeatures: this.mapToBusinessCharacteristicViewModelArray(this.equipmentFeaturesItems.filter((y) => value.equipmentFeatures?.includes(y.id!))),
        });
        return objRetrun;
      },
    },
  });
</script>
