// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetCampaignSyncTransformCrmContactProcessesRequestModel from '../../model/get-campaign-sync-transform-crm-contact-processes-request-model';
import ExecuteTransformCrmContactProcessRequestModel from '../../model/execute-transform-crm-contact-process-request-model';
import ProcessPendingIntegrationCallsRequestModel from '../../model/process-pending-integration-calls-request-model';
import UpdateContactIdMappingRequestModel from '../../model/update-contact-id-mapping-request-model';
import Int32LookupRequestListResultModel from '../../model/int32-lookup-request-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getCampaignSyncTransformCrmContactProcesses(request: GetCampaignSyncTransformCrmContactProcessesRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-campaign-sync-transform-crm-contact-processes'] ?? 'core/integration/Processes/CampaignSync/TransformCrmContact';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int32LookupRequestListResultModel.toModel(dto));
  },
  /**  */
  async executeTransformCrmContactProcess(request: ExecuteTransformCrmContactProcessRequestModel, config?: RequestConfig) {
    const requestDTO = ExecuteTransformCrmContactProcessRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-execute-transform-crm-contact-process'] ?? 'core/integration/Processes/CampaignSync/TransformCrmContact/{processName}';
    endpointPath = endpointPath.replace(/\{processName\}/g, `${requestDTO.processName}`);
    const response = await service.post<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async processPendingIntegrationCalls(request: ProcessPendingIntegrationCallsRequestModel, config?: RequestConfig) {
    const requestDTO = ProcessPendingIntegrationCallsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-process-pending-integration-calls'] ?? 'core/integration/Processes/ProcessPendingIntegrationCalls';
    const response = await service.post<any>(endpointPath, {
      body: {
        integrationTaskId: requestDTO.integrationTaskId,
        sourceAppSystemId: requestDTO.sourceAppSystemId,
        itemsToBeProcessed: requestDTO.itemsToBeProcessed,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async updateContactIdMapping(request: UpdateContactIdMappingRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateContactIdMappingRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-contact-id-mapping'] ?? 'core/integration/Processes/UpdateContactIdMapping/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.post<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
});
