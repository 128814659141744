// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/e-surance-membership-contact-schema',
  type: 'object',
  required: ['isCompany', 'firstName', 'lastName', 'companyName', 'street', 'houseNumber', 'zipCode', 'city', 'email'],
  properties: {
    isCompany: {
      type: 'boolean',
    },
    salutationKey: {
      type: 'integer',
      nullable: true,
    },
    salutationName: {
      type: 'string',
      nullable: true,
    },
    firstName: {
      type: 'string',
      minLength: 1,
    },
    lastName: {
      type: 'string',
      minLength: 1,
    },
    companyName: {
      type: 'string',
      minLength: 1,
    },
    street: {
      type: 'string',
      minLength: 1,
    },
    houseNumber: {
      type: 'string',
      minLength: 1,
    },
    zipCode: {
      type: 'string',
      minLength: 1,
    },
    city: {
      type: 'string',
      minLength: 1,
    },
    birthDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    email: {
      type: 'string',
      minLength: 1,
    },
  },
} as IJSONSchema;

export default schema;
