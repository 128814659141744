// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UpdateShopArticleTaxHistoryRequestModel from '../../model/update-shop-article-tax-history-request-model';
import UpdateShopArticleTaxHistoryRequestDTO from '../dto/update-shop-article-tax-history-request-dto';
import dtoSchema from '../schemas/update-shop-article-tax-history-request-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class UpdateShopArticleTaxHistoryRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<UpdateShopArticleTaxHistoryRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.shopArticleTaxId = data.shopArticleTaxId;
      this.taxRate = data.taxRate;
      this.validFrom = data.validFrom;
      this.validTo = data.validTo ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  shopArticleTaxId?: number;
  /**
  * @type {double}
  */
  taxRate?: number;
  /**
  * @type {date-time}
  */
  validFrom?: string;
  /**
  * @type {date-time}
  */
  validTo?: string;

  static toModel(dto: DeepPartial<UpdateShopArticleTaxHistoryRequestDTO>): UpdateShopArticleTaxHistoryRequestModel;
  static toModel(dto: DeepPartial<UpdateShopArticleTaxHistoryRequestDTO> | undefined | null): UpdateShopArticleTaxHistoryRequestModel | undefined;
  static toModel(dto: DeepPartial<UpdateShopArticleTaxHistoryRequestDTO> | undefined | null): UpdateShopArticleTaxHistoryRequestModel | undefined {
    return dto ? new UpdateShopArticleTaxHistoryRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<UpdateShopArticleTaxHistoryRequestModel> | UpdateShopArticleTaxHistoryRequestModel): UpdateShopArticleTaxHistoryRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      shopArticleTaxId: unwrapped.shopArticleTaxId,
      taxRate: unwrapped.taxRate,
      validFrom: unwrapped.validFrom,
      validTo: unwrapped.validTo,
    } as UpdateShopArticleTaxHistoryRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for UpdateShopArticleTaxHistoryRequestModel, can not map to UpdateShopArticleTaxHistoryRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
