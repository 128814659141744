// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import UserApiKeySettingsResourceViewModel from '../../../../viewModel/resource/UserApiKeySettingsResourceViewModel';
import UserApiKeySettingsResourceDTO from '../../dto/resource/UserApiKeySettingsResourceDTO';

export default abstract class UserApiKeySettingsResourceModel extends BaseModel<UserApiKeySettingsResourceDTO> {
  /**
  * @type {int32}
  */
  get apiKeyLength() { return this.dto.apiKeyLength; }
  set apiKeyLength(value) { this.dto.apiKeyLength = value; }
  /**
  */
  get apiKeyPrefix() { return this.dto.apiKeyPrefix; }
  set apiKeyPrefix(value) { this.dto.apiKeyPrefix = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.apikeylength);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.apiKeyLength = value;
    this.dto.apiKeyPrefix = qs.apikeyprefix;
  }
}
