// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ShopBasketModel from '../../model/shop-basket-model';
import ShopBasketDTO from '../dto/shop-basket-dto';
import dtoSchema from '../schemas/shop-basket-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import ShopBasketItemsListModel from '../../model/shop-basket-items-list-model';

export default abstract class ShopBasketModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ShopBasketDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.publicBasketId = data.publicBasketId;
      this.totalQuantity = data.totalQuantity;
      this.totalGrossPrice = data.totalGrossPrice;
      this.totalNetPrice = data.totalNetPrice;
      this.totalTaxAmount = data.totalTaxAmount;
      this.currency = data.currency ?? undefined;
      this.items = data.items?.map((i) => ShopBasketItemsListModel.toModel(i)) ?? [];
      this.language_ISO2 = data.language_ISO2 ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {uuid}
  */
  publicBasketId?: string;
  /**
  * @type {int32}
  */
  totalQuantity?: number;
  /**
  * @type {double}
  */
  totalGrossPrice?: number;
  /**
  * @type {double}
  */
  totalNetPrice?: number;
  /**
  * @type {double}
  */
  totalTaxAmount?: number;
  /**
  */
  currency?: string;
  /**
  */
  items?: ShopBasketItemsListModel[];
  /**
  */
  language_ISO2?: string;

  static toModel(dto: DeepPartial<ShopBasketDTO>): ShopBasketModel;
  static toModel(dto: DeepPartial<ShopBasketDTO> | undefined | null): ShopBasketModel | undefined;
  static toModel(dto: DeepPartial<ShopBasketDTO> | undefined | null): ShopBasketModel | undefined {
    return dto ? new ShopBasketModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ShopBasketModel> | ShopBasketModel): ShopBasketDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      publicBasketId: unwrapped.publicBasketId,
      totalQuantity: unwrapped.totalQuantity,
      totalGrossPrice: unwrapped.totalGrossPrice,
      totalNetPrice: unwrapped.totalNetPrice,
      totalTaxAmount: unwrapped.totalTaxAmount,
      currency: unwrapped.currency,
      items: unwrapped.items,
      language_ISO2: unwrapped.language_ISO2,
    } as ShopBasketDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ShopBasketModel, can not map to ShopBasketDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
