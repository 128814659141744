// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetShopArticleTaxesRequestModel from '../../model/get-shop-article-taxes-request-model';
import CreateShopArticleTaxRequestModel from '../../model/create-shop-article-tax-request-model';
import GetShopArticleTaxByIdRequestModel from '../../model/get-shop-article-tax-by-id-request-model';
import UpdateShopArticleTaxRequestModel from '../../model/update-shop-article-tax-request-model';
import DeleteShopArticleTaxByIdRequestModel from '../../model/delete-shop-article-tax-by-id-request-model';
import GetShopArticleTaxHistoryByIdRequestModel from '../../model/get-shop-article-tax-history-by-id-request-model';
import GetShopArticleTaxLookupRequestModel from '../../model/get-shop-article-tax-lookup-request-model';
import ShopArticleTaxListResultModel from '../../model/shop-article-tax-list-result-model';
import ShopArticleTaxModel from '../../model/shop-article-tax-model';
import ShopArticleTaxHistoryListResultModel from '../../model/shop-article-tax-history-list-result-model';
import Int64LookupListResultModel from '../../model/int64-lookup-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getShopArticleTaxes(request: GetShopArticleTaxesRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-article-taxes'] ?? 'ShopArticleTaxes';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopArticleTaxListResultModel.toModel(dto));
  },
  /**  */
  async createShopArticleTax(request: CreateShopArticleTaxRequestModel, config?: RequestConfig) {
    const requestDTO = CreateShopArticleTaxRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-shop-article-tax'] ?? 'ShopArticleTaxes';
    const response = await service.post<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        shortName: requestDTO.shortName,
        name: requestDTO.name,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getShopArticleTaxById(request: GetShopArticleTaxByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetShopArticleTaxByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-article-tax-by-id'] ?? 'ShopArticleTaxes/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopArticleTaxModel.toModel(dto));
  },
  /**  */
  async updateShopArticleTax(request: UpdateShopArticleTaxRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateShopArticleTaxRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-shop-article-tax'] ?? 'ShopArticleTaxes/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        shortName: requestDTO.shortName,
        name: requestDTO.name,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async deleteShopArticleTaxById(request: DeleteShopArticleTaxByIdRequestModel, config?: RequestConfig) {
    const requestDTO = DeleteShopArticleTaxByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-shop-article-tax-by-id'] ?? 'ShopArticleTaxes/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getShopArticleTaxHistoryById(request: GetShopArticleTaxHistoryByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetShopArticleTaxHistoryByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-article-tax-history-by-id'] ?? 'ShopArticleTaxes/{id}/History';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopArticleTaxHistoryListResultModel.toModel(dto));
  },
  /**  */
  async getShopArticleTaxLookup(request: GetShopArticleTaxLookupRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-article-tax-lookup'] ?? 'ShopArticleTaxes/Lookup';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupListResultModel.toModel(dto));
  },
});
