// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import OnlinePaymentContactAddressResourceViewModel from '../../../../viewModel/resource/OnlinePaymentContactAddressResourceViewModel';
import OnlinePaymentContactAddressResourceDTO from '../../dto/resource/OnlinePaymentContactAddressResourceDTO';

export default abstract class OnlinePaymentContactAddressResourceModel extends BaseModel<OnlinePaymentContactAddressResourceDTO> {
  /**
  */
  get salutation() { return this.dto.salutation; }
  set salutation(value) { this.dto.salutation = value; }
  /**
  */
  get firstName() { return this.dto.firstName; }
  set firstName(value) { this.dto.firstName = value; }
  /**
  */
  get lastName() { return this.dto.lastName; }
  set lastName(value) { this.dto.lastName = value; }
  /**
  */
  get email() { return this.dto.email; }
  set email(value) { this.dto.email = value; }
  /**
  */
  get phoneNumber() { return this.dto.phoneNumber; }
  set phoneNumber(value) { this.dto.phoneNumber = value; }
  /**
  */
  get mobilePhoneNumber() { return this.dto.mobilePhoneNumber; }
  set mobilePhoneNumber(value) { this.dto.mobilePhoneNumber = value; }
  /**
  * @type {date-time}
  */
  get dateOfBirth() { return this.dto.dateOfBirth; }
  set dateOfBirth(value) { this.dto.dateOfBirth = value; }
  /**
  */
  get companyName() { return this.dto.companyName; }
  set companyName(value) { this.dto.companyName = value; }
  /**
  */
  get street() { return this.dto.street; }
  set street(value) { this.dto.street = value; }
  /**
  */
  get countryISO() { return this.dto.countryISO; }
  set countryISO(value) { this.dto.countryISO = value; }
  /**
  */
  get zipCode() { return this.dto.zipCode; }
  set zipCode(value) { this.dto.zipCode = value; }
  /**
  */
  get city() { return this.dto.city; }
  set city(value) { this.dto.city = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.salutation = qs.salutation;
    this.dto.firstName = qs.firstname;
    this.dto.lastName = qs.lastname;
    this.dto.email = qs.email;
    this.dto.phoneNumber = qs.phonenumber;
    this.dto.mobilePhoneNumber = qs.mobilephonenumber;
    this.dto.dateOfBirth = qs.dateofbirth;
    this.dto.companyName = qs.companyname;
    this.dto.street = qs.street;
    this.dto.countryISO = qs.countryiso;
    this.dto.zipCode = qs.zipcode;
    this.dto.city = qs.city;
  }
}
