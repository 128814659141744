import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import AttributesApi from '../generated/api/Attributes';
import LookupAttributesRequestViewModel from '../viewModel/request/Attributes/LookupAttributesRequestViewModel';

export default (service: Servicelayer) => ({
  ...AttributesApi(service),

  async getSocialInsuranceTypes(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'SocialInsurance',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },
  async getLegalFormTypes(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'LegalForm',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },
  async getGastronomyTypes(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'Gastronomy',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },
  async getAccommodationTypes(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'Accommodation',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },
  async getOwnershipTypes(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'OwnershipType',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },

  async getServicedCatering(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'ServicedCatering',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },
  async getEntertainmentCatering(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'EntertainmentCatering',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },
  async getCommunityCatering(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'CommunityCatering',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },
  async getOtherCatering(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'OtherCatering',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },
  async getFastFoodCatering(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'FastFoodCatering',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },
  async getKitchenStyle(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'KitchenStyle',
      id: id?.toString(),
      'paging.CurrentPage': 1,
      'paging.PageSize': 100,
    });
    return this.lookupAttributes(options);
  },
  async getExpandedCateringOffers(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'ExpandedCateringOffers',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },
  async getRestDays(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'RestDays',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },
  async getCommunicationChannels(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'CommunicationChannels',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },
  async getPaymentMethods(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'PaymentMethods',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },
  async getEquipmentFeatures(id?: string | number) {
    const options = new LookupAttributesRequestViewModel().fromModel({
      key: 'EquipmentFeatures',
      id: id?.toString(),
    });
    return this.lookupAttributes(options);
  },
});
