// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import OnlinePaymentTransactionModel from '../../model/online-payment-transaction-model';
import OnlinePaymentTransactionDTO from '../dto/online-payment-transaction-dto';
import dtoSchema from '../schemas/online-payment-transaction-schema';
import OnlinePaymentContactAddressModel from '../../model/online-payment-contact-address-model';
import OnlinePaymentOrderItemModel from '../../model/online-payment-order-item-model';

export default abstract class OnlinePaymentTransactionModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<OnlinePaymentTransactionDTO>) {
    super();
    if (data) {
      this.id = data.id ?? undefined;
      this.merchantReference = data.merchantReference ?? undefined;
      this.currency = data.currency ?? undefined;
      this.totalPrice = data.totalPrice ?? undefined;
      this.language = data.language ?? undefined;
      this.billingAddress = data.billingAddress ? OnlinePaymentContactAddressModel.toModel(data.billingAddress) : undefined;
      this.shippingAddress = data.shippingAddress ? OnlinePaymentContactAddressModel.toModel(data.shippingAddress) : undefined;
      this.orderItems = data.orderItems?.map((i) => OnlinePaymentOrderItemModel.toModel(i)) ?? [];
      this.state = data.state;
      this.isRecurring = data.isRecurring;
      this.recurringOnlinePaymentTokenId = data.recurringOnlinePaymentTokenId ?? undefined;
      this.paymentConnectorId = data.paymentConnectorId ?? undefined;
      this.createdOn = data.createdOn ?? undefined;
      this.completedOn = data.completedOn ?? undefined;
      this.failedOn = data.failedOn ?? undefined;
      this.version = data.version ?? undefined;
    }
  }
  /**
  */
  id?: string;
  /**
  */
  merchantReference?: string;
  /**
  */
  currency?: string;
  /**
  * @type {double}
  */
  totalPrice?: number;
  /**
  */
  language?: string;
  /**
  */
  billingAddress?: OnlinePaymentContactAddressModel;
  /**
  */
  shippingAddress?: OnlinePaymentContactAddressModel;
  /**
  */
  orderItems?: OnlinePaymentOrderItemModel[];
  /**
  * @type {int32}
  */
  state?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9; // OnlinePaymentTransactionStateDTO
  /**
  */
  isRecurring?: boolean;
  /**
  */
  recurringOnlinePaymentTokenId?: string;
  /**
  */
  paymentConnectorId?: string;
  /**
  * @type {date-time}
  */
  createdOn?: string;
  /**
  * @type {date-time}
  */
  completedOn?: string;
  /**
  * @type {date-time}
  */
  failedOn?: string;
  /**
  * @type {int32}
  */
  version?: number;

  static toModel(dto: DeepPartial<OnlinePaymentTransactionDTO>): OnlinePaymentTransactionModel;
  static toModel(dto: DeepPartial<OnlinePaymentTransactionDTO> | undefined | null): OnlinePaymentTransactionModel | undefined;
  static toModel(dto: DeepPartial<OnlinePaymentTransactionDTO> | undefined | null): OnlinePaymentTransactionModel | undefined {
    return dto ? new OnlinePaymentTransactionModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<OnlinePaymentTransactionModel> | OnlinePaymentTransactionModel): OnlinePaymentTransactionDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      merchantReference: unwrapped.merchantReference,
      currency: unwrapped.currency,
      totalPrice: unwrapped.totalPrice,
      language: unwrapped.language,
      billingAddress: unwrapped.billingAddress,
      shippingAddress: unwrapped.shippingAddress,
      orderItems: unwrapped.orderItems,
      state: unwrapped.state,
      isRecurring: unwrapped.isRecurring,
      recurringOnlinePaymentTokenId: unwrapped.recurringOnlinePaymentTokenId,
      paymentConnectorId: unwrapped.paymentConnectorId,
      createdOn: unwrapped.createdOn,
      completedOn: unwrapped.completedOn,
      failedOn: unwrapped.failedOn,
      version: unwrapped.version,
    } as OnlinePaymentTransactionDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for OnlinePaymentTransactionModel, can not map to OnlinePaymentTransactionDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
