// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ShopCheckoutOrderModel from '../../model/shop-checkout-order-model';
import ShopCheckoutOrderDTO from '../dto/shop-checkout-order-dto';
import dtoSchema from '../schemas/shop-checkout-order-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import ShopInvoiceAddressModel from '../../model/shop-invoice-address-model';
import ShopDeliveryAddressModel from '../../model/shop-delivery-address-model';

export default abstract class ShopCheckoutOrderModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ShopCheckoutOrderDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.invoiceAddress = data.invoiceAddress ? ShopInvoiceAddressModel.toModel(data.invoiceAddress) : undefined;
      this.deliveryAddress = data.deliveryAddress ? ShopDeliveryAddressModel.toModel(data.deliveryAddress) : undefined;
      this.orderStateId = data.orderStateId;
      this.orderNumber = data.orderNumber ?? undefined;
      this.orderReference = data.orderReference ?? undefined;
      this.totalNetPrice = data.totalNetPrice;
      this.totalGrossPrice = data.totalGrossPrice;
      this.totalTaxAmount = data.totalTaxAmount;
      this.currency = data.currency ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  invoiceAddress?: ShopInvoiceAddressModel;
  /**
  */
  deliveryAddress?: ShopDeliveryAddressModel;
  /**
  * @type {int64}
  */
  orderStateId?: 1 | 2 | 3 | 4 | 5; // ShopOrderStateDTO
  /**
  */
  orderNumber?: string;
  /**
  */
  orderReference?: string;
  /**
  * @type {double}
  */
  totalNetPrice?: number;
  /**
  * @type {double}
  */
  totalGrossPrice?: number;
  /**
  * @type {double}
  */
  totalTaxAmount?: number;
  /**
  */
  currency?: string;

  static toModel(dto: DeepPartial<ShopCheckoutOrderDTO>): ShopCheckoutOrderModel;
  static toModel(dto: DeepPartial<ShopCheckoutOrderDTO> | undefined | null): ShopCheckoutOrderModel | undefined;
  static toModel(dto: DeepPartial<ShopCheckoutOrderDTO> | undefined | null): ShopCheckoutOrderModel | undefined {
    return dto ? new ShopCheckoutOrderModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ShopCheckoutOrderModel> | ShopCheckoutOrderModel): ShopCheckoutOrderDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      invoiceAddress: unwrapped.invoiceAddress,
      deliveryAddress: unwrapped.deliveryAddress,
      orderStateId: unwrapped.orderStateId,
      orderNumber: unwrapped.orderNumber,
      orderReference: unwrapped.orderReference,
      totalNetPrice: unwrapped.totalNetPrice,
      totalGrossPrice: unwrapped.totalGrossPrice,
      totalTaxAmount: unwrapped.totalTaxAmount,
      currency: unwrapped.currency,
    } as ShopCheckoutOrderDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ShopCheckoutOrderModel, can not map to ShopCheckoutOrderDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
