// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetBasketRequestViewModel from '../../viewModel/request/ShopBasket/GetBasketRequestViewModel';
import AddToBasketRequestViewModel from '../../viewModel/request/ShopBasket/AddToBasketRequestViewModel';
import GetMiniBasketRequestViewModel from '../../viewModel/request/ShopBasket/GetMiniBasketRequestViewModel';
import UpdateBasketItemRequestViewModel from '../../viewModel/request/ShopBasket/UpdateBasketItemRequestViewModel';
import RemoveFromBasketRequestViewModel from '../../viewModel/request/ShopBasket/RemoveFromBasketRequestViewModel';
import ShopBasketResourceViewModel from '../../viewModel/resource/ShopBasketResourceViewModel';
import AddShopBasketItemRequestViewModel from '../../viewModel/resource/AddShopBasketItemRequestViewModel';
import ShopMiniBasketResourceViewModel from '../../viewModel/resource/ShopMiniBasketResourceViewModel';
import UpdateShopBasketItemRequestViewModel from '../../viewModel/resource/UpdateShopBasketItemRequestViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getBasket(request: GetBasketRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-basket'] ?? 'ShopBasket';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
        publicBasketId: requestDTO.publicBasketId,
      },
      ...config,
    });
    const dto = response.data.result;
    return new ShopBasketResourceViewModel().fromDTO(dto);
  },
  /**  */
  async addToBasket(model: AddShopBasketItemRequestViewModel, request: AddToBasketRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-add-to-basket'] ?? 'ShopBasket/Items';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      query: {
        userId: requestDTO.userId,
        publicBasketId: requestDTO.publicBasketId,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as string | undefined;
  },
  /**  */
  async getMiniBasket(request: GetMiniBasketRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-mini-basket'] ?? 'ShopBasket/MiniBasket';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
        publicBasketId: requestDTO.publicBasketId,
      },
      ...config,
    });
    const dto = response.data.result;
    return new ShopMiniBasketResourceViewModel().fromDTO(dto);
  },
  /**  */
  async updateBasketItem(id: number, model: UpdateShopBasketItemRequestViewModel, request: UpdateBasketItemRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-basket-item'] ?? 'ShopBasket/Items/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.put<any>(endpointPath, {
      body: modelDTO,
      query: {
        userId: requestDTO.userId,
        publicBasketId: requestDTO.publicBasketId,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as string | undefined;
  },
  /**  */
  async removeFromBasket(id: number, request: RemoveFromBasketRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-remove-from-basket'] ?? 'ShopBasket/Items/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.delete<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
        publicBasketId: requestDTO.publicBasketId,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as string | undefined;
  },
});
