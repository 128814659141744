// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetSequenceNumberListRequestModel from '../../model/get-sequence-number-list-request-model';
import CreateSequenceNumberRequestModel from '../../model/create-sequence-number-request-model';
import UpdateSequenceNumberRequestModel from '../../model/update-sequence-number-request-model';
import DeleteSequenceNumberRequestModel from '../../model/delete-sequence-number-request-model';
import SequenceNumberListPagingResultModel from '../../model/sequence-number-list-paging-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getSequenceNumberList(request: GetSequenceNumberListRequestModel, config?: RequestConfig) {
    const requestDTO = GetSequenceNumberListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-sequence-number-list'] ?? 'core/Sequencenumbers';
    const response = await service.get<any>(endpointPath, {
      query: {
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(SequenceNumberListPagingResultModel.toModel(dto));
  },
  /**  */
  async createSequenceNumber(request: CreateSequenceNumberRequestModel, config?: RequestConfig) {
    const requestDTO = CreateSequenceNumberRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-sequence-number'] ?? 'core/Sequencenumbers';
    const response = await service.post<any>(endpointPath, {
      body: {
        name: requestDTO.name,
        startNumber: requestDTO.startNumber,
        increment: requestDTO.increment,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async updateSequenceNumber(request: UpdateSequenceNumberRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateSequenceNumberRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-sequence-number'] ?? 'core/Sequencenumbers/{id}/SetNextNumber/{nextNumber}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    endpointPath = endpointPath.replace(/\{nextNumber\}/g, `${requestDTO.nextNumber}`);
    const response = await service.put<any>(endpointPath, {
      ...config,
    });
  },
  /**  */
  async deleteSequenceNumber(request: DeleteSequenceNumberRequestModel, config?: RequestConfig) {
    const requestDTO = DeleteSequenceNumberRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-sequence-number'] ?? 'core/Sequencenumbers/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
  },
});
