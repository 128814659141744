// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetShopPaymentPagedListRequestModel from '../../model/get-shop-payment-paged-list-request-model';
import CreateShopPaymentRequestModel from '../../model/create-shop-payment-request-model';
import GetShopPaymentRequestModel from '../../model/get-shop-payment-request-model';
import UpdateShopPaymentRequestModel from '../../model/update-shop-payment-request-model';
import DeleteShopPaymentRequestModel from '../../model/delete-shop-payment-request-model';
import ShopPaymentPagingResultModel from '../../model/shop-payment-paging-result-model';
import ShopOrderModel from '../../model/shop-order-model';

export default (service: Servicelayer) => ({
  /**  */
  async getShopPaymentPagedList(request: GetShopPaymentPagedListRequestModel, config?: RequestConfig) {
    const requestDTO = GetShopPaymentPagedListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-payment-paged-list'] ?? 'ShopPayments';
    const response = await service.get<any>(endpointPath, {
      query: {
        amountFrom: requestDTO.amountFrom,
        amountTo: requestDTO.amountTo,
        dateFrom: requestDTO.dateFrom,
        dateTo: requestDTO.dateTo,
        paymentStatus: requestDTO.paymentStatus,
        paymentMethod: requestDTO.paymentMethod,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopPaymentPagingResultModel.toModel(dto));
  },
  /**  */
  async createShopPayment(request: CreateShopPaymentRequestModel, config?: RequestConfig) {
    const requestDTO = CreateShopPaymentRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-shop-payment'] ?? 'ShopPayments';
    const response = await service.post<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        shopOrderId: requestDTO.shopOrderId,
        paymentDate: requestDTO.paymentDate,
        paymentMethod: requestDTO.paymentMethod,
        onlinePaymentMethod: requestDTO.onlinePaymentMethod,
        currency: requestDTO.currency,
        amount: requestDTO.amount,
        transactionId: requestDTO.transactionId,
        paymentStatus: requestDTO.paymentStatus,
        paymentMessage: requestDTO.paymentMessage,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getShopPayment(request: GetShopPaymentRequestModel, config?: RequestConfig) {
    const requestDTO = GetShopPaymentRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-shop-payment'] ?? 'ShopPayments/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopOrderModel.toModel(dto));
  },
  /**  */
  async updateShopPayment(request: UpdateShopPaymentRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateShopPaymentRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-shop-payment'] ?? 'ShopPayments/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        shopOrderId: requestDTO.shopOrderId,
        paymentDate: requestDTO.paymentDate,
        paymentMethod: requestDTO.paymentMethod,
        onlinePaymentMethod: requestDTO.onlinePaymentMethod,
        currency: requestDTO.currency,
        amount: requestDTO.amount,
        transactionId: requestDTO.transactionId,
        paymentStatus: requestDTO.paymentStatus,
        paymentMessage: requestDTO.paymentMessage,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async deleteShopPayment(request: DeleteShopPaymentRequestModel, config?: RequestConfig) {
    const requestDTO = DeleteShopPaymentRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-shop-payment'] ?? 'ShopPayments/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
});
