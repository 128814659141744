// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/call-log-with-duration-schema',
  type: 'object',
  required: ['id', 'creatorId', 'responseDuration'],
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    creatorId: {
      type: 'integer',
    },
    creatorName: {
      type: 'string',
      nullable: true,
    },
    created: {
      type: 'string',
      format: 'date-time',
    },
    modifierId: {
      type: 'integer',
      nullable: true,
    },
    modifierName: {
      type: 'string',
      nullable: true,
    },
    modified: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    targetSystemName: {
      type: 'string',
      nullable: true,
    },
    correlationId: {
      type: 'string',
      nullable: true,
    },
    requestTime: {
      type: 'string',
      format: 'date-time',
    },
    requestType: {
      type: 'string',
      nullable: true,
    },
    requestUrl: {
      type: 'string',
      nullable: true,
    },
    requestPath: {
      type: 'string',
      nullable: true,
    },
    requestBody: {
      type: 'string',
      nullable: true,
    },
    responseTime: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    responseStatusCode: {
      type: 'integer',
      nullable: true,
    },
    responseBody: {
      type: 'string',
      nullable: true,
    },
    responseInfo: {
      type: 'string',
      nullable: true,
    },
    exceptionMessage: {
      type: 'string',
      nullable: true,
    },
    responseDuration: {
      type: 'number',
    },
  },
} as IJSONSchema;

export default schema;
