// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/user-schema',
  type: 'object',
  required: ['id', 'creatorId', 'appHostRoleId'],
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    creatorId: {
      type: 'integer',
    },
    creatorName: {
      type: 'string',
      nullable: true,
    },
    created: {
      type: 'string',
      format: 'date-time',
    },
    modifierId: {
      type: 'integer',
      nullable: true,
    },
    modifierName: {
      type: 'string',
      nullable: true,
    },
    modified: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    email: {
      type: 'string',
      nullable: true,
    },
    userName: {
      type: 'string',
      nullable: true,
    },
    gdprConsentDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    gdprConsentVersion: {
      type: 'integer',
      nullable: true,
    },
    verificationCodeSentOn: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    verificationCodeValidUntil: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    verifiedOn: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    statusId: {
      enum: [
        0,
        1,
        2,
        3,
      ],
    },
    appHostRoleId: {
      type: 'integer',
    },
    userLanguage: {
      type: 'string',
      nullable: true,
    },
    userCulture: {
      type: 'string',
      nullable: true,
    },
    registrationKey: {
      type: 'string',
      nullable: true,
    },
    canDelete: {
      type: 'boolean',
    },
    isDeleted: {
      type: 'boolean',
    },
  },
} as IJSONSchema;

export default schema;
