// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';
import ShopInvoiceAddressSchema from './shop-invoice-address-schema';
import ShopDeliveryAddressSchema from './shop-delivery-address-schema';

const schema = {
  $id: '/shop-checkout-order-schema',
  type: 'object',
  required: ['id', 'totalNetPrice', 'totalGrossPrice', 'totalTaxAmount'],
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    invoiceAddress: {
      ...ShopInvoiceAddressSchema,
    },
    deliveryAddress: {
      ...ShopDeliveryAddressSchema,
    },
    orderStateId: {
      enum: [
        1,
        2,
        3,
        4,
        5,
      ],
    },
    orderNumber: {
      type: 'string',
      nullable: true,
    },
    orderReference: {
      type: 'string',
      nullable: true,
    },
    totalNetPrice: {
      type: 'number',
    },
    totalGrossPrice: {
      type: 'number',
    },
    totalTaxAmount: {
      type: 'number',
    },
    currency: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
