// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import RequestSummaryItemResourceViewModel from '../../../../viewModel/resource/RequestSummaryItemResourceViewModel';
import RequestSummarySectionResourceViewModel from '../../../../viewModel/resource/RequestSummarySectionResourceViewModel';
import RequestSummarySectionResourceDTO from '../../dto/resource/RequestSummarySectionResourceDTO';

export default abstract class RequestSummarySectionResourceModel extends BaseModel<RequestSummarySectionResourceDTO> {
  /**
  */
  get title() { return this.dto.title; }
  set title(value) { this.dto.title = value; }
  /**
  */
  get items() { return this.getArrayModels(() => this.dto.items, RequestSummaryItemResourceViewModel)!; }
  set items(value) { this.setArrayModels(() => this.dto.items, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.title = qs.title;
  }
}
