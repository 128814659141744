// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import AddConsultingCaseRequestModel from '../../model/add-consulting-case-request-model';
import GetConsultingCasesByUserRequestModel from '../../model/get-consulting-cases-by-user-request-model';
import ConsultingCaseListListResultModel from '../../model/consulting-case-list-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async addConsultingCase(request: AddConsultingCaseRequestModel, config?: RequestConfig) {
    const requestDTO = AddConsultingCaseRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-add-consulting-case'] ?? 'ConsultingCases';
    const response = await service.post<any>(endpointPath, {
      body: {
        userId: requestDTO.userId,
        subject: requestDTO.subject,
        description: requestDTO.description,
        documents: requestDTO.documents,
        customerContactText: requestDTO.customerContactText,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getConsultingCasesByUser(request: GetConsultingCasesByUserRequestModel, config?: RequestConfig) {
    const requestDTO = GetConsultingCasesByUserRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-consulting-cases-by-user'] ?? 'ConsultingCases/{userId}';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${requestDTO.userId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ConsultingCaseListListResultModel.toModel(dto));
  },
});
