// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/create-dynamic-form-field-request-schema',
  type: 'object',
  required: [],
  nullable: true,
  properties: {
    dynamicFormFieldDefinitionId: {
      type: 'string',
      format: 'uuid',
    },
    value: {
      nullable: true,
    },
    groupFields: {
      type: 'array',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
