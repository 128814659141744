// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/shop-basket-items-list-schema',
  type: 'object',
  required: ['id', 'basketId', 'shopArticleId', 'shopArticleTaxId', 'taxRate', 'quantity', 'totalNetPrice', 'totalGrossPrice', 'totalTaxAmount', 'unitGrossPrice', 'unitNetPrice', 'taxAmount'],
  nullable: true,
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    basketId: {
      type: 'integer',
    },
    basketPublicId: {
      type: 'string',
      format: 'uuid',
      nullable: true,
    },
    shopArticleId: {
      type: 'integer',
    },
    shopArticleTaxId: {
      type: 'number',
    },
    taxRate: {
      type: 'number',
    },
    taxName: {
      type: 'string',
      nullable: true,
    },
    taxShortName: {
      type: 'string',
      nullable: true,
    },
    articleNumber: {
      type: 'string',
      nullable: true,
    },
    supplierId: {
      type: 'integer',
      nullable: true,
    },
    supplierArticleNumber: {
      type: 'string',
      nullable: true,
    },
    caption: {
      type: 'string',
      nullable: true,
    },
    mainPictureId: {
      type: 'integer',
      nullable: true,
    },
    quantity: {
      type: 'integer',
    },
    description: {
      type: 'string',
      nullable: true,
    },
    shortDescription: {
      type: 'string',
      nullable: true,
    },
    totalNetPrice: {
      type: 'number',
    },
    totalGrossPrice: {
      type: 'number',
    },
    totalTaxAmount: {
      type: 'number',
    },
    unitGrossPrice: {
      type: 'number',
    },
    unitNetPrice: {
      type: 'number',
    },
    taxAmount: {
      type: 'number',
    },
    hasShipping: {
      type: 'boolean',
    },
    isDeliveryCost: {
      type: 'boolean',
    },
    hasLicense: {
      type: 'boolean',
    },
    isDownload: {
      type: 'boolean',
    },
    digitalAssetId: {
      type: 'integer',
      nullable: true,
    },
    crmItemPriceNumber: {
      type: 'integer',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
