// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import BusinessCharacteristicViewModel from '../../../../viewModel/resource/BusinessCharacteristicViewModel';
import BusinessCharacteristicDTO from '../../dto/resource/BusinessCharacteristicDTO';

export default abstract class BusinessCharacteristicModel extends BaseModel<BusinessCharacteristicDTO> {
  /**
  * @type {uuid}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get name() { return this.dto.name; }
  set name(value) { this.dto.name = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.id = qs.id;
    this.dto.name = qs.name;
  }
}
