// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ShopCategoriesListModel from '../../model/shop-categories-list-model';
import ShopCategoriesListDTO from '../dto/shop-categories-list-dto';
import dtoSchema from '../schemas/shop-categories-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class ShopCategoriesListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ShopCategoriesListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.parentCategoryId = data.parentCategoryId ?? undefined;
      this.language_ISO2 = data.language_ISO2 ?? undefined;
      this.caption = data.caption ?? undefined;
      this.isDefault = data.isDefault;
      this.sort = data.sort;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  parentCategoryId?: number;
  /**
  */
  language_ISO2?: string;
  /**
  */
  caption?: string;
  /**
  */
  isDefault?: boolean;
  /**
  * @type {int32}
  */
  sort?: number;

  static toModel(dto: DeepPartial<ShopCategoriesListDTO>): ShopCategoriesListModel;
  static toModel(dto: DeepPartial<ShopCategoriesListDTO> | undefined | null): ShopCategoriesListModel | undefined;
  static toModel(dto: DeepPartial<ShopCategoriesListDTO> | undefined | null): ShopCategoriesListModel | undefined {
    return dto ? new ShopCategoriesListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ShopCategoriesListModel> | ShopCategoriesListModel): ShopCategoriesListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      parentCategoryId: unwrapped.parentCategoryId,
      language_ISO2: unwrapped.language_ISO2,
      caption: unwrapped.caption,
      isDefault: unwrapped.isDefault,
      sort: unwrapped.sort,
    } as ShopCategoriesListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ShopCategoriesListModel, can not map to ShopCategoriesListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
