// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import AddHotelClassificationRequestModel from '../../model/add-hotel-classification-request-model';
import AddHotelClassificationRequestDTO from '../dto/add-hotel-classification-request-dto';
import dtoSchema from '../schemas/add-hotel-classification-request-schema';
import ContactModel from '../../model/contact-model';
import DeliveryAddressModel from '../../model/delivery-address-model';
import RequestSummaryModel from '../../model/request-summary-model';

export default abstract class AddHotelClassificationRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<AddHotelClassificationRequestDTO>) {
    super();
    if (data) {
      this.userId = data.userId;
      this.businessName = data.businessName;
      this.languageIso2 = data.languageIso2;
      this.businessContactPersonFirstName = data.businessContactPersonFirstName ?? undefined;
      this.businessContactPersonLastName = data.businessContactPersonLastName ?? undefined;
      this.businessContactCeoSalutation = data.businessContactCeoSalutation;
      this.businessContactPersonSalutation = data.businessContactPersonSalutation;
      this.businessContactCeoLastName = data.businessContactCeoLastName ?? undefined;
      this.businessContactCeoFirstName = data.businessContactCeoFirstName ?? undefined;
      this.isBusinessContactReadonly = data.isBusinessContactReadonly;
      this.businessContact = data.businessContact ? ContactModel.toModel(data.businessContact) : undefined;
      this.isBillingContactReadonly = data.isBillingContactReadonly;
      this.billingContact = data.billingContact ? DeliveryAddressModel.toModel(data.billingContact) : undefined;
      this.classificationStars = data.classificationStars;
      this.accommodationTypeId = data.accommodationTypeId;
      this.accommodationTypeName = data.accommodationTypeName ?? undefined;
      this.isSeasonal = data.isSeasonal;
      this.countOfRooms = data.countOfRooms ?? undefined;
      this.webAddress = data.webAddress ?? undefined;
      this.countOfBeds = data.countOfBeds ?? undefined;
      this.summary = data.summary ? RequestSummaryModel.toModel(data.summary) : undefined;
      this.acceptAgb = data.acceptAgb;
    }
  }
  /**
  * @type {int64}
  */
  userId?: number;
  /**
  * @minimum 1
  */
  businessName?: string;
  /**
  * @minimum 1
  */
  languageIso2?: string;
  /**
  */
  businessContactPersonFirstName?: string;
  /**
  */
  businessContactPersonLastName?: string;
  /**
  * @type {int32}
  */
  businessContactCeoSalutation?: 0 | 1 | 2; // ContactSalutationTypeDTO
  /**
  * @type {int32}
  */
  businessContactPersonSalutation?: 0 | 1 | 2; // ContactSalutationTypeDTO
  /**
  */
  businessContactCeoLastName?: string;
  /**
  */
  businessContactCeoFirstName?: string;
  /**
  */
  isBusinessContactReadonly?: boolean;
  /**
  */
  businessContact?: ContactModel;
  /**
  */
  isBillingContactReadonly?: boolean;
  /**
  */
  billingContact?: DeliveryAddressModel;
  /**
  * @type {int32}
  */
  classificationStars?: number;
  /**
  * @type {uuid}
  */
  accommodationTypeId?: string;
  /**
  */
  accommodationTypeName?: string;
  /**
  */
  isSeasonal?: boolean;
  /**
  * @type {int32}
  */
  countOfRooms?: number;
  /**
  */
  webAddress?: string;
  /**
  * @type {int32}
  */
  countOfBeds?: number;
  /**
  */
  summary?: RequestSummaryModel;
  /**
  */
  acceptAgb?: boolean;

  static toModel(dto: DeepPartial<AddHotelClassificationRequestDTO>): AddHotelClassificationRequestModel;
  static toModel(dto: DeepPartial<AddHotelClassificationRequestDTO> | undefined | null): AddHotelClassificationRequestModel | undefined;
  static toModel(dto: DeepPartial<AddHotelClassificationRequestDTO> | undefined | null): AddHotelClassificationRequestModel | undefined {
    return dto ? new AddHotelClassificationRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<AddHotelClassificationRequestModel> | AddHotelClassificationRequestModel): AddHotelClassificationRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      userId: unwrapped.userId,
      businessName: unwrapped.businessName,
      languageIso2: unwrapped.languageIso2,
      businessContactPersonFirstName: unwrapped.businessContactPersonFirstName,
      businessContactPersonLastName: unwrapped.businessContactPersonLastName,
      businessContactCeoSalutation: unwrapped.businessContactCeoSalutation,
      businessContactPersonSalutation: unwrapped.businessContactPersonSalutation,
      businessContactCeoLastName: unwrapped.businessContactCeoLastName,
      businessContactCeoFirstName: unwrapped.businessContactCeoFirstName,
      isBusinessContactReadonly: unwrapped.isBusinessContactReadonly,
      businessContact: unwrapped.businessContact,
      isBillingContactReadonly: unwrapped.isBillingContactReadonly,
      billingContact: unwrapped.billingContact,
      classificationStars: unwrapped.classificationStars,
      accommodationTypeId: unwrapped.accommodationTypeId,
      accommodationTypeName: unwrapped.accommodationTypeName,
      isSeasonal: unwrapped.isSeasonal,
      countOfRooms: unwrapped.countOfRooms,
      webAddress: unwrapped.webAddress,
      countOfBeds: unwrapped.countOfBeds,
      summary: unwrapped.summary,
      acceptAgb: unwrapped.acceptAgb,
    } as AddHotelClassificationRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for AddHotelClassificationRequestModel, can not map to AddHotelClassificationRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
