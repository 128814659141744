// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetAppSystemLookupRequestViewModel from '../../viewModel/request/AppSystem/GetAppSystemLookupRequestViewModel';
import AppSystemResourceListResultViewModel from '../../viewModel/resource/AppSystemResourceListResultViewModel';
import Int64LookupResourcePagingResultViewModel from '../../viewModel/resource/Int64LookupResourcePagingResultViewModel';
import CampaignCustomFieldResourceListResultViewModel from '../../viewModel/resource/CampaignCustomFieldResourceListResultViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getAppSystem(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-app-system'] ?? 'core/integration/AppSystem';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new AppSystemResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async getAppSystemLookup(request: GetAppSystemLookupRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-app-system-lookup'] ?? 'core/integration/AppSystem/Lookup';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new Int64LookupResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getAppSystemCustomFields(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-app-system-custom-fields'] ?? 'core/integration/AppSystem/{id}/CustomFields';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new CampaignCustomFieldResourceListResultViewModel().fromDTO(dto);
  },
});
