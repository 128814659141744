// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ContactSalutationTypeResourceViewModel from '../../../../viewModel/resource/ContactSalutationTypeResourceViewModel';
import ContactResourceViewModel from '../../../../viewModel/resource/ContactResourceViewModel';
import DeliveryAddressResourceViewModel from '../../../../viewModel/resource/DeliveryAddressResourceViewModel';
import RequestSummaryResourceViewModel from '../../../../viewModel/resource/RequestSummaryResourceViewModel';
import CreateHotelClassificationRequestViewModel from '../../../../viewModel/resource/CreateHotelClassificationRequestViewModel';
import CreateHotelClassificationRequestDTO from '../../dto/resource/CreateHotelClassificationRequestDTO';

export default abstract class CreateHotelClassificationRequestModel extends BaseModel<CreateHotelClassificationRequestDTO> {
  /**
  * @type {int64}
  */
  get userId() { return this.dto.userId; }
  set userId(value) { this.dto.userId = value; }
  /**
  */
  get businessName() { return this.dto.businessName; }
  set businessName(value) { this.dto.businessName = value; }
  /**
  */
  get languageIso2() { return this.dto.languageIso2; }
  set languageIso2(value) { this.dto.languageIso2 = value; }
  /**
  */
  get businessContactPersonFirstName() { return this.dto.businessContactPersonFirstName; }
  set businessContactPersonFirstName(value) { this.dto.businessContactPersonFirstName = value; }
  /**
  */
  get businessContactPersonLastName() { return this.dto.businessContactPersonLastName; }
  set businessContactPersonLastName(value) { this.dto.businessContactPersonLastName = value; }
  /**
  * @type {int32}
  */
  get businessContactCeoSalutation() { return this.dto.businessContactCeoSalutation; }
  set businessContactCeoSalutation(value) { this.dto.businessContactCeoSalutation = value; }
  /**
  * @type {int32}
  */
  get businessContactPersonSalutation() { return this.dto.businessContactPersonSalutation; }
  set businessContactPersonSalutation(value) { this.dto.businessContactPersonSalutation = value; }
  /**
  */
  get businessContactCeoLastName() { return this.dto.businessContactCeoLastName; }
  set businessContactCeoLastName(value) { this.dto.businessContactCeoLastName = value; }
  /**
  */
  get businessContactCeoFirstName() { return this.dto.businessContactCeoFirstName; }
  set businessContactCeoFirstName(value) { this.dto.businessContactCeoFirstName = value; }
  /**
  */
  get isBusinessContactReadonly() { return this.dto.isBusinessContactReadonly; }
  set isBusinessContactReadonly(value) { this.dto.isBusinessContactReadonly = value; }
  /**
  */
  get businessContact() { return this.getObjectModel(() => this.dto.businessContact, ContactResourceViewModel)!; }
  set businessContact(value) { this.setObjectModel(() => this.dto.businessContact, value); }
  /**
  */
  get isBillingContactReadonly() { return this.dto.isBillingContactReadonly; }
  set isBillingContactReadonly(value) { this.dto.isBillingContactReadonly = value; }
  /**
  */
  get billingContact() { return this.getObjectModel(() => this.dto.billingContact, DeliveryAddressResourceViewModel)!; }
  set billingContact(value) { this.setObjectModel(() => this.dto.billingContact, value); }
  /**
  * @type {int32}
  */
  get classificationStars() { return this.dto.classificationStars; }
  set classificationStars(value) { this.dto.classificationStars = value; }
  /**
  * @type {uuid}
  */
  get accommodationTypeId() { return this.dto.accommodationTypeId; }
  set accommodationTypeId(value) { this.dto.accommodationTypeId = value; }
  /**
  */
  get accommodationTypeName() { return this.dto.accommodationTypeName; }
  set accommodationTypeName(value) { this.dto.accommodationTypeName = value; }
  /**
  */
  get isSeasonal() { return this.dto.isSeasonal; }
  set isSeasonal(value) { this.dto.isSeasonal = value; }
  /**
  * @type {int32}
  */
  get countOfRooms() { return this.dto.countOfRooms; }
  set countOfRooms(value) { this.dto.countOfRooms = value; }
  /**
  */
  get webAddress() { return this.dto.webAddress; }
  set webAddress(value) { this.dto.webAddress = value; }
  /**
  * @type {int32}
  */
  get countOfBeds() { return this.dto.countOfBeds; }
  set countOfBeds(value) { this.dto.countOfBeds = value; }
  /**
  */
  get summary() { return this.getObjectModel(() => this.dto.summary, RequestSummaryResourceViewModel)!; }
  set summary(value) { this.setObjectModel(() => this.dto.summary, value); }
  /**
  */
  get acceptAgb() { return this.dto.acceptAgb; }
  set acceptAgb(value) { this.dto.acceptAgb = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.userid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.userId = value;
    this.dto.businessName = qs.businessname;
    this.dto.languageIso2 = qs.languageiso2;
    this.dto.businessContactPersonFirstName = qs.businesscontactpersonfirstname;
    this.dto.businessContactPersonLastName = qs.businesscontactpersonlastname;
    value = Number.parseFloat(qs.businesscontactceosalutation);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.businessContactCeoSalutation = value;
    value = Number.parseFloat(qs.businesscontactpersonsalutation);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.businessContactPersonSalutation = value;
    this.dto.businessContactCeoLastName = qs.businesscontactceolastname;
    this.dto.businessContactCeoFirstName = qs.businesscontactceofirstname;
    value = qs.isBusinessContactReadonly === 'true' ? true : qs.isbusinesscontactreadonly;
    value = qs.isBusinessContactReadonly === 'false' ? false : undefined;
    this.dto.isBusinessContactReadonly = value;
    value = qs.isBillingContactReadonly === 'true' ? true : qs.isbillingcontactreadonly;
    value = qs.isBillingContactReadonly === 'false' ? false : undefined;
    this.dto.isBillingContactReadonly = value;
    value = Number.parseFloat(qs.classificationstars);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.classificationStars = value;
    this.dto.accommodationTypeId = qs.accommodationtypeid;
    this.dto.accommodationTypeName = qs.accommodationtypename;
    value = qs.isSeasonal === 'true' ? true : qs.isseasonal;
    value = qs.isSeasonal === 'false' ? false : undefined;
    this.dto.isSeasonal = value;
    value = Number.parseFloat(qs.countofrooms);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.countOfRooms = value;
    this.dto.webAddress = qs.webaddress;
    value = Number.parseFloat(qs.countofbeds);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.countOfBeds = value;
    value = qs.acceptAgb === 'true' ? true : qs.acceptagb;
    value = qs.acceptAgb === 'false' ? false : undefined;
    this.dto.acceptAgb = value;
  }
}
