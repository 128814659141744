// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ShopSalutationTypeResourceViewModel from '../../../../viewModel/resource/ShopSalutationTypeResourceViewModel';
import ShopInvoiceAddressResourceViewModel from '../../../../viewModel/resource/ShopInvoiceAddressResourceViewModel';
import ShopInvoiceAddressResourceDTO from '../../dto/resource/ShopInvoiceAddressResourceDTO';

export default abstract class ShopInvoiceAddressResourceModel extends BaseModel<ShopInvoiceAddressResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int32}
  */
  get salutation() { return this.dto.salutation; }
  set salutation(value) { this.dto.salutation = value; }
  /**
  */
  get company() { return this.dto.company; }
  set company(value) { this.dto.company = value; }
  /**
  */
  get firstName() { return this.dto.firstName; }
  set firstName(value) { this.dto.firstName = value; }
  /**
  */
  get lastName() { return this.dto.lastName; }
  set lastName(value) { this.dto.lastName = value; }
  /**
  */
  get street() { return this.dto.street; }
  set street(value) { this.dto.street = value; }
  /**
  */
  get houseNumber() { return this.dto.houseNumber; }
  set houseNumber(value) { this.dto.houseNumber = value; }
  /**
  */
  get poBox() { return this.dto.poBox; }
  set poBox(value) { this.dto.poBox = value; }
  /**
  */
  get additionalLine() { return this.dto.additionalLine; }
  set additionalLine(value) { this.dto.additionalLine = value; }
  /**
  */
  get postalCode() { return this.dto.postalCode; }
  set postalCode(value) { this.dto.postalCode = value; }
  /**
  */
  get city() { return this.dto.city; }
  set city(value) { this.dto.city = value; }
  /**
  */
  get countryIso2() { return this.dto.countryIso2; }
  set countryIso2(value) { this.dto.countryIso2 = value; }
  /**
  */
  get phone() { return this.dto.phone; }
  set phone(value) { this.dto.phone = value; }
  /**
  */
  get mobilePhone() { return this.dto.mobilePhone; }
  set mobilePhone(value) { this.dto.mobilePhone = value; }
  /**
  */
  get eMail() { return this.dto.eMail; }
  set eMail(value) { this.dto.eMail = value; }
  /**
  */
  get isReadonly() { return this.dto.isReadonly; }
  set isReadonly(value) { this.dto.isReadonly = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.salutation);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.salutation = value;
    this.dto.company = qs.company;
    this.dto.firstName = qs.firstname;
    this.dto.lastName = qs.lastname;
    this.dto.street = qs.street;
    this.dto.houseNumber = qs.housenumber;
    this.dto.poBox = qs.pobox;
    this.dto.additionalLine = qs.additionalline;
    this.dto.postalCode = qs.postalcode;
    this.dto.city = qs.city;
    this.dto.countryIso2 = qs.countryiso2;
    this.dto.phone = qs.phone;
    this.dto.mobilePhone = qs.mobilephone;
    this.dto.eMail = qs.email;
    value = qs.isReadonly === 'true' ? true : qs.isreadonly;
    value = qs.isReadonly === 'false' ? false : undefined;
    this.dto.isReadonly = value;
  }
}
