// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import BusinessUnitModel from '../../model/business-unit-model';
import BusinessUnitDTO from '../dto/business-unit-dto';
import dtoSchema from '../schemas/business-unit-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class BusinessUnitModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<BusinessUnitDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.number = data.number;
      this.name = data.name ?? undefined;
      this.unitName = data.unitName ?? undefined;
      this.isMembershipAdditionalInfoRequired = data.isMembershipAdditionalInfoRequired;
      this.isMembershipOnlinePaymentAllowed = data.isMembershipOnlinePaymentAllowed;
      this.isMembershipDirectRegistration = data.isMembershipDirectRegistration;
      this.emailAddress = data.emailAddress ?? undefined;
      this.stateCode = data.stateCode ?? undefined;
      this.phoneNumber = data.phoneNumber ?? undefined;
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int32}
  */
  number?: number;
  /**
  */
  name?: string;
  /**
  */
  unitName?: string;
  /**
  */
  isMembershipAdditionalInfoRequired?: boolean;
  /**
  */
  isMembershipOnlinePaymentAllowed?: boolean;
  /**
  */
  isMembershipDirectRegistration?: boolean;
  /**
  */
  emailAddress?: string;
  /**
  */
  stateCode?: string;
  /**
  */
  phoneNumber?: string;

  static toModel(dto: DeepPartial<BusinessUnitDTO>): BusinessUnitModel;
  static toModel(dto: DeepPartial<BusinessUnitDTO> | undefined | null): BusinessUnitModel | undefined;
  static toModel(dto: DeepPartial<BusinessUnitDTO> | undefined | null): BusinessUnitModel | undefined {
    return dto ? new BusinessUnitModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<BusinessUnitModel> | BusinessUnitModel): BusinessUnitDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      number: unwrapped.number,
      name: unwrapped.name,
      unitName: unwrapped.unitName,
      isMembershipAdditionalInfoRequired: unwrapped.isMembershipAdditionalInfoRequired,
      isMembershipOnlinePaymentAllowed: unwrapped.isMembershipOnlinePaymentAllowed,
      isMembershipDirectRegistration: unwrapped.isMembershipDirectRegistration,
      emailAddress: unwrapped.emailAddress,
      stateCode: unwrapped.stateCode,
      phoneNumber: unwrapped.phoneNumber,
    } as BusinessUnitDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for BusinessUnitModel, can not map to BusinessUnitDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
