import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import CreateSubscriptionContractRequestViewModel from '../viewModel/resource/CreateSubscriptionContractRequestViewModel';
import SubscriptionsApi from '../generated/api/Subscriptions';
import SubscriptionTypeResourceViewModel from '../viewModel/resource/SubscriptionTypeResourceViewModel';

export default (service: Servicelayer) => ({
  ...SubscriptionsApi(service),

  getHasGastroJournalSubscription() {
    const getSubscribeStatus = Vue.$auth.getClaim('gs-GastroJournal') ?? '0';
    return getSubscribeStatus === '3';
  },

  getHasGastroProfessionalSubscription() {
    const getSubscribeStatus = Vue.$auth.getClaim('gs-GastroProfessional') ?? '0';
    return getSubscribeStatus === '3';
  },

  async addGastroProfessionalSubscription(subscriptionModel: Omit<CreateSubscriptionContractRequestViewModel, 'userId'>) {
    const request = new CreateSubscriptionContractRequestViewModel().fromModel(subscriptionModel);
    const subscriptionType = 1 as SubscriptionTypeResourceViewModel;
    request.subscriptionType = subscriptionType;
    request.userId = Vue.$auth.user?.id!;
    const result = await this.addSubscription(subscriptionType, request);
    await Vue.$auth.refresh();
    return result;
  },

  async addGastroJournalSubscription(subscriptionModel: CreateSubscriptionContractRequestViewModel) {
    const request = new CreateSubscriptionContractRequestViewModel().fromModel(subscriptionModel);
    const subscriptionType = 2 as SubscriptionTypeResourceViewModel;
    request.subscriptionType = subscriptionType;
    request.userId = Vue.$auth.user?.id!;
    const result = await this.addSubscription(subscriptionType, request);
    await Vue.$auth.refresh();
    return result;
  },

  async getMyGastroJournalSubscriptions() {
    const user = await Vue.$auth.getUser();
    return this.getSubscriptionContract(2, user?.id!);
  },

  async getMyGastroProfessionalSubscriptions() {
    const user = await Vue.$auth.getUser();
    return this.getSubscriptionContract(1, user?.id!);
  },

  async getGastroJournalSubscriptionTypes() {
    return this.getSubscriptionArticles(2);
  },

  async getGastroProfessionalSubscriptionTypes() {
    return this.getSubscriptionArticles(1);
  },

  async getMyGastroJournalDetail() {
    const user = await Vue.$auth.getUser();
    return this.getSubscriptionContract(2, user?.id!);
  },

  async getMyGastroProfessionalDetail() {
    const user = await Vue.$auth.getUser();
    return this.getSubscriptionContract(1, user?.id!);
  },

  async getHasCombiSubscription() {
    const user = await Vue.$auth.getUser();
    const subscription = await this.getSubscriptionContract(SubscriptionTypeResourceViewModel.GastroJournal, user?.id!);
    return subscription.hasCombiSubscription ?? false;
  },

});
