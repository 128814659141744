// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetBasketRequestModel from '../../model/get-basket-request-model';
import AddToBasketRequestModel from '../../model/add-to-basket-request-model';
import GetMiniBasketRequestModel from '../../model/get-mini-basket-request-model';
import UpdateBasketItemRequestModel from '../../model/update-basket-item-request-model';
import RemoveFromBasketRequestModel from '../../model/remove-from-basket-request-model';
import ShopBasketModel from '../../model/shop-basket-model';
import ShopMiniBasketModel from '../../model/shop-mini-basket-model';

export default (service: Servicelayer) => ({
  /**  */
  async getBasket(request: GetBasketRequestModel, config?: RequestConfig) {
    const requestDTO = GetBasketRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-basket'] ?? 'ShopBasket';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
        publicBasketId: requestDTO.publicBasketId,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopBasketModel.toModel(dto));
  },
  /**  */
  async addToBasket(request: AddToBasketRequestModel, config?: RequestConfig) {
    const requestDTO = AddToBasketRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-add-to-basket'] ?? 'ShopBasket/Items';
    const response = await service.post<any>(endpointPath, {
      body: {
        articleId: requestDTO.articleId,
        quantity: requestDTO.quantity,
      },
      query: {
        userId: requestDTO.userId,
        publicBasketId: requestDTO.publicBasketId,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as string | undefined);
  },
  /**  */
  async getMiniBasket(request: GetMiniBasketRequestModel, config?: RequestConfig) {
    const requestDTO = GetMiniBasketRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-mini-basket'] ?? 'ShopBasket/MiniBasket';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
        publicBasketId: requestDTO.publicBasketId,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ShopMiniBasketModel.toModel(dto));
  },
  /**  */
  async updateBasketItem(request: UpdateBasketItemRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateBasketItemRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-basket-item'] ?? 'ShopBasket/Items/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        articleId: requestDTO.articleId,
        quantity: requestDTO.quantity,
      },
      query: {
        userId: requestDTO.userId,
        publicBasketId: requestDTO.publicBasketId,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as string | undefined);
  },
  /**  */
  async removeFromBasket(request: RemoveFromBasketRequestModel, config?: RequestConfig) {
    const requestDTO = RemoveFromBasketRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-remove-from-basket'] ?? 'ShopBasket/Items/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.delete<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
        publicBasketId: requestDTO.publicBasketId,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as string | undefined);
  },
});
