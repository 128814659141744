// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import BusinessCharacteristicViewModel from '../../../../viewModel/resource/BusinessCharacteristicViewModel';
import BusinessCharacteristicsResourceViewModel from '../../../../viewModel/resource/BusinessCharacteristicsResourceViewModel';
import BusinessCharacteristicsResourceDTO from '../../dto/resource/BusinessCharacteristicsResourceDTO';

export default abstract class BusinessCharacteristicsResourceModel extends BaseModel<BusinessCharacteristicsResourceDTO> {
  /**
  */
  get ownershipType() { return this.getArrayModels(() => this.dto.ownershipType, BusinessCharacteristicViewModel)!; }
  set ownershipType(value) { this.setArrayModels(() => this.dto.ownershipType, value); }
  /**
  */
  get operatingType() { return this.getArrayModels(() => this.dto.operatingType, BusinessCharacteristicViewModel)!; }
  set operatingType(value) { this.setArrayModels(() => this.dto.operatingType, value); }
  /**
  */
  get operatingArea() { return this.getArrayModels(() => this.dto.operatingArea, BusinessCharacteristicViewModel)!; }
  set operatingArea(value) { this.setArrayModels(() => this.dto.operatingArea, value); }
  /**
  */
  get gastronomicType() { return this.getArrayModels(() => this.dto.gastronomicType, BusinessCharacteristicViewModel)!; }
  set gastronomicType(value) { this.setArrayModels(() => this.dto.gastronomicType, value); }
  /**
  */
  get wantsConsulting() { return this.dto.wantsConsulting; }
  set wantsConsulting(value) { this.dto.wantsConsulting = value; }
  /**
  */
  get kitchenStyle() { return this.getArrayModels(() => this.dto.kitchenStyle, BusinessCharacteristicViewModel)!; }
  set kitchenStyle(value) { this.setArrayModels(() => this.dto.kitchenStyle, value); }
  /**
  */
  get accommodationType() { return this.getArrayModels(() => this.dto.accommodationType, BusinessCharacteristicViewModel)!; }
  set accommodationType(value) { this.setArrayModels(() => this.dto.accommodationType, value); }
  /**
  */
  get expandedCateringOffers() { return this.getArrayModels(() => this.dto.expandedCateringOffers, BusinessCharacteristicViewModel)!; }
  set expandedCateringOffers(value) { this.setArrayModels(() => this.dto.expandedCateringOffers, value); }
  /**
  */
  get restDays() { return this.getArrayModels(() => this.dto.restDays, BusinessCharacteristicViewModel)!; }
  set restDays(value) { this.setArrayModels(() => this.dto.restDays, value); }
  /**
  */
  get communicationChannels() { return this.getArrayModels(() => this.dto.communicationChannels, BusinessCharacteristicViewModel)!; }
  set communicationChannels(value) { this.setArrayModels(() => this.dto.communicationChannels, value); }
  /**
  */
  get paymentMethods() { return this.getArrayModels(() => this.dto.paymentMethods, BusinessCharacteristicViewModel)!; }
  set paymentMethods(value) { this.setArrayModels(() => this.dto.paymentMethods, value); }
  /**
  */
  get equipmentFeatures() { return this.getArrayModels(() => this.dto.equipmentFeatures, BusinessCharacteristicViewModel)!; }
  set equipmentFeatures(value) { this.setArrayModels(() => this.dto.equipmentFeatures, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = qs.wantsConsulting === 'true' ? true : qs.wantsconsulting;
    value = qs.wantsConsulting === 'false' ? false : undefined;
    this.dto.wantsConsulting = value;
  }
}
