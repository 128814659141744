// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CreateAppSystemWebApiRequestModel from '../../model/create-app-system-web-api-request-model';
import CreateAppSystemWebApiRequestDTO from '../dto/create-app-system-web-api-request-dto';
import dtoSchema from '../schemas/create-app-system-web-api-request-schema';

export default abstract class CreateAppSystemWebApiRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CreateAppSystemWebApiRequestDTO>) {
    super();
    if (data) {
      this.appSystemId = data.appSystemId;
      this.apiUrl = data.apiUrl ?? undefined;
      this.apiUser = data.apiUser ?? undefined;
      this.apiPassword = data.apiPassword ?? undefined;
      this.apiAuthenticationType = data.apiAuthenticationType ?? undefined;
      this.timeoutSeconds = data.timeoutSeconds;
      this.useProxy = data.useProxy;
      this.proxyUrl = data.proxyUrl ?? undefined;
      this.proxyUser = data.proxyUser ?? undefined;
      this.proxyPassword = data.proxyPassword ?? undefined;
      this.enableHttpsCertificateValidation = data.enableHttpsCertificateValidation;
      this.workspaceId = data.workspaceId ?? undefined;
      this.secret = data.secret ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  appSystemId?: number;
  /**
  */
  apiUrl?: string;
  /**
  */
  apiUser?: string;
  /**
  */
  apiPassword?: string;
  /**
  */
  apiAuthenticationType?: string;
  /**
  * @type {int32}
  */
  timeoutSeconds?: number;
  /**
  */
  useProxy?: boolean;
  /**
  */
  proxyUrl?: string;
  /**
  */
  proxyUser?: string;
  /**
  */
  proxyPassword?: string;
  /**
  */
  enableHttpsCertificateValidation?: boolean;
  /**
  */
  workspaceId?: string;
  /**
  */
  secret?: string;

  static toModel(dto: DeepPartial<CreateAppSystemWebApiRequestDTO>): CreateAppSystemWebApiRequestModel;
  static toModel(dto: DeepPartial<CreateAppSystemWebApiRequestDTO> | undefined | null): CreateAppSystemWebApiRequestModel | undefined;
  static toModel(dto: DeepPartial<CreateAppSystemWebApiRequestDTO> | undefined | null): CreateAppSystemWebApiRequestModel | undefined {
    return dto ? new CreateAppSystemWebApiRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CreateAppSystemWebApiRequestModel> | CreateAppSystemWebApiRequestModel): CreateAppSystemWebApiRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      appSystemId: unwrapped.appSystemId,
      apiUrl: unwrapped.apiUrl,
      apiUser: unwrapped.apiUser,
      apiPassword: unwrapped.apiPassword,
      apiAuthenticationType: unwrapped.apiAuthenticationType,
      timeoutSeconds: unwrapped.timeoutSeconds,
      useProxy: unwrapped.useProxy,
      proxyUrl: unwrapped.proxyUrl,
      proxyUser: unwrapped.proxyUser,
      proxyPassword: unwrapped.proxyPassword,
      enableHttpsCertificateValidation: unwrapped.enableHttpsCertificateValidation,
      workspaceId: unwrapped.workspaceId,
      secret: unwrapped.secret,
    } as CreateAppSystemWebApiRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CreateAppSystemWebApiRequestModel, can not map to CreateAppSystemWebApiRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
