// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import BasketItemResourceViewModel from '../../../../viewModel/resource/BasketItemResourceViewModel';
import MethodOfPaymentViewModel from '../../../../viewModel/resource/MethodOfPaymentViewModel';
import OnlinePaymentResourceViewModel from '../../../../viewModel/resource/OnlinePaymentResourceViewModel';
import BasketResourceViewModel from '../../../../viewModel/resource/BasketResourceViewModel';
import BasketResourceDTO from '../../dto/resource/BasketResourceDTO';

export default abstract class BasketResourceModel extends BaseModel<BasketResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int32}
  */
  get businessUnitNumber() { return this.dto.businessUnitNumber; }
  set businessUnitNumber(value) { this.dto.businessUnitNumber = value; }
  /**
  */
  get businessPostalCode() { return this.dto.businessPostalCode; }
  set businessPostalCode(value) { this.dto.businessPostalCode = value; }
  /**
  * @type {int32}
  */
  get membershipCategoryId() { return this.dto.membershipCategoryId; }
  set membershipCategoryId(value) { this.dto.membershipCategoryId = value; }
  /**
  * @type {uuid}
  */
  get membershipTemplateId() { return this.dto.membershipTemplateId; }
  set membershipTemplateId(value) { this.dto.membershipTemplateId = value; }
  /**
  * @type {double}
  */
  get grossTotal() { return this.dto.grossTotal; }
  set grossTotal(value) { this.dto.grossTotal = value; }
  /**
  */
  get currency() { return this.dto.currency; }
  set currency(value) { this.dto.currency = value; }
  /**
  */
  get items() { return this.getArrayModels(() => this.dto.items, BasketItemResourceViewModel)!; }
  set items(value) { this.setArrayModels(() => this.dto.items, value); }
  /**
  * @type {date-time}
  */
  get periodStart() { return this.dto.periodStart; }
  set periodStart(value) { this.dto.periodStart = value; }
  /**
  * @type {date-time}
  */
  get periodEnd() { return this.dto.periodEnd; }
  set periodEnd(value) { this.dto.periodEnd = value; }
  /**
  * @type {int32}
  */
  get paymentMethod() { return this.dto.paymentMethod; }
  set paymentMethod(value) { this.dto.paymentMethod = value; }
  /**
  */
  get onlinePayment() { return this.getObjectModel(() => this.dto.onlinePayment, OnlinePaymentResourceViewModel)!; }
  set onlinePayment(value) { this.setObjectModel(() => this.dto.onlinePayment, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.businessunitnumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.businessUnitNumber = value;
    this.dto.businessPostalCode = qs.businesspostalcode;
    value = Number.parseFloat(qs.membershipcategoryid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.membershipCategoryId = value;
    this.dto.membershipTemplateId = qs.membershiptemplateid;
    value = Number.parseFloat(qs.grosstotal);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.grossTotal = value;
    this.dto.currency = qs.currency;
    this.dto.periodStart = qs.periodstart;
    this.dto.periodEnd = qs.periodend;
    value = Number.parseFloat(qs.paymentmethod);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.paymentMethod = value;
  }
}
