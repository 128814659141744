// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import PaginationDataModel from '../../model/pagination-data-model';
import PaginationDataDTO from '../dto/pagination-data-dto';
import dtoSchema from '../schemas/pagination-data-schema';

export default abstract class PaginationDataModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<PaginationDataDTO>) {
    super();
    if (data) {
      this.totalCount = data.totalCount;
      this.pageSize = data.pageSize;
      this.currentPage = data.currentPage;
      this.totalPages = data.totalPages;
      this.previousPage = data.previousPage;
      this.nextPage = data.nextPage;
    }
  }
  /**
  * @type {int32}
  */
  totalCount?: number;
  /**
  * @type {int32}
  */
  pageSize?: number;
  /**
  * @type {int32}
  */
  currentPage?: number;
  /**
  * @readonly
  * @type {int32}
  */
  totalPages?: number;
  /**
  * @readonly
  */
  previousPage?: boolean;
  /**
  * @readonly
  */
  nextPage?: boolean;

  static toModel(dto: DeepPartial<PaginationDataDTO>): PaginationDataModel;
  static toModel(dto: DeepPartial<PaginationDataDTO> | undefined | null): PaginationDataModel | undefined;
  static toModel(dto: DeepPartial<PaginationDataDTO> | undefined | null): PaginationDataModel | undefined {
    return dto ? new PaginationDataModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<PaginationDataModel> | PaginationDataModel): PaginationDataDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      totalCount: unwrapped.totalCount,
      pageSize: unwrapped.pageSize,
      currentPage: unwrapped.currentPage,
      totalPages: unwrapped.totalPages,
      previousPage: unwrapped.previousPage,
      nextPage: unwrapped.nextPage,
    } as PaginationDataDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for PaginationDataModel, can not map to PaginationDataDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
