// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import RequestSummarySectionModel from '../../model/request-summary-section-model';
import RequestSummarySectionDTO from '../dto/request-summary-section-dto';
import dtoSchema from '../schemas/request-summary-section-schema';
import RequestSummaryItemModel from '../../model/request-summary-item-model';

export default abstract class RequestSummarySectionModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<RequestSummarySectionDTO>) {
    super();
    if (data) {
      this.title = data.title ?? undefined;
      this.items = data.items?.map((i) => RequestSummaryItemModel.toModel(i)) ?? [];
    }
  }
  /**
  */
  title?: string;
  /**
  */
  items?: RequestSummaryItemModel[];

  static toModel(dto: DeepPartial<RequestSummarySectionDTO>): RequestSummarySectionModel;
  static toModel(dto: DeepPartial<RequestSummarySectionDTO> | undefined | null): RequestSummarySectionModel | undefined;
  static toModel(dto: DeepPartial<RequestSummarySectionDTO> | undefined | null): RequestSummarySectionModel | undefined {
    return dto ? new RequestSummarySectionModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<RequestSummarySectionModel> | RequestSummarySectionModel): RequestSummarySectionDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      title: unwrapped.title,
      items: unwrapped.items,
    } as RequestSummarySectionDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for RequestSummarySectionModel, can not map to RequestSummarySectionDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
