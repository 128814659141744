// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import OnlinePaymentContactAddressSchema from './online-payment-contact-address-schema';
import OnlinePaymentOrderItemSchema from './online-payment-order-item-schema';

const schema = {
  $id: '/update-transaction-request-schema',
  type: 'object',
  required: [],
  properties: {
    id: {
      type: 'string',
      nullable: true,
    },
    merchantReference: {
      type: 'string',
      nullable: true,
    },
    currency: {
      type: 'string',
      nullable: true,
    },
    totalPrice: {
      type: 'number',
      nullable: true,
    },
    language: {
      type: 'string',
      nullable: true,
    },
    billingAddress: {
      ...OnlinePaymentContactAddressSchema,
    },
    shippingAddress: {
      ...OnlinePaymentContactAddressSchema,
    },
    orderItems: {
      type: 'array',
      nullable: true,
      items: { ...OnlinePaymentOrderItemSchema },
    },
    state: {
      enum: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
      ],
    },
    isRecurring: {
      type: 'boolean',
    },
    recurringOnlinePaymentTokenId: {
      type: 'string',
      nullable: true,
    },
    paymentConnectorId: {
      type: 'string',
      nullable: true,
    },
    createdOn: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    completedOn: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    failedOn: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    version: {
      type: 'integer',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
