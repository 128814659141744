// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/get-shop-payment-paged-list-request-schema',
  type: 'object',
  required: [],
  properties: {
    amountFrom: {
      type: 'number',
    },
    amountTo: {
      type: 'number',
    },
    dateFrom: {
      type: 'string',
      format: 'date-time',
    },
    dateTo: {
      type: 'string',
      format: 'date-time',
    },
    paymentStatus: {
      enum: [
        1,
        2,
        3,
      ],
    },
    paymentMethod: {
      enum: [
        1,
        2,
      ],
    },
    searchCriteria: {
      type: 'string',
    },
    'paging.CurrentPage': {
      type: 'integer',
    },
    'paging.PageSize': {
      type: 'integer',
    },
    'paging.IncludeCount': {
      type: 'boolean',
    },
    'paging.NoPaging': {
      type: 'boolean',
    },
  },
} as IJSONSchema;

export default schema;
