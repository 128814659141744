// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import RefreshTokenRequestModel from '../../model/refresh-token-request-model';
import RefreshTokenRequestDTO from '../dto/refresh-token-request-dto';
import dtoSchema from '../schemas/refresh-token-request-schema';

export default abstract class RefreshTokenRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<RefreshTokenRequestDTO>) {
    super();
    if (data) {
      this.accessToken = data.accessToken;
      this.refreshToken = data.refreshToken;
    }
  }
  /**
  * @minimum 1
  */
  accessToken?: string;
  /**
  * @minimum 1
  */
  refreshToken?: string;

  static toModel(dto: DeepPartial<RefreshTokenRequestDTO>): RefreshTokenRequestModel;
  static toModel(dto: DeepPartial<RefreshTokenRequestDTO> | undefined | null): RefreshTokenRequestModel | undefined;
  static toModel(dto: DeepPartial<RefreshTokenRequestDTO> | undefined | null): RefreshTokenRequestModel | undefined {
    return dto ? new RefreshTokenRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<RefreshTokenRequestModel> | RefreshTokenRequestModel): RefreshTokenRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      accessToken: unwrapped.accessToken,
      refreshToken: unwrapped.refreshToken,
    } as RefreshTokenRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for RefreshTokenRequestModel, can not map to RefreshTokenRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
