// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import LookupSocialInsuranceTypesRequestModel from '../../model/lookup-social-insurance-types-request-model';
import LookupAccommodationTypesRequestModel from '../../model/lookup-accommodation-types-request-model';
import LookupGastronomyTypesRequestModel from '../../model/lookup-gastronomy-types-request-model';
import CreateESuranceMembershipRequestModel from '../../model/create-e-surance-membership-request-model';
import GuidLookupPagingResultModel from '../../model/guid-lookup-paging-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async lookupSocialInsuranceTypes(request: LookupSocialInsuranceTypesRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-social-insurance-types'] ?? 'ESurance/SocialInsuranceTypes';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(GuidLookupPagingResultModel.toModel(dto));
  },
  /**  */
  async lookupAccommodationTypes(request: LookupAccommodationTypesRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-accommodation-types'] ?? 'ESurance/AccommodationTypes';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(GuidLookupPagingResultModel.toModel(dto));
  },
  /**  */
  async lookupGastronomyTypes(request: LookupGastronomyTypesRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-gastronomy-types'] ?? 'ESurance/GastronomyTypes';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(GuidLookupPagingResultModel.toModel(dto));
  },
  /**  */
  async createESuranceMembership(request: CreateESuranceMembershipRequestModel, config?: RequestConfig) {
    const requestDTO = CreateESuranceMembershipRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-e-surance-membership'] ?? 'ESurance/Membership';
    const response = await service.post<any>(endpointPath, {
      body: {
        membership: requestDTO.membership,
        membershipContact: requestDTO.membershipContact,
        membershipBusiness: requestDTO.membershipBusiness,
        invoiceAddress: requestDTO.invoiceAddress,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
});
