// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import AppSystemSchema from './app-system-schema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/app-system-list-result-schema',
  type: 'object',
  required: [],
  properties: {
    items: {
      type: 'array',
      nullable: true,
      items: { ...AppSystemSchema },
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
  },
} as IJSONSchema;

export default schema;
