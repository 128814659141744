<template>
  <div class="gs-basket-item">
    <p-card :height="120" :horizontal="$media.isDesktop">
      <gs-article-image
        contain
        :height="$media.isDesktop ? 120 : 175"
        :width="$media.isDesktop ? 140 : undefined"
        :image-id="value.articleNumber"
        :to="(disabled || readonly) ? undefined : articleRoute"
      ></gs-article-image>
      <p-row no-gutters :column="!$media.isDesktop">
        <p-col md12 lg6>
          <p-row column dense>
            <p-conditional-wrapper :show="!disabled && !readonly">
              <router-link tag="div" class="hover-effect" :to="articleRoute">
                <h4>
                  {{ value.caption }}
                </h4>
              </router-link>
            </p-conditional-wrapper>
            <span class="caption-1">
              {{ $t('shop.articleNumberShort') }}: {{ value.articleNumber }}
            </span>
          </p-row>
        </p-col>
        <p-col>
          <p-row>
            <p-col md12 lg8 xl9>
              <p-row nowrap justify-end no-gutters>
                <p-col v-if="readonly || disabled" justify-end>
                  <span class="caption-1 mt-1">
                    {{ quantity }} {{ $t('shop.basket.piecesPostfix') }}
                  </span>
                </p-col>
                <template v-else>
                  <p-col justify-end>
                    <p-number-field
                      v-model="quantity"
                      solo
                      :disabled="disabled"
                      :errors="errorChangeQuantity"
                      class="ix-4"
                      :min="1"
                      :loading="isLoading"
                      :label="$t('shop.basket.quantity')"
                    ></p-number-field>
                  </p-col>
                  <p-col shrink>
                    <gs-remove-from-basket class="ml-3" :disabled="disabled" :article-id="value.shopArticleId"></gs-remove-from-basket>
                  </p-col>
                </template>
              </p-row>
            </p-col>
            <p-col md12 lg4 xl3 justify-end>
              <gs-price-display :value="value"></gs-price-display>
            </p-col>
          </p-row>
        </p-col>
      </p-row>
    </p-card>
  </div>
</template>

<script lang="ts">
  import ShopBasketItemsListResourceViewModel from '../../services/v1/viewModel/resource/ShopBasketItemsListResourceViewModel';
  import UpdateShopBasketItemRequestViewModel from '../../services/v1/viewModel/resource/UpdateShopBasketItemRequestViewModel';
  import Vue, { PropType } from 'vue';

  export default Vue.extend({
    name: 'GsBasketItem',
    props: {
      value: { type: Object as PropType<ShopBasketItemsListResourceViewModel>, default: () => new ShopBasketItemsListResourceViewModel() },
      disabled: { type: Boolean, default: false },
      readonly: { type: Boolean, default: false },
    },
    data: () => ({
      quantity: 0,
      errorChangeQuantity: undefined as any,
      isLoading: false,
    }),
    computed: {
      articleRoute() {
        return `/gs/shop/articles/${this.value.shopArticleId}`;
      },
    },
    watch: {
      'value.quantity': {
        immediate: true,
        handler() {
          this.quantity = this.value.quantity ?? 0;
        },
      },
      quantity() {
        this.$debounce(() => {
          this.changeQuantity(this.quantity);
        }, 600, this)();
      },
    },
    methods: {
      async changeQuantity(quantity: number) {
        this.isLoading = true;
        try {
          const model = new UpdateShopBasketItemRequestViewModel();
          model.articleId = this.value.shopArticleId!;
          model.quantity = quantity;

          await this.$service.api.shopBasket.updateBasketItem(model);
          this.$emit('input', new ShopBasketItemsListResourceViewModel().fromDTO({ ...this.value.toDTO(), quantity }));
        } catch (error: any) {
          this.errorChangeQuantity = error?.message ?? error;
        }
        this.isLoading = false;
      },
    },
  });
</script>
