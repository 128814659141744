// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import AppLogResourceViewModel from '../../../../viewModel/resource/AppLogResourceViewModel';
import AppLogResourceDTO from '../../dto/resource/AppLogResourceDTO';

export default abstract class AppLogResourceModel extends BaseModel<AppLogResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get message() { return this.dto.message; }
  set message(value) { this.dto.message = value; }
  /**
  */
  get level() { return this.dto.level; }
  set level(value) { this.dto.level = value; }
  /**
  */
  get exception() { return this.dto.exception; }
  set exception(value) { this.dto.exception = value; }
  /**
  * @type {date-time}
  */
  get timeStamp() { return this.dto.timeStamp; }
  set timeStamp(value) { this.dto.timeStamp = value; }
  /**
  */
  get correlationId() { return this.dto.correlationId; }
  set correlationId(value) { this.dto.correlationId = value; }
  /**
  */
  get appTransactionId() { return this.dto.appTransactionId; }
  set appTransactionId(value) { this.dto.appTransactionId = value; }
  /**
  * @type {int64}
  */
  get appUserId() { return this.dto.appUserId; }
  set appUserId(value) { this.dto.appUserId = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    this.dto.message = qs.message;
    this.dto.level = qs.level;
    this.dto.exception = qs.exception;
    this.dto.timeStamp = qs.timestamp;
    this.dto.correlationId = qs.correlationid;
    this.dto.appTransactionId = qs.apptransactionid;
    value = Number.parseFloat(qs.appuserid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.appUserId = value;
  }
}
