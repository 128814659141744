// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UpdateShopOrderItemRequestModel from '../../model/update-shop-order-item-request-model';
import UpdateShopOrderItemRequestDTO from '../dto/update-shop-order-item-request-dto';
import dtoSchema from '../schemas/update-shop-order-item-request-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class UpdateShopOrderItemRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<UpdateShopOrderItemRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.shopOrderId = data.shopOrderId;
      this.shopArticleId = data.shopArticleId;
      this.shopArticleNumber = data.shopArticleNumber ?? undefined;
      this.caption = data.caption ?? undefined;
      this.shopSupplierId = data.shopSupplierId ?? undefined;
      this.shopSupplierArticleNumber = data.shopSupplierArticleNumber ?? undefined;
      this.isDelivery = data.isDelivery;
      this.hasLicense = data.hasLicense;
      this.isDownload = data.isDownload;
      this.digitalAssetId = data.digitalAssetId ?? undefined;
      this.quantity = data.quantity;
      this.unitNetPrice = data.unitNetPrice;
      this.unitGrossPrice = data.unitGrossPrice;
      this.taxRate = data.taxRate;
      this.taxAmount = data.taxAmount;
      this.totalNetPrice = data.totalNetPrice;
      this.totalGrossPrice = data.totalGrossPrice;
      this.crmItemPriceNumber = data.crmItemPriceNumber;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  shopOrderId?: number;
  /**
  * @type {int64}
  */
  shopArticleId?: number;
  /**
  */
  shopArticleNumber?: string;
  /**
  */
  caption?: string;
  /**
  * @type {int64}
  */
  shopSupplierId?: number;
  /**
  */
  shopSupplierArticleNumber?: string;
  /**
  */
  isDelivery?: boolean;
  /**
  */
  hasLicense?: boolean;
  /**
  */
  isDownload?: boolean;
  /**
  * @type {int64}
  */
  digitalAssetId?: number;
  /**
  * @type {int32}
  */
  quantity?: number;
  /**
  * @type {double}
  */
  unitNetPrice?: number;
  /**
  * @type {double}
  */
  unitGrossPrice?: number;
  /**
  * @type {double}
  */
  taxRate?: number;
  /**
  * @type {double}
  */
  taxAmount?: number;
  /**
  * @type {double}
  */
  totalNetPrice?: number;
  /**
  * @type {double}
  */
  totalGrossPrice?: number;
  /**
  * @type {int64}
  */
  crmItemPriceNumber?: number;

  static toModel(dto: DeepPartial<UpdateShopOrderItemRequestDTO>): UpdateShopOrderItemRequestModel;
  static toModel(dto: DeepPartial<UpdateShopOrderItemRequestDTO> | undefined | null): UpdateShopOrderItemRequestModel | undefined;
  static toModel(dto: DeepPartial<UpdateShopOrderItemRequestDTO> | undefined | null): UpdateShopOrderItemRequestModel | undefined {
    return dto ? new UpdateShopOrderItemRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<UpdateShopOrderItemRequestModel> | UpdateShopOrderItemRequestModel): UpdateShopOrderItemRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      shopOrderId: unwrapped.shopOrderId,
      shopArticleId: unwrapped.shopArticleId,
      shopArticleNumber: unwrapped.shopArticleNumber,
      caption: unwrapped.caption,
      shopSupplierId: unwrapped.shopSupplierId,
      shopSupplierArticleNumber: unwrapped.shopSupplierArticleNumber,
      isDelivery: unwrapped.isDelivery,
      hasLicense: unwrapped.hasLicense,
      isDownload: unwrapped.isDownload,
      digitalAssetId: unwrapped.digitalAssetId,
      quantity: unwrapped.quantity,
      unitNetPrice: unwrapped.unitNetPrice,
      unitGrossPrice: unwrapped.unitGrossPrice,
      taxRate: unwrapped.taxRate,
      taxAmount: unwrapped.taxAmount,
      totalNetPrice: unwrapped.totalNetPrice,
      totalGrossPrice: unwrapped.totalGrossPrice,
      crmItemPriceNumber: unwrapped.crmItemPriceNumber,
    } as UpdateShopOrderItemRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for UpdateShopOrderItemRequestModel, can not map to UpdateShopOrderItemRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
