// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CreateSubscriptionContractRequestModel from '../../model/create-subscription-contract-request-model';
import CreateSubscriptionContractRequestDTO from '../dto/create-subscription-contract-request-dto';
import dtoSchema from '../schemas/create-subscription-contract-request-schema';
import ContactModel from '../../model/contact-model';
import DeliveryAddressModel from '../../model/delivery-address-model';
import BasketModel from '../../model/basket-model';
import RequestSummaryModel from '../../model/request-summary-model';

export default abstract class CreateSubscriptionContractRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CreateSubscriptionContractRequestDTO>) {
    super();
    if (data) {
      this.userId = data.userId;
      this.subscriptionType = data.subscriptionType;
      this.subscriptionTemplateNumber = data.subscriptionTemplateNumber;
      this.entryDate = data.entryDate ?? undefined;
      this.isBillingContactReadonly = data.isBillingContactReadonly;
      this.billingContact = data.billingContact ? ContactModel.toModel(data.billingContact) : undefined;
      this.isDeliveryContactReadonly = data.isDeliveryContactReadonly;
      this.deliveryContact = data.deliveryContact ? DeliveryAddressModel.toModel(data.deliveryContact) : undefined;
      this.orderBasket = data.orderBasket ? BasketModel.toModel(data.orderBasket) : undefined;
      this.summary = data.summary ? RequestSummaryModel.toModel(data.summary) : undefined;
      this.newsletter = data.newsletter;
      this.languageIso2 = data.languageIso2 ?? undefined;
      this.acceptGdpr = data.acceptGdpr;
      this.acceptAgb = data.acceptAgb;
    }
  }
  /**
  * @type {int64}
  */
  userId?: number;
  /**
  * @type {int32}
  */
  subscriptionType?: 1 | 2; // SubscriptionTypeDTO
  /**
  * @type {int32}
  */
  subscriptionTemplateNumber?: number;
  /**
  * @type {date-time}
  */
  entryDate?: string;
  /**
  */
  isBillingContactReadonly?: boolean;
  /**
  */
  billingContact?: ContactModel;
  /**
  */
  isDeliveryContactReadonly?: boolean;
  /**
  */
  deliveryContact?: DeliveryAddressModel;
  /**
  */
  orderBasket?: BasketModel;
  /**
  */
  summary?: RequestSummaryModel;
  /**
  */
  newsletter?: boolean;
  /**
  */
  languageIso2?: string;
  /**
  */
  acceptGdpr?: boolean;
  /**
  */
  acceptAgb?: boolean;

  static toModel(dto: DeepPartial<CreateSubscriptionContractRequestDTO>): CreateSubscriptionContractRequestModel;
  static toModel(dto: DeepPartial<CreateSubscriptionContractRequestDTO> | undefined | null): CreateSubscriptionContractRequestModel | undefined;
  static toModel(dto: DeepPartial<CreateSubscriptionContractRequestDTO> | undefined | null): CreateSubscriptionContractRequestModel | undefined {
    return dto ? new CreateSubscriptionContractRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CreateSubscriptionContractRequestModel> | CreateSubscriptionContractRequestModel): CreateSubscriptionContractRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      userId: unwrapped.userId,
      subscriptionType: unwrapped.subscriptionType,
      subscriptionTemplateNumber: unwrapped.subscriptionTemplateNumber,
      entryDate: unwrapped.entryDate,
      isBillingContactReadonly: unwrapped.isBillingContactReadonly,
      billingContact: unwrapped.billingContact,
      isDeliveryContactReadonly: unwrapped.isDeliveryContactReadonly,
      deliveryContact: unwrapped.deliveryContact,
      orderBasket: unwrapped.orderBasket,
      summary: unwrapped.summary,
      newsletter: unwrapped.newsletter,
      languageIso2: unwrapped.languageIso2,
      acceptGdpr: unwrapped.acceptGdpr,
      acceptAgb: unwrapped.acceptAgb,
    } as CreateSubscriptionContractRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CreateSubscriptionContractRequestModel, can not map to CreateSubscriptionContractRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
