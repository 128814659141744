// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/online-payment-order-item-schema',
  type: 'object',
  required: [],
  nullable: true,
  properties: {
    productId: {
      type: 'string',
      nullable: true,
    },
    sku: {
      type: 'string',
      nullable: true,
    },
    name: {
      type: 'string',
      nullable: true,
    },
    unitPrice: {
      type: 'number',
      nullable: true,
    },
    discountFraction: {
      type: 'number',
      nullable: true,
    },
    quantity: {
      type: 'number',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
