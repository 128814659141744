// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetUserContactRequestModel from '../../model/get-user-contact-request-model';
import RegisterRequestModel from '../../model/register-request-model';
import ForgotPasswordRequestModel from '../../model/forgot-password-request-model';
import ChangePasswordRequestModel from '../../model/change-password-request-model';
import SetPasswordForUserRequestModel from '../../model/set-password-for-user-request-model';
import GetUsersRequestModel from '../../model/get-users-request-model';
import GetUserByIdRequestModel from '../../model/get-user-by-id-request-model';
import UpdateUserRequestModel from '../../model/update-user-request-model';
import DeleteUserRequestModel from '../../model/delete-user-request-model';
import SetUserStatusRequestModel from '../../model/set-user-status-request-model';
import SetUserAppRoleRequestModel from '../../model/set-user-app-role-request-model';
import RestoreUserRequestModel from '../../model/restore-user-request-model';
import ContactModel from '../../model/contact-model';
import UserPagingResultModel from '../../model/user-paging-result-model';
import UserModel from '../../model/user-model';

export default (service: Servicelayer) => ({
  /**  */
  async getUserContact(request: GetUserContactRequestModel, config?: RequestConfig) {
    const requestDTO = GetUserContactRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-user-contact'] ?? 'Users/{id}/UserContact';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ContactModel.toModel(dto));
  },
  /**  */
  async register(request: RegisterRequestModel, config?: RequestConfig) {
    const requestDTO = RegisterRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-register'] ?? 'core/Users/Register';
    const response = await service.post<any>(endpointPath, {
      body: {
        email: requestDTO.email,
        userName: requestDTO.userName,
        password: requestDTO.password,
        userLanguage: requestDTO.userLanguage,
        userCulture: requestDTO.userCulture,
        isGdprConsentAccepted: requestDTO.isGdprConsentAccepted,
        registrationKey: requestDTO.registrationKey,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async forgotPassword(request: ForgotPasswordRequestModel, config?: RequestConfig) {
    const requestDTO = ForgotPasswordRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-forgot-password'] ?? 'core/Users/ForgotPassword';
    const response = await service.post<any>(endpointPath, {
      body: {
        email: requestDTO.email,
      },
      ...config,
    });
  },
  /**  */
  async changePassword(request: ChangePasswordRequestModel, config?: RequestConfig) {
    const requestDTO = ChangePasswordRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-change-password'] ?? 'core/Users/ChangePassword';
    const response = await service.post<any>(endpointPath, {
      body: {
        token: requestDTO.token,
        newPassword: requestDTO.newPassword,
      },
      ...config,
    });
  },
  /**  */
  async setPasswordForUser(request: SetPasswordForUserRequestModel, config?: RequestConfig) {
    const requestDTO = SetPasswordForUserRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-set-password-for-user'] ?? 'core/Users/{id}/SetPassword';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.post<any>(endpointPath, {
      body: {
        newPassword: requestDTO.newPassword,
        repeatNewPassword: requestDTO.repeatNewPassword,
      },
      ...config,
    });
  },
  /**  */
  async getUsers(request: GetUsersRequestModel, config?: RequestConfig) {
    const requestDTO = GetUsersRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-users'] ?? 'core/Users';
    const response = await service.get<any>(endpointPath, {
      query: {
        searchCriteria: requestDTO.searchCriteria,
        statusId: requestDTO.statusId,
        isDeleted: requestDTO.isDeleted,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(UserPagingResultModel.toModel(dto));
  },
  /**  */
  async getUserById(request: GetUserByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetUserByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-user-by-id'] ?? 'core/Users/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(UserModel.toModel(dto));
  },
  /**  */
  async updateUser(request: UpdateUserRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateUserRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-user'] ?? 'core/Users/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        creatorId: requestDTO.creatorId,
        creatorName: requestDTO.creatorName,
        created: requestDTO.created,
        modifierId: requestDTO.modifierId,
        modifierName: requestDTO.modifierName,
        modified: requestDTO.modified,
        email: requestDTO.email,
        userName: requestDTO.userName,
        gdprConsentDate: requestDTO.gdprConsentDate,
        gdprConsentVersion: requestDTO.gdprConsentVersion,
        verificationCodeSentOn: requestDTO.verificationCodeSentOn,
        verificationCodeValidUntil: requestDTO.verificationCodeValidUntil,
        verifiedOn: requestDTO.verifiedOn,
        statusId: requestDTO.statusId,
        appHostRoleId: requestDTO.appHostRoleId,
        userLanguage: requestDTO.userLanguage,
        userCulture: requestDTO.userCulture,
        registrationKey: requestDTO.registrationKey,
        canDelete: requestDTO.canDelete,
        isDeleted: requestDTO.isDeleted,
      },
      ...config,
    });
  },
  /**  */
  async deleteUser(request: DeleteUserRequestModel, config?: RequestConfig) {
    const requestDTO = DeleteUserRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-user'] ?? 'core/Users/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as boolean | undefined);
  },
  /**  */
  async setUserStatus(request: SetUserStatusRequestModel, config?: RequestConfig) {
    const requestDTO = SetUserStatusRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-set-user-status'] ?? 'core/Users/{id}/SetStatus/{newStatusId}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    endpointPath = endpointPath.replace(/\{newStatusId\}/g, `${requestDTO.newStatusId}`);
    const response = await service.put<any>(endpointPath, {
      ...config,
    });
  },
  /**  */
  async setUserAppRole(request: SetUserAppRoleRequestModel, config?: RequestConfig) {
    const requestDTO = SetUserAppRoleRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-set-user-app-role'] ?? 'core/Users/{id}/SetAppRole/{newRoleId}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    endpointPath = endpointPath.replace(/\{newRoleId\}/g, `${requestDTO.newRoleId}`);
    const response = await service.put<any>(endpointPath, {
      ...config,
    });
  },
  /**  */
  async restoreUser(request: RestoreUserRequestModel, config?: RequestConfig) {
    const requestDTO = RestoreUserRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-restore-user'] ?? 'core/Users/restore/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.patch<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as boolean | undefined);
  },
});
