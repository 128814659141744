// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import Int32LookupRequestListResultViewModel from '../../viewModel/resource/Int32LookupRequestListResultViewModel';
import ProcessPendingIntegrationCallsRequestViewModel from '../../viewModel/resource/ProcessPendingIntegrationCallsRequestViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getCampaignSyncTransformCrmContactProcesses(config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-campaign-sync-transform-crm-contact-processes'] ?? 'core/integration/Processes/CampaignSync/TransformCrmContact';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new Int32LookupRequestListResultViewModel().fromDTO(dto);
  },
  /**  */
  async executeTransformCrmContactProcess(processName: string, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-execute-transform-crm-contact-process'] ?? 'core/integration/Processes/CampaignSync/TransformCrmContact/{processName}';
    endpointPath = endpointPath.replace(/\{processName\}/g, `${processName}`);
    const response = await service.post<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async processPendingIntegrationCalls(model: ProcessPendingIntegrationCallsRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-process-pending-integration-calls'] ?? 'core/integration/Processes/ProcessPendingIntegrationCalls';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async updateContactIdMapping(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-contact-id-mapping'] ?? 'core/integration/Processes/UpdateContactIdMapping/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.post<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
});
