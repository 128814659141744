// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2025 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetHotelClassificationBusinessesRequestModel from '../../model/get-hotel-classification-businesses-request-model';
import AddHotelClassificationRequestModel from '../../model/add-hotel-classification-request-model';
import GetInitialHoklaRequestRequestModel from '../../model/get-initial-hokla-request-request-model';
import GetAccommodationTypeItemsRequestModel from '../../model/get-accommodation-type-items-request-model';
import HotelClassificationBusinessListListResultModel from '../../model/hotel-classification-business-list-list-result-model';
import CreateHotelClassificationRequestModel from '../../model/create-hotel-classification-request-model';
import GuidLookupListResultModel from '../../model/guid-lookup-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getHotelClassificationBusinesses(request: GetHotelClassificationBusinessesRequestModel, config?: RequestConfig) {
    const requestDTO = GetHotelClassificationBusinessesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-hotel-classification-businesses'] ?? 'HotelClassifications/{userId}/Businesses';
    endpointPath = endpointPath.replace(/\{userId\}/g, `${requestDTO.userId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(HotelClassificationBusinessListListResultModel.toModel(dto));
  },
  /**  */
  async addHotelClassification(request: AddHotelClassificationRequestModel, config?: RequestConfig) {
    const requestDTO = AddHotelClassificationRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-add-hotel-classification'] ?? 'HotelClassifications';
    const response = await service.post<any>(endpointPath, {
      body: {
        userId: requestDTO.userId,
        businessName: requestDTO.businessName,
        languageIso2: requestDTO.languageIso2,
        businessContactPersonFirstName: requestDTO.businessContactPersonFirstName,
        businessContactPersonLastName: requestDTO.businessContactPersonLastName,
        businessContactCeoSalutation: requestDTO.businessContactCeoSalutation,
        businessContactPersonSalutation: requestDTO.businessContactPersonSalutation,
        businessContactCeoLastName: requestDTO.businessContactCeoLastName,
        businessContactCeoFirstName: requestDTO.businessContactCeoFirstName,
        isBusinessContactReadonly: requestDTO.isBusinessContactReadonly,
        businessContact: requestDTO.businessContact,
        isBillingContactReadonly: requestDTO.isBillingContactReadonly,
        billingContact: requestDTO.billingContact,
        classificationStars: requestDTO.classificationStars,
        accommodationTypeId: requestDTO.accommodationTypeId,
        accommodationTypeName: requestDTO.accommodationTypeName,
        isSeasonal: requestDTO.isSeasonal,
        countOfRooms: requestDTO.countOfRooms,
        webAddress: requestDTO.webAddress,
        countOfBeds: requestDTO.countOfBeds,
        summary: requestDTO.summary,
        acceptAgb: requestDTO.acceptAgb,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getInitialHoklaRequest(request: GetInitialHoklaRequestRequestModel, config?: RequestConfig) {
    const requestDTO = GetInitialHoklaRequestRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-initial-hokla-request'] ?? 'HotelClassifications/GetInitialRequest';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CreateHotelClassificationRequestModel.toModel(dto));
  },
  /**  */
  async getAccommodationTypeItems(request: GetAccommodationTypeItemsRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-accommodation-type-items'] ?? 'HotelClassifications/AccommodationTypes';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(GuidLookupListResultModel.toModel(dto));
  },
});
